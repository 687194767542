import actions from './actions';
import formBuilderFields from './formBuilderFields'

const initState = {
  thirdPartyIntegratorData: null,
  fetchingThirdPartyIntegratorData: false,
  excelData: null,
  uploadingFile: false,
  uploadingMessageSuccess: null,
  uploadingMessageFailed: null,
  thirdPartyData: null,
  fetchingThirdPartyData: false,
  searchThirdPartyDataResult: null,
  searchingThirdPartyData: false,
  thirdPartyDataByUserResult: null,
  fetchingThirdPartyDataByUser: false,
  batchSummaryData: null,
  fetchingBatchSummary: false,
  batchByNameData: null,
  allBatchByNameData: null,
  fetchingBatchByName: false,
  filterStatus: null,
  fetchingFilterStatus: false,
  removingBatchEntry: false,
  removeMessageSuccess: null,
  removeMessageFailed: null,
  removedDataResult: null,
  fetchedDataByStatus: null,
  fetchingDataByStatus: false,
  statusCountData: null,
  fetchingStatusCount: false,
  countValidator: [],
  isBatchUploadData: null,
  fetchingIsBatchUpload: false,
  formBuilderFields: formBuilderFields,
  fetchingFormBuilderItems: false,
  formBuilderItems: null,
  formBuilderItemsUpdated: false,
  savingDraft: false,
  saveDraftSuccess: false,
  saveDraftMessage: null,
  publishingTemplate: false,
  publishTemplateSuccess: false,
  publishTemplateMessage: null
  // formBuilderItems: [
  //   {
  //     fields: [
  //       {
  //         disabled: true,
  //         hasDelete: false,
  //         column: 1,
  //         fields: [
  //           [
  //             {
  //               category: "text_input",
  //               id: "single_line",
  //               label: "Single-line Text Field",
  //               properties: {
  //                 component: 'Input',
  //                 maxLength: -1,
  //                 paramName: 'first_name',
  //                 placeholder: 'First name',
  //                 required: true,
  //                 type: 'text',
  //                 validation: 'name'
  //               }
  //             },
  //             {
  //               category: "text_input",
  //               id: "single_line",
  //               label: "Single-line Text Field",
  //               properties: {
  //                 component: 'Input',
  //                 maxLength: -1,
  //                 paramName: 'mid_name',
  //                 placeholder: 'Middle name',
  //                 required: false,
  //                 type: 'text',
  //                 validation: 'name'
  //               }
  //             },
  //             {
  //               category: "text_input",
  //               id: "single_line",
  //               label: "Single-line Text Field",
  //               properties: {
  //                 component: 'Input',
  //                 maxLength: -1,
  //                 paramName: 'last_name',
  //                 placeholder: 'Last name',
  //                 required: true,
  //                 type: 'text',
  //                 validation: 'name'
  //               }
  //             }
  //           ],
  //           [
  //             {
  //               category: "text_input",
  //               id: "email",
  //               label: "Email Field",
  //               properties: {
  //                 component: 'Input',
  //                 maxLength: -1,
  //                 paramName: 'email',
  //                 placeholder: 'Email',
  //                 required: true,
  //                 type: 'email',
  //                 validation: 'email'
  //               }
  //             }
  //           ],
  //           [
  //             {
  //               category: "text_input",
  //               id: "single_line",
  //               label: "Mobile Field",
  //               properties: {
  //                 component: 'InputMobile',
  //                 maxLength: 10,
  //                 paramName: 'mobile',
  //                 placeholder: '9XXXXXXXXX - Mobile No (optional)',
  //                 required: false,
  //                 type: 'number',
  //                 validation: 'number',
  //                 addonBefore: '+63'
  //               }
  //             }
  //           ]
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     fields: []
  //   }
  // ]
};


export default (state = initState, action) => {
  switch (action.type) {

    case actions.FETCHED_THIRD_PARTY_INTEGRATOR:
      return {
        ...state,
        thirdPartyIntegratorData: action.payload,
        fetchingThirdPartyData: false,
      };
    case actions.FETCHING_THIRD_PARTY_INTEGRATOR:
      return {
        ...state,
        fetchingThirdPartyIntegratorData: true,
      };
    case actions.FETCH_THIRD_PARTY_INTEGRATOR_FAILED:
      return {
        ...state,
        fetchingThirdPartyIntegratorData: false,
      };

    case actions.UPLOADED_EXCEL_FILE:
      return {
        ...state,
        excelData: action.payload,
        uploadingFile: false,
        batchByNameData: action.finalResult,
        uploadingMessageSuccess: action.messageSuccess,
      };
    case actions.UPLOADING_EXCEL_FILE:
      return {
        ...state,
        uploadingFile: true,
      };
    case actions.UPLOAD_EXCEL_FILE_FAILED:
      return {
        ...state,
        uploadingFile: false,
        uploadingMessageFailed: action.messageFailed,
      };
    case actions.RESET_UPLOAD_MESSAGE:
      return {
        ...state,
        uploadingFile: false,
        uploadingMessageSuccess: null,
        uploadingMessageFailed: null,
      };

    case actions.FETCHED_THIRD_PARTY_DATA:
      return {
        ...state,
        thirdPartyData: action.payload,
        fetchingThirdPartyData: false,
      };
    case actions.FETCHING_THIRD_PARTY_DATA:
      return {
        ...state,
        fetchingThirdPartyData: true,
      };
    case actions.FETCH_THIRD_PARTY_DATA_FAILED:
      return {
        ...state,
        fetchingThirdPartyData: false,
      };

    case actions.SEARCHED_THIRD_PARTY_DATA:
      return {
        ...state,
        batchByNameData: action.payload,
        countValidator: action.countValidator,
        searchingThirdPartyData: false,
      };
    case actions.SEARCHING_THIRD_PARTY_DATA:
      return {
        ...state,
        searchingThirdPartyData: true,
      };
    case actions.SEARCH_THIRD_PARTY_DATA_FAILED:
      return {
        ...state,
        searchingThirdPartyData: false,
      };
    case actions.RESET_SEARCH_THIRD_PARTY_DATA:
      return {
        ...state,
        searchThirdPartyDataResult: null,
      };

    case actions.FETCHED_THIRD_PARTY_DATA_BY_USER:
      return {
        ...state,
        thirdPartyDataByUserResult: action.payload,
        fetchingThirdPartyDataByUser: false,
      };
    case actions.FETCHING_THIRD_PARTY_DATA_BY_USER:
      return {
        ...state,
        fetchingThirdPartyDataByUser: true,
      };
    case actions.FETCH_THIRD_PARTY_DATA_BY_USER_FAILED:
      return {
        ...state,
        fetchingThirdPartyDataByUser: false,
      };
    case actions.RESET_FETCH_THIRD_PARTY_DATA_BY_USER:
      return {
        ...state,
        thirdPartyDataByUserResult: null,
        batchSummaryData: null,
        batchByNameData: null,
        filterStatus: null,
        statusCountData: null,
      };

    case actions.FETCHED_BATCH_SUMMARY:
      return {
        ...state,
        batchSummaryData: action.payload,
        fetchingBatchSummary: false,
      };
    case actions.FETCHING_BATCH_SUMMARY:
      return {
        ...state,
        fetchingBatchSummary: true,
      };
    case actions.FETCH_BATCH_SUMMARY_FAILED:
      return {
        ...state,
        fetchingBatchSummary: false,
      };

    case actions.FETCHED_BATCH_BY_NAME:
      return {
        ...state,
        batchByNameData: action.payload,
        allBatchByNameData: action.allData,
        countValidator: action.countValidator,
        fetchingBatchByName: false,
      };
    case actions.FETCHING_BATCH_BY_NAME:
      return {
        ...state,
        fetchingBatchByName: true,
      };
    case actions.FETCH_BATCH_BY_NAME_FAILED:
      return {
        ...state,
        fetchingBatchByName: false,
      };

    case actions.FETCHED_FILTER_STATUS:
      return {
        ...state,
        filterStatus: action.payload,
        fetchingFilterStatus: false,
      };
    case actions.FETCHING_FILTER_STATUS:
      return {
        ...state,
        fetchingFilterStatus: true,
      };
    case actions.FETCH_FILTER_STATUS_FAILED:
      return {
        ...state,
        fetchingFilterStatus: false,
      };

    case actions.REMOVED_BATCH_UPLOAD_ENTRY:
      return {
        ...state,
        removingBatchEntry: false,
        removedDataResult: action.payload,
        removeMessageSuccess: action.removeMsgSuccess,
      };
    case actions.REMOVING_BATCH_UPLOAD_ENTRY:
      return {
        ...state,
        removingBatchEntry: true,
      };
    case actions.REMOVE_BATCH_UPLOAD_ENTRY_FAILED:
      return {
        ...state,
        removingBatchEntry: false,
        removeMessageFailed: action.removeMsgFailed,
      };
    case actions.RESET_REMOVE_MESSAGE:
      return {
        ...state,
        removingBatchEntry: false,
        removeMessageSuccess: null,
        removeMessageFailed: null,
      };

    case actions.FETCHED_DATA_BY_STATUS:
      return {
        ...state,
        batchByNameData: action.payload,
        fetchingDataByStatus: false,
        statusCountData: action.filterStatusCount,
        countValidator: action.countValidator,
      };
    case actions.NEW_FETCHED_DATA_BY_STATUS:
      return {
        ...state,
        fetchingDataByStatus: false,
        statusCountData: action.statusCountData,
      };
    case actions.FETCHING_DATA_BY_STATUS:
      return {
        ...state,
        fetchingDataByStatus: true,
      };
    case actions.FETCH_DATA_BY_STATUS_FAILED:
      return {
        ...state,
        fetchingDataByStatus: false,
      };

    case actions.FETCHED_STATUS_COUNT:
      return {
        ...state,
        statusCountData: action.payload,
        fetchingStatusCount: false,
      };
    case actions.FETCHING_STATUS_COUNT:
      return {
        ...state,
        fetchingStatusCount: true,
      };
    case actions.FETCH_STATUS_COUNT_FAILED:
      return {
        ...state,
        fetchingStatusCount: false,
      };

    case actions.FETCHED_IS_BATCH_UPLOAD:
      return {
        ...state,
        isBatchUploadData: action.payload,
        fetchingIsBatchUpload: false,
      };
    case actions.FETCHING_IS_BATCH_UPLOAD:
      return {
        ...state,
        fetchingIsBatchUpload: true,
      };
    case actions.FETCH_IS_BATCH_UPLOAD_FAILED:
      return {
        ...state,
        fetchingIsBatchUpload: false,
      };

    case actions.RESET_IS_BATCH_UPLOAD_DATA:
      return {
        ...state,
        isBatchUploadData: null,
      };

    case actions.RESET_BATCH_UPLOAD_DATA:
      return {
        ...state,
        excelData: null,
        uploadingFile: false,
        uploadingMessageSuccess: null,
        uploadingMessageFailed: null,
        thirdPartyData: null,
        fetchingThirdPartyData: false,
        searchThirdPartyDataResult: null,
        searchingThirdPartyData: false,
        thirdPartyDataByUserResult: null,
        fetchingThirdPartyDataByUser: false,
        batchSummaryData: null,
        fetchingBatchSummary: false,
        batchByNameData: null,
        allBatchByNameData: null,
        fetchingBatchByName: false,
        filterStatus: null,
        fetchingFilterStatus: false,
        removingBatchEntry: false,
        removeMessageSuccess: null,
        removeMessageFailed: null,
        removedDataResult: null,
        fetchedDataByStatus: null,
        fetchingDataByStatus: false,
        statusCountData: null,
        fetchingStatusCount: false,
        countValidator: [],
        isBatchUploadData: null,
        fetchingIsBatchUpload: false,
      };

    case actions.RESET_BATCH_UPLOAD_STATE_VALUES:
      return {
        ...state,
        excelData: null,
        uploadingFile: false,
        uploadingMessageSuccess: null,
        uploadingMessageFailed: null,
        thirdPartyData: null,
        fetchingThirdPartyData: false,
        searchThirdPartyDataResult: null,
        searchingThirdPartyData: false,
        thirdPartyDataByUserResult: null,
        fetchingThirdPartyDataByUser: false,
        batchSummaryData: null,
        fetchingBatchSummary: false,
        batchByNameData: null,
        allBatchByNameData: null,
        fetchingBatchByName: false,
        filterStatus: null,
        fetchingFilterStatus: false,
        removingBatchEntry: false,
        removeMessageSuccess: null,
        removeMessageFailed: null,
        removedDataResult: null,
        fetchedDataByStatus: null,
        fetchingDataByStatus: false,
        statusCountData: null,
        fetchingStatusCount: false,
        countValidator: [],
      };
    case actions.FETCHING_DRAFT_TEMPLATE:
      return {
        ...state,
        formBuilderItems: null,
        fetchingFormBuilderItems: true,
      };
    case actions.FETCHED_DRAFT_TEMPLATE:
      return {
        ...state,
        fetchingFormBuilderItems: false,
        formBuilderItems: action.template,
        formBuilderItemsUpdated: false,
      };
    case actions.FETCH_DRAFT_TEMPLATE_FAILED:
      return {
        ...state,
        fetchingFormBuilderItems: false,
        formBuilderItemsUpdated: false,
      };
    case actions.UPDATE_FORM_BUILDER_ITEMS:
      return {
        ...state,
        formBuilderItems: action.payload,
        formBuilderItemsUpdated: true,
      };
    case actions.SAVING_DRAFT_TEMPLATE:
      return {
        ...state,
        savingDraft: true,
      }
    case actions.SAVED_DRAFT_TEMPLATE:
      return {
        ...state,
        savingDraft: false,
        saveDraftSuccess: true,
        saveDraftMessage: action.message,
        formBuilderItemsUpdated: false,
      }
    case actions.SAVE_DRAFT_TEMPLATE_FAILED:
      return {
        ...state,
        savingDraft: false,
        saveDraftSuccess: false,
        saveDraftMessage: action.message
      }
    case actions.RESET_SAVE_DRAFT_TEMPLATE:
      return {
        ...state,
        savingDraft: false,
        saveDraftSuccess: false,
        saveDraftMessage: null,
      }
    case actions.PUBLISHING_TEMPLATE:
      return {
        ...state,
        publishingTemplate: true,
      }
    case actions.PUBLISHED_TEMPLATE:
      return {
        ...state,
        publishingTemplate: false,
        publishTemplateSuccess: true,
        publishTemplateMessage: action.message
      }
    case actions.PUBLISH_TEMPLATE_FAILED:
      return {
        ...state,
        publishingTemplate: false,
        publishTemplateSuccess: false,
        publishTemplateMessage: action.message
      }
    case actions.RESET_PUBLISH_TEMPLATE:
      return {
        ...state,
        publishingTemplate: false,
        publishTemplateSuccess: false,
        publishTemplateMessage: null
      }
    default:
      return state;
  }
}

