export default JSON.parse(`
[
    {
        "category": "text_input",
        "label": "TEXT INPUT FIELDS",
        "types": [
            {
                "id": "single_line",
                "label": "Single-line Text Field",
                "properties": {
                    "type": "text",
                    "component": "Input",
                    "placeholder": "",
                    "maxLength": "",
                    "paramName": "",
                    "required": false
                }
            },
            {
                "id": "multi_line",
                "label": "Multi-line Text Field",
                "properties": {
                    "type": "textarea",
                    "component": "Input.TextArea",
                    "placeholder": "",
                    "maxLength": "",
                    "paramName": "",
                    "required": false
                }
            },
            {
                "id": "email",
                "label": "Email Field",
                "properties": {
                    "type": "email",
                    "component": "Input",
                    "placeholder": "",
                    "maxLength": "",
                    "paramName": "",
                    "required": false,
                    "validation": "email"
                }
            },
            {
                "id": "password",
                "label": "Password Field",
                "properties": {
                    "type": "password",
                    "component": "Input.Password",
                    "placeholder": "",
                    "maxLength": "",
                    "paramName": "",
                    "required": false,
                    "validation": "password"
                }
            },
            {
                "id": "web_url",
                "label": "Web URL Field",
                "properties": {
                    "type": "url",
                    "component": "Input",
                    "placeholder": "",
                    "maxLength": "",
                    "paramName": "",
                    "required": false,
                    "validation": "url"
                }
            }
        ]
    },
    {
        "category": "number_input",
        "label": "NUMBER INPUT FIELDS",
        "types": [
            {
                "id": "number_field",
                "label": "Number Field",
                "properties": {
                    "type": "number",
                    "component": "InputNumber",
                    "placeholder": "",
                    "min": 0,
                    "max": 100,
                    "step": "1",
                    "prefix": null,
                    "suffix": null,
                    "paramName": "",
                    "required": false,
                    "validation": "number"
                }
            },
            {
                "id": "decimal_field",
                "label": "Decimal Field",
                "properties": {
                    "type": "decimal",
                    "component": "InputNumber",
                    "placeholder": "",
                    "min": 0,
                    "max": 100,
                    "step": "0.01",
                    "prefix": null,
                    "suffix": null,
                    "paramName": "",
                    "required": false,
                    "validation": "decimal"
                }
            },
            {
                "id": "slider",
                "label": "Slider Field",
                "properties": {
                    "type": "slider",
                    "component": "Slider",
                    "label": "",
                    "min": 0,
                    "max": 100,
                    "paramName": "",
                    "required": false
                }
            }
        ]
    },
    {
        "category": "selection",
        "label": "SELECTIONS FIELDS",
        "types": [
            {
                "id": "dropdown",
                "label": "Dropdown Selection Field",
                "properties": {
                    "type": "select",
                    "component": "Select",
                    "placeholder": "",
                    "paramName": "",
                    "required": false,
                    "options": [
                        {
                            "label": "Option A",
                            "value": "Option A"
                        },
                        {
                            "label": "Option B",
                            "value": "Option B"
                        }
                    ]
                }
            },
            {
                "id": "radio",
                "label": "Single Selection Field",
                "properties": {
                    "type": "radio",
                    "component": "Radio.Group",
                    "label": "",
                    "paramName": "",
                    "required": false,
                    "options": [
                        {
                            "label": "Option A",
                            "value": "Option A"
                        },
                        {
                            "label": "Option B",
                            "value": "Option B"
                        }
                    ]
                }
            },
            {
                "id": "checkbox",
                "label": "Multiple Selection Field",
                "properties": {
                    "type": "checkbox",
                    "component": "Checkbox.Group",
                    "label": "",
                    "paramName": "",
                    "required": false,
                    "options": [
                        {
                            "label": "Option A",
                            "value": "Option A"
                        },
                        {
                            "label": "Option B",
                            "value": "Option B"
                        }
                    ]
                }
            }
        ]
    },
    {
        "category": "date_time",
        "label": "DATE/TIME FIELDS",
        "types": [
            {
                "id": "date",
                "label": "Date Field",
                "properties": {
                    "type": "datepicker",
                    "component": "DatePicker",
                    "placeholder": "",
                    "paramName": "",
                    "required": false
                }
            },
            {
                "id": "time",
                "label": "Time Field",
                "properties": {
                    "type": "timepicker",
                    "component": "TimePicker",
                    "placeholder": "",
                    "paramName": "",
                    "required": false
                }
            }
        ]
    },
    {
        "category": "static_content",
        "label": "STATIC CONTENT",
        "types": [
            {
                "id": "title",
                "label": "Title",
                "properties": {
                    "type": "title",
                    "component": "Title",
                    "label": ""
                }
            },
            {
                "id": "label",
                "label": "Label",
                "properties": {
                    "type": "label",
                    "component": "Label",
                    "label": ""
                }
            }
        ]
    }
]
`);