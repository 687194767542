import { put } from 'redux-saga/effects';
import actions from '@iso/redux/auth/actions';


export const pushToIos = data => {
    if (window.webkit) {
        // window.webkit.messageHandlers.eventHandler.postMessage(data);
        window.postMessage(data);
    }
};

export const pushIosToWeb = data => {
    if (window.webkit) {
        // window.webkit.messageHandlers.eventHandler.postMessage(data);
        if (data.topic === 'VERIFY_IDENTITY_CLICKED') {
            hideKYCDialog();
        }
    }
};

function hideKYCDialog() {
    // hide KYC dialog
    put({
        type: actions.TOGGLE_KYC,
    });
}

window.pushIosToWeb = pushIosToWeb;
window.hideKYCDialog = hideKYCDialog;




// if (window.webkit) {

// }