const actions = {

  // GET THIRD PARTY INTEGRATOR
  FETCH_THIRD_PARTY_INTEGRATOR: 'FETCH_THIRD_PARTY_INTEGRATOR',
  FETCHING_THIRD_PARTY_INTEGRATOR: 'FETCHING_THIRD_PARTY_INTEGRATOR',
  FETCHED_THIRD_PARTY_INTEGRATOR: 'FETCHED_THIRD_PARTY_INTEGRATOR',
  FETCH_THIRD_PARTY_INTEGRATOR_FAILED: 'FETCH_THIRD_PARTY_INTEGRATOR_FAILED',

  // BATCH UPLOAD MERCHANT
  UPLOAD_EXCEL_FILE: 'UPLOAD_EXCEL_FILE',
  UPLOADING_EXCEL_FILE: 'UPLOADING_EXCEL_FILE',
  UPLOADED_EXCEL_FILE: 'UPLOADED_EXCEL_FILE',
  UPLOAD_EXCEL_FILE_FAILED: 'UPLOAD_EXCEL_FILE_FAILED',
  RESET_UPLOAD_MESSAGE: 'RESET_UPLOAD_MESSAGE',

  // GET BATCH UPLOAD MERCHANT DATA
  FETCH_THIRD_PARTY_DATA: 'FETCH_THIRD_PARTY_DATA',
  FETCHING_THIRD_PARTY_DATA: 'FETCHING_THIRD_PARTY_DATA',
  FETCHED_THIRD_PARTY_DATA: 'FETCHED_THIRD_PARTY_DATA',
  FETCH_THIRD_PARTY_DATA_FAILED: 'FETCH_THIRD_PARTY_DATA_FAILED',

  // GET SEARCH DATA RESULT
  SEARCH_THIRD_PARTY_DATA: 'SEARCH_THIRD_PARTY_DATA',
  SEARCHING_THIRD_PARTY_DATA: 'SEARCHING_THIRD_PARTY_DATA',
  SEARCHED_THIRD_PARTY_DATA: 'SEARCHED_THIRD_PARTY_DATA',
  SEARCH_THIRD_PARTY_DATA_FAILED: 'SEARCH_THIRD_PARTY_DATA_FAILED',
  RESET_SEARCH_THIRD_PARTY_DATA: 'RESET_SEARCH_THIRD_PARTY_DATA',

  // GET BATCH UPLOAD DATA BY USERNAME
  FETCH_THIRD_PARTY_DATA_BY_USER: 'FETCH_THIRD_PARTY_DATA_BY_USER',
  FETCHING_THIRD_PARTY_DATA_BY_USER: 'FETCHING_THIRD_PARTY_DATA_BY_USER',
  FETCHED_THIRD_PARTY_DATA_BY_USER: 'FETCHED_THIRD_PARTY_DATA_BY_USER',
  FETCH_THIRD_PARTY_DATA_BY_USER_FAILED: 'FETCH_THIRD_PARTY_DATA_BY_USER_FAILED',
  RESET_FETCH_THIRD_PARTY_DATA_BY_USER: 'RESET_FETCH_THIRD_PARTY_DATA_BY_USER',

  // GET BATCH SUMMARY
  FETCH_BATCH_SUMMARY: 'FETCH_BATCH_SUMMARY',
  FETCHING_BATCH_SUMMARY: 'FETCHING_BATCH_SUMMARY',
  FETCHED_BATCH_SUMMARY: 'FETCHED_BATCH_SUMMARY',
  FETCH_BATCH_SUMMARY_FAILED: 'FETCH_BATCH_SUMMARY_FAILED',

  // GET BATCH SUMMARY BY NAME
  FETCH_BATCH_BY_NAME: 'FETCH_BATCH_BY_NAME',
  FETCHING_BATCH_BY_NAME: 'FETCHING_BATCH_BY_NAME',
  FETCHED_BATCH_BY_NAME: 'FETCHED_BATCH_BY_NAME',
  FETCH_BATCH_BY_NAME_FAILED: 'FETCH_BATCH_BY_NAME_FAILED',

  // GET FILTER STATUS
  FETCH_FILTER_STATUS: 'FETCH_FILTER_STATUS',
  FETCHING_FILTER_STATUS: 'FETCHING_FILTER_STATUS',
  FETCHED_FILTER_STATUS: 'FETCHED_FILTER_STATUS',
  FETCH_FILTER_STATUS_FAILED: 'FETCH_FILTER_STATUS_FAILED',

  // REMOVE BATCH UPLOAD ENTRY
  REMOVE_BATCH_UPLOAD_ENTRY: 'REMOVE_BATCH_UPLOAD_ENTRY',
  REMOVING_BATCH_UPLOAD_ENTRY: 'REMOVING_BATCH_UPLOAD_ENTRY',
  REMOVED_BATCH_UPLOAD_ENTRY: 'REMOVED_BATCH_UPLOAD_ENTRY',
  REMOVE_BATCH_UPLOAD_ENTRY_FAILED: 'REMOVE_BATCH_UPLOAD_ENTRY_FAILED',
  RESET_REMOVE_MESSAGE: 'RESET_REMOVE_MESSAGE',

  // FILTER BY STATUS
  FETCH_DATA_BY_STATUS: 'FETCH_DATA_BY_STATUS',
  FETCHING_DATA_BY_STATUS: 'FETCHING_DATA_BY_STATUS',
  FETCHED_DATA_BY_STATUS: 'FETCHED_DATA_BY_STATUS',
  NEW_FETCHED_DATA_BY_STATUS: 'NEW_FETCHED_DATA_BY_STATUS',
  FETCH_DATA_BY_STATUS_FAILED: 'FETCH_DATA_BY_STATUS_FAILED',

  // FETCH STATUS COUNT
  FETCH_STATUS_COUNT: 'FETCH_STATUS_COUNT',
  FETCHING_STATUS_COUNT: 'FETCHING_STATUS_COUNT',
  FETCHED_STATUS_COUNT: 'FETCHED_STATUS_COUNT',
  FETCH_STATUS_COUNT_FAILED: 'FETCH_STATUS_COUNT_FAILED',

  // FETCH MERCHANT IS_BATCH_UPLOAD
  FETCH_IS_BATCH_UPLOAD: 'FETCH_IS_BATCH_UPLOAD',
  FETCHING_IS_BATCH_UPLOAD: 'FETCHING_IS_BATCH_UPLOAD',
  FETCHED_IS_BATCH_UPLOAD: 'FETCHED_IS_BATCH_UPLOAD',
  FETCH_IS_BATCH_UPLOAD_FAILED: 'FETCH_IS_BATCH_UPLOAD_FAILED',
  RESET_IS_BATCH_UPLOAD_DATA: 'RESET_IS_BATCH_UPLOAD_DATA',

  // RESET BATCH UPLOAD DATA
  RESET_BATCH_UPLOAD_DATA: 'RESET_BATCH_UPLOAD_DATA',

  // RESET BATCH UPLOAD STATE VALUES
  RESET_BATCH_UPLOAD_STATE_VALUES: 'RESET_BATCH_UPLOAD_STATE_VALUES',

  // FETCH DRAFT TEMPLATE
  FETCH_DRAFT_TEMPLATE: 'FETCH_DRAFT_TEMPLATE',
  FETCHING_DRAFT_TEMPLATE: 'FETCHING_DRAFT_TEMPLATE',
  FETCHED_DRAFT_TEMPLATE: 'FETCHED_DRAFT_TEMPLATE',
  FETCH_DRAFT_TEMPLATE_FAILED: 'FETCH_DRAFT_TEMPLATE_FAILED',

  // UPDATE FORM BUILDER ITEMS
  UPDATE_FORM_BUILDER_ITEMS: 'UPDATE_FORM_BUILDER_ITEMS',

  // SAVE DRAFT TEMPLATE
  SAVE_DRAFT_TEMPLATE: 'SAVE_DRAFT_TEMPLATE',
  SAVING_DRAFT_TEMPLATE: 'SAVING_DRAFT_TEMPLATE',
  SAVED_DRAFT_TEMPLATE: 'SAVED_DRAFT_TEMPLATE',
  SAVE_DRAFT_TEMPLATE_FAILED: 'SAVE_DRAFT_TEMPLATE_FAILED',
  RESET_SAVE_DRAFT_TEMPLATE: 'RESET_SAVE_DRAFT_TEMPLATE',

  // PUBLISH TEMPLATE
  PUBLISH_TEMPLATE: 'PUBLISH_TEMPLATE',
  PUBLISHING_TEMPLATE: 'PUBLISHING_TEMPLATE',
  PUBLISHED_TEMPLATE: 'PUBLISHED_TEMPLATE',
  PUBLISH_TEMPLATE_FAILED: 'PUBLISH_TEMPLATE_FAILED',
  RESET_PUBLISH_TEMPLATE: 'RESET_PUBLISH_TEMPLATE',

  fetchIsBatchUpload: (payload) => ({
    type: actions.FETCH_IS_BATCH_UPLOAD,
    payload,
  }),

  fetchAllThirdPartyIntegrator: () => ({
    type: actions.FETCH_THIRD_PARTY_INTEGRATOR,
  }),

  batchUploadMerchant: (payload) => ({
    type: actions.UPLOAD_EXCEL_FILE,
    payload,
  }),

  fetchAllThirdPartyData: () => ({
    type: actions.FETCH_THIRD_PARTY_DATA,
  }),

  resetUploadMessage: () => ({
    type: actions.RESET_UPLOAD_MESSAGE,
  }),

  searchThirdPartyData: (payload) => ({
    type: actions.SEARCH_THIRD_PARTY_DATA,
    payload,
  }),

  resetSearchThirdPartyData: () => ({
    type: actions.RESET_SEARCH_THIRD_PARTY_DATA,
  }),

  fetchThirdPartyDataByUser: (payload) => ({
    type: actions.FETCH_THIRD_PARTY_DATA_BY_USER,
    payload,
  }),

  resetThirdPartyDataByUser: () => ({
    type: actions.RESET_FETCH_THIRD_PARTY_DATA_BY_USER,
  }),

  fetchBatchSummary: (payload) => ({
    type: actions.FETCH_BATCH_SUMMARY,
    payload,
  }),

  fetchBatchByName: (payload) => ({
    type: actions.FETCH_BATCH_BY_NAME,
    payload,
  }),

  fetchFilterStatus: (payload) => ({
    type: actions.FETCH_FILTER_STATUS,
    payload,
  }),

  removeBatchUploadEntry: (payload) => ({
    type: actions.REMOVE_BATCH_UPLOAD_ENTRY,
    payload,
  }),

  filterByStatus: (payload) => ({
    type: actions.FETCH_DATA_BY_STATUS,
    payload,
  }),

  fetchStatusCount: (payload) => ({
    type: actions.FETCH_STATUS_COUNT,
    payload,
  }),

  resetRemoveMessage: () => ({
    type: actions.RESET_REMOVE_MESSAGE,
  }),

  resetIsBatchUploadData: () => ({
    type: actions.RESET_IS_BATCH_UPLOAD_DATA,
  }),

  resetBatchUploadData: () => ({
    type: actions.RESET_BATCH_UPLOAD_DATA,
  }),

  resetBatchUploadStateValues: () => ({
    type: actions.RESET_BATCH_UPLOAD_STATE_VALUES,
  }),
  // updateBatchSummaryData: (payload) => ({
  //   type: actions.RESET_BATCH_UPLOAD_STATE_VALUES,
  //   payload,
  // }),

  fetchDraftTemplate: (payload) => ({
    type: actions.FETCH_DRAFT_TEMPLATE,
    payload
  }),

  updateFormBuilderItems: (payload) => ({
    type: actions.UPDATE_FORM_BUILDER_ITEMS,
    payload: payload
  }),

  saveDraftTemplate: (payload) => ({
    type: actions.SAVE_DRAFT_TEMPLATE,
    payload
  }),

  resetSaveDraftTemplate: () => ({
    type: actions.RESET_SAVE_DRAFT_TEMPLATE,
  }),

  publishTemplate: (payload) => ({
    type: actions.PUBLISH_TEMPLATE,
    payload
  }),

  resetPublishTemplate: () => ({
    type: actions.RESET_PUBLISH_TEMPLATE,
  })

};
export default actions;

