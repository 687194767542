import { PUT, get, post } from '@iso/lib/helpers/resApiRequestor';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import actions from './actions';

export function* loadPaymentCount() {
  yield takeEvery('LOAD_DASHBOARD_DATA', function* () {
    try {
      const state = yield select();
      const role = state.Auth.identity.role.role.label;
      const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
      const loading = state.Dashboard.isLoadingDashboard;
      if (!loading) {
        yield put({
          type: actions.LOADING_DASHBOARD_DATA,
          payload: true,
        });
        // const apiResult = yield getDashboardPaymentCount();
        const apiResult = yield getDashboardPaymentCount(merchantId);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.LOADING_DASHBOARD_DATA,
            payload: false,

          });
          if (result.success) {
            yield put({
              type: actions.LOADED_DASHBOARD_DATA,
              payload: result.data,
            });
          }
        } else {
          yield put({
            type: actions.LOAD_DASHBOARD_ERROR,
            payload: true,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_DASHBOARD_ERROR,
        payload: true,
      });
    }
  });
}
export function* getUserSettings() {
  yield takeEvery('GET_USER_SETTINGS', function* ({ payload }) {
    try {
      yield put({
        type: actions.GETTING_USER_SETTINGS,
        payload: true,
      });
      const apiResult = yield gettingUserSettings(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.GOT_USER_SETTINGS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.ERROR_USER_SETTINGS,
          payload:  true,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ERROR_USER_SETTINGS,
        payload:  true,
      });
    }
  });
}
export function* updateVat() {
  yield takeEvery('UPDATE_VAT_AGGREE', function* ({ payload }) {
    try {
      yield put({
        type: actions.UPDATING_VAT_AGGREE,
        payload: true,
      });
      const apiResult = yield updatingVat(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.GOT_USER_SETTINGS,
          payload: result.data,
        });
        yield put({
          type: actions.UPDATING_VAT_AGGREE,
          payload: false,
        });
      } else {
        yield put({
          type: actions.ERROR_USER_SETTINGS,
          payload:  true,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ERROR_USER_SETTINGS,
        payload:  true,
      });
    }
  });
}
// export function* getWinners() {
//   yield takeEvery('GET_WINNERS', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.GETTING_WINNERS,
//         payload: true,
//       });
//       const apiResult = yield checkWinners();
//       const result = apiResult.data;
//       if (result.success) {
//         yield put({
//           type: actions.GOT_WINNERS,
//           payload: result.message,
//           success: true,
//         });
//       } else {
//         yield put({
//           type: actions.GOT_WINNERS,
//           payload: result.message,
//           success: false,
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.SENDING_FAILED_RAFFLE_ENTRY,
//       });
//     }
//   });
// }
// export function* startRaffle() {
//   yield takeEvery('START_RAFFLE', function* () {
//     try {
//       yield put({
//         type: actions.STARTING_RAFFLE,
//         payload: true,
//       });
//       const apiResult = yield startingDraw();
//       const result = apiResult.data;
//       if (result.success) {
//         yield put({
//           type: actions.DONE_RAFFLE,
//           payload: result.data.length > 0 ? result.data[0] : {name: 'No Selected Winner'},
//           success: true,
//         });
//         yield put({
//           type: actions.GOT_WINNERS,
//           payload: result.data.length > 0 ? result.data[0] : 'No Winner',
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.STARTING_RAFFLE,
//         payload: false,
//       });
//     }
//   });
// }
// export function* getWinner() {
//   yield takeEvery('GET_WINNERS', function* () {
//     try {
//       yield put({
//         type: actions.GETTING_WINNERS,
//         payload: true,
//       });
//       const apiResult = yield gettingWinner();
//       const result = apiResult.data;
//       if (result.success) {
//         yield put({
//           type: actions.GOT_WINNERS,
//           payload: result.data.length > 0 ? result.data[0] : 'No Winner',
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.GETTING_WINNERS,
//         payload: false,
//       });
//     }
//   });
// }
// export function* sendRaffleEntry() {
//   yield takeEvery('SEND_RAFFLE_ENTRY', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.SENDING_RAFFLE_ENTRY,
//         payload: true,
//       });
//       const apiResult = yield sendingRaffleEntry(payload);
//       const result = apiResult.data;
//       if (result.success) {
//         yield put({
//           type: actions.RAFFLE_ENTRY,
//           payload: result.message,
//           success: true,
//         });
//       } else {
//         yield put({
//           type: actions.RAFFLE_ENTRY,
//           payload: result.message,
//           success: false,
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.SENDING_FAILED_RAFFLE_ENTRY,
//       });
//     }
//   });
// }

export function* applyCredit() {
  yield takeEvery('APPLY_CREDIT_CARD', function* ({ payload }) {
    try {
      yield put({
        type: actions.APPLYING_CREDIT_CARD,
      });
      const apiResult = yield applyCreditCard(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.APPLIED_CREDIT_CARD,
          message: result.message,
          data: result,
        });
      } else {
        yield put({
          type: actions.APPLY_CREDIT_CARD_FAILED,
          message: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.APPLY_CREDIT_CARD_FAILED,
      });
    }
  });
}
export function* getCcApplication() {
  yield takeEvery('GET_CC_APPLICATION', function* () {
    try {
      const state = yield select();
      const role = state.Auth.identity.role.role.label;
      const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
      yield put({
        type: actions.GETTING_CC_APPLICATION
      });
      const apiResult = yield getCc(merchantId);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        yield put({
          type: actions.GOT_CC_APPLICATION,
          data: result,
        });
      } else {
        yield put({
          type: actions.ERROR_CC_APPLICATION,
          data: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ERROR_CC_APPLICATION,
        payload:  true,
      });
    }
  });
}

export function* downloadTncPdf() {
  yield takeEvery('DOWNLOAD_TNC_PDF', function* ({payload}) {
    try {
      yield put({
        type: actions.DOWNLOAD_TNC_PDF_LOADING,
      });
      const apiResult = yield downloadTncPdfRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DOWNLOAD_TNC_PDF_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.DOWNLOAD_TNC_PDF_FAILED,
          payload: 'Downloading Terms and Conditions Failed',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_TNC_PDF_FAILED,
        payload: 'Downloading Terms and Conditions Error',
      });
    }
  });
}

export function* setUrl() {
  yield takeEvery('SET_URL', function* ({payload}) {
    yield put({
      type: actions.SET_URL_SUCCESS,
      payload,
    });
  });
}

export function* setPrevUrl() {
  yield takeEvery('SET_PREV_URL', function* ({payload}) {
    yield put({
      type: actions.SET_PREV_URL_SUCCESS,
      payload,
    });
  });
}

export function* fetchMerchBirDocs() {
  yield takeEvery('FETCH_MERCH_BIR_DOCS', function* ({payload}) {
    try {
      yield put({
        type: actions.FETCH_MERCH_BIR_DOCS_LOADING,
      });
      const apiResult = yield fetchMerchBirDocsRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_MERCH_BIR_DOCS_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_MERCH_BIR_DOCS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCH_BIR_DOCS_FAILED,
        payload: error,
      });
    }
  });
}

function getDashboardPaymentCount(mid) {
  return get(`dashboard/get_dashboard_data/${mid}`);
}
function gettingUserSettings(username) {
  return get(`dashboard/get_user_settings/${username}`);
}
function updatingVat(username) {
  return PUT(`dashboard/update_user_settings/${username}`);
}
// function sendingRaffleEntry(payload) {
//   return post(`raffleentries/`, payload);
// }
// function checkWinners() {
//   return get(`raffleentries/get_winners/`);
// }
// function startingDraw() {
//   return get(`raffleentries/draw_raffle/`);
// }
// function gettingWinner() {
//   return get(`raffleentries/get_winners/`);
// }
function applyCreditCard(merchantId) {
  const merchant = { merchantId };
  return post(`ccregistrations/apply_creditcard`, merchant);
}
function getCc(id) {
  return get(`ccregistrations/fetch_application/${id}`);
}

function downloadTncPdfRequest(payload) {
  return post(`merchantsettings/download_tnc_pdf`, payload)
}

function fetchMerchBirDocsRequest(mid) {
  return get(`birdocuments/fetch_docs_by_property/merchant_id/${mid}`)
}
export default function* rootSaga() {
  yield all([
    loadPaymentCount(),
    getUserSettings(),
    updateVat(),
    // sendRaffleEntry(),
    // checkWinners(),
    // startRaffle(),
    // getWinner(),
    applyCredit(),
    getCcApplication(),
    downloadTncPdf(),
    setUrl(),
    setPrevUrl(),
    fetchMerchBirDocs(),
  ]);
}
