import actions from './actions';
import authActions from '../auth/actions';
// import dummyInventory from './dummyData'

const initState = {
  errorMessage: null,
  message: null,
  loadingBalances: false,
  loadingLimit: false,
  walletDetails: null,
  limitDetails: null,
  loadingBankAccounts: false,
  bankAccounts: null,
  isRequestOtp: false,
  otpMessage: null,
  isErrorOtp: false,
  isVerifyOtp: false,
  verifyOtpMessage: null,
  isErrorVerifyOtp: false,
  resendSuccessMessage: null,
  isResendOtp: false,
  loadingSupportedBanks: false,
  supportedBanks: null,
  loadingSendingOtp: false,
  otpSent: false,
  loadingSavingBank: false,
  savingBankResult: null,
  loadingResendingOtp: false,
  resendOtpMessage: null,
  loadingUpdatingBank: false,
  updatedBankResult: false,
  updateSuccess: false,
  loadingRemovingBank: false,
  removedBankResult: false,
  loadingChangingFavorite: false,
  removeSuccess: false,
  changingFailed: false,
  //
  transactionType: 'withdraw',
  //
  withdrawTransactionData: null,
  withdrawTransactionPagination: null,
  withdrawTransactionLoadmore: false,
  fetchingWithdrawTransaction: false,
  isErrorWithdrawalRequest: false,
  //
  splitTransactionData: null,
  splitTransactionPagination: null,
  splitTransactionLoadmore: false,
  fetchingSplitTransaction: false,
  isErrorSplitTransactionRequest: false,
  //
  topupTransactionData: null,
  topupTransactionPagination: null,
  topupTransactionLoadmore: false,
  fetchingTopupTransaction: false,
  isErrorTopupRequest: false,
  //
  onlinePaymentTransactionData: null,
  onlinePaymentTransactionPagination: null,
  onlinePaymentTransactionLoadmore: false,
  fetchingOnlinePaymentTransaction: false,
  isErrorOnlinePaymentRequest: false,
  //
  autoSettlementData: null,
  autoSettlementPagination: null,
  autoSettlementLoadmore: false,
  fetchingAutoSettlement: false,
  isErrorGetAutoSettlement: false,
  //
  isGetWithdrawal: false,
  emailNotif: null,
  downloadingWithdrawal: false,
  downloadedWithdrawalData: null,
  downloadWithdrawalMessage: null,
  downloadingSplit: false,
  downloadedSplitData: null,
  downloadSplitMessage: null,
  downloadingTopup: false,
  downloadedTopupData: null,
  downloadTopupMessage: null,
  downloadingAutoSettlement: false,
  downloadedAutoSettlementData: null,
  downloadAutoSettlementMessage: null,
  withdrawalRequestData: null,
  updateStatusBoolean: false,
  updatedData: null,
  changingStatusFailed: false,
  changingStatusSuccess: false,
  loadingChangingStatus: false,
  loadingSendingAutosweepOtp: false,
  autosweepOtpSent: false,
  autosweepOtpMessage: null,
  savingAutoSweep: false,
  savingAutoSweepResult: null,
  savingAutoSweepMessage: null,
  walletTopUpResponse: null,
  walletTopUpErrorMessage: null,
  downloadingBizwalletReport: false,
  downloadedBizwalletReport: null,
  downloadBizwalletReportMessage: null,
  centinelTopUpLoading: false,
  centinelTopUpResponse: null,
  centinelTopUpErrorMessage: null,
  isBocReport: false,
  bocReport: null,
  //
  searchedWithdrawTransactionData: null,
  searchingWithdrawTransaction: false,
  searchWithdrawTransactionPagination: null,
  searchWithdrawTransactionLoadmore: false,
  isErrorSearchWithdrawRequest: false,

  searchedSplitTransactionData: null,
  searchingSplitTransaction: false,
  searchSplitTransactionPagination: null,
  searchSplitTransactionLoadmore: false,
  isErrorSearchSplitRequest: false,

  searchedTopupTransactionData: null,
  searchingTopupTransaction: false,
  searchTopupTransactionPagination: null,
  searchTopupTransactionLoadmore: false,
  isErrorSearchTopupRequest: false,

  searchedOnlinePaymentTransactionData: null,
  searchingOnlinePaymentTransaction: false,
  searchOnlinePaymentTransactionPagination: null,
  searchOnlinePaymentTransactionLoadmore: false,
  isErrorSearchOnlinePaymentRequest: false,

  searchedAutoSettlementData: null,
  searchingAutoSettlement: false,
  searchAutoSettlementPagination: null,
  searchAutoSettlementLoadmore: false,
  isErrorSearchAutoSettlementRequest: false,

  // fetchedEnrolledBankAccountData: null,
  fetchingEnrolledBankAccount: false,
  fetchEnrolledBankAccountPagination: null,
  fetchEnrolledBankAccountLoadmore: false,
  isErrorFetchEnrolledBankAccountRequest: false,

  isFetchingAllWallet: false,
  allWallets: null,
  allWalletError: null,
  walletId: null,
  isSavingWallet: false,
  savedWallet: null,
  savedWalletError: null,

  fetchingWallet: false,
  fetchedWallet: null,
  fetchedWalletError: null,
  approvingWallet: false,
  approvedWallet: null,
  approvedWalletError: null,

  //ROLLING RESERVE
  fetchingRollingReserveAmount: false,
  fetchedRollingReserveAmount: null,
  fetchedRollingReserveAmountError: null,

  // WALLET LINK
  walletLinkData: null,
  walletLinkLoading: false,
  walletLinkFailed: null,

  // CANCEL ONLINE PAYMENT
  cancelOnlinePaymentData: null,
  cancelOnlinePaymentLoading: false,
  cancelOnlinePaymentFailed: null,

  // SEND OTP CENTINEL MANUAL TOP UP
  sendOtpCentinelTopupLoading: false,
  sendOtpCentinelTopupError: null,
  sendOtpCentinelTopupSuccess: null,

  // RESEND OTP CENTINEL MANUAL TOP UP
  resendOtpCentinelTopupLoading: false,
  resendOtpCentinelTopupError: null,
  resendOtpCentinelTopupSuccess: null,

  // VERIFY OTP CENTINEL MANUAL TOP UP
  verifyOtpCentinelTopupLoading: false,
  verifyOtpCentinelTopupError: null,
  verifyOtpCentinelTopupSuccess: null,

  // FETCH MERCHANT BIR DOCUMENTS
  fetchMerchantBirDocsSuccess: null,
  fetchMerchantBirDocsLoading: false,
  fetchMerchantBirDocsFailed: null,

  // SUBMIT MERCHANT BIR DOCUMENTS
  submitMerchantBirDocsSuccess: null,
  submitMerchantBirDocsLoading: false,
  submitMerchantBirDocsFailed: null,

  // UPDATE MERCHANT BIR DOCUMENTS
  updateBirDocsSuccess: null,
  updateBirDocsLoading: false,
  updateBirDocsFailed: null,

  // DELETE MERCHANT BIR DOCUMENTS
  deleteMerchantBirDocsSuccess: null,
  deleteMerchantBirDocsLoading: false,
  deleteMerchantBirDocsFailed: null,

  // FETCH FILE BASE64 FROM AWS S3
  fetchFileBase64Success: null,
  fetchFileBase64Loading: false,
  fetchFileBase64Failed: null,

  //Remove Enrolled Bank
  otpRemoveBankLoading: false,
  otpRemoveBankBannerModal: false,
  otpRemoveBankModal: false,
  verifyingRemoveBankOtpLoading: false,
  otpRemoveBankData: null,
  otpRemoveBankToken: null,
  removeEnrolleBankError: null,
  verifyingRemoveBankData: null,
  isErrorVerifyRemoveBankOtp: false,
  verifyRemoveBankOtpMessage: null,
  otpRemoveBankLimitReachMessage: null,
  resendingRemoveBankOtpLoading: false,
  verifiedOtp: false,
  otpRemoveBankMessage: null,
  otpRemoveBankEmail: null,
  isConfirmRemovalModal: false,
  verifyingEnrolleBankError: null,

  modules: [
    {
      id: 'wallet',
      label: 'Biz.financials.menu.wallet',
      view: 'BizWallet'
    },
    {
      id: 'bankAccounts',
      label: 'Biz.financials.menu.banks',
      view: 'EnrolledBankAccounts'
    },
    {
      id: 'card',
      label: 'Biz.financials.menu.card',
      view: 'Card'
    },
    // {
    //   id: 'bookeeping',
    //   label: 'Biz.financials.menu.bookeeping',
    //   view: 'Bookeeping'
    // },
    // {
    //   id: 'reports',
    //   label: 'Biz.financials.menu.reports',
    //   view: 'TransactionReports'
    // },
  ],
};

export default (state = initState, action) => {

  switch (action.type) {
    case actions.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.payload,
      };
    case actions.GOT_ALL_AUTOSETTLEMENT:
      return {
        ...state,
        autoSettlementData: action.payload,
        autoSettlementPagination: action.pagination,
        autoSettlementLoadmore: false,
        fetchingAutoSettlement: false,
      }
    case actions.GETTING_ALL_AUTOSETTLEMENT:
      return {
        ...state,
        fetchingAutoSettlement: true,
        autoSettlementData: null,
        autoSettlementPagination: null,
        autoSettlementLoadmore: false,
        isErrorGetAutoSettlement: false,
      };
    case actions.LOADMORE_AUTOSETTLEMENT:
      return {
        ...state,
        autoSettlementLoadmore: true,
      };
    case actions.GET_ALL_AUTOSETTLEMENT_FAILED:
      return {
        ...state,
        fetchingAutoSettlement: false,
        autoSettlementLoadmore: false,
      };
    case actions.GET_ALL_AUTOSETTLEMENT_ERROR:
      return {
        ...state,
        isErrorGetAutoSettlement: action.payload,
        autoSettlementLoadmore: false,
      };
    case actions.RESET_AUTOSETTLEMENT:
      return {
        ...state,
        autoSettlementData: null,
        autoSettlementPagination: null,
        autoSettlementLoadmore: false,
        fetchingAutoSettlement: false,
        isErrorGetAutoSettlement: false,
      };
    //
    case actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS:
      return {
        ...state,
        withdrawTransactionData: action.payload,
        withdrawTransactionPagination: action.pagination,
        withdrawTransactionLoadmore: false,
        fetchingWithdrawTransaction: false,
      };
    case actions.GETTING_WITHDRAWAL_TRANSACTION:
      return {
        ...state,
        fetchingWithdrawTransaction: true,
        withdrawTransactionData: null,
        withdrawTransactionPagination: null,
        withdrawTransactionLoadmore: false,
        isErrorWithdrawalRequest: false,
      };
    case actions.LOADMORE_WITHDRAWAL_TRANSACTION:
      return {
        ...state,
        withdrawTransactionLoadmore: true,
      };
    case actions.GET_WITHDRAWAL_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingWithdrawTransaction: false,
        withdrawTransactionLoadmore: false,
      };
    case actions.GET_WITHDRAWAL_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorWithdrawalRequest: action.payload,
        withdrawTransactionLoadmore: false,
      };
    case actions.RESET_WITHDRAWAL_TRANSACTION:
      return {
        ...state,
        withdrawTransactionData: null,
        withdrawTransactionPagination: null,
        withdrawTransactionLoadmore: false,
        fetchingWithdrawTransaction: false,
        isErrorWithdrawalRequest: false,
      };
    //
    case actions.GET_SPLIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        splitTransactionData: action.payload,
        splitTransactionPagination: action.pagination,
        splitTransactionLoadmore: false,
        fetchingSplitTransaction: false,
      };
    case actions.UPDATE_SPLIT_TRANSACTION:
      return {
        ...state,
        splitTransactionData: action.payload,
      };
    case actions.GETTING_SPLIT_TRANSACTION:
      return {
        ...state,
        fetchingSplitTransaction: true,
        splitTransactionData: null,
        splitTransactionPagination: null,
        splitTransactionLoadmore: false,
        isErrorSplitTransactionRequest: false,
      };
    case actions.LOADMORE_SPLIT_TRANSACTION:
      return {
        ...state,
        splitTransactionLoadmore: true,
      };
    case actions.GET_SPLIT_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingSplitTransaction: false,
        splitTransactionLoadmore: false,
      };
    case actions.GET_SPLIT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSplitTransactionRequest: action.payload,
        splitTransactionLoadmore: false,
      };
    case actions.RESET_SPLIT_TRANSACTION:
      return {
        ...state,
        splitTransactionData: null,
        splitTransactionPagination: null,
        splitTransactionLoadmore: false,
        fetchingSplitTransaction: false,
        isErrorSplitTransactionRequest: false,
      };
    //
    case actions.GET_TOPUP_TRANSACTION_SUCCESS:
      return {
        ...state,
        topupTransactionData: action.payload,
        topupTransactionPagination: action.pagination,
        topupTransactionLoadmore: false,
        fetchingTopupTransaction: false,
      };
    case actions.GETTING_TOPUP_TRANSACTION:
      return {
        ...state,
        fetchingTopupTransaction: true,
        topupTransactionData: null,
        topupTransactionPagination: null,
        topupTransactionLoadmore: false,
        isErrorTopupRequest: false,
      };
    case actions.LOADMORE_TOPUP_TRANSACTION:
      return {
        ...state,
        topupTransactionLoadmore: true,
      };
    case actions.GET_TOPUP_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingTopupTransaction: false,
        topupTransactionLoadmore: false,
      };
    case actions.GET_TOPUP_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorTopupRequest: action.payload,
        topupTransactionLoadmore: false,
      };
    case actions.RESET_TOPUP_TRANSACTION:
      return {
        ...state,
        topupTransactionData: null,
        topupTransactionPagination: null,
        topupTransactionLoadmore: false,
        fetchingTopupTransaction: false,
        isErrorTopupRequest: false,
      };
    //
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        onlinePaymentTransactionData: action.payload,
        onlinePaymentTransactionPagination: action.pagination,
        onlinePaymentTransactionLoadmore: false,
        fetchingOnlinePaymentTransaction: false,
      };
    case actions.GETTING_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        fetchingOnlinePaymentTransaction: true,
        onlinePaymentTransactionData: null,
        onlinePaymentTransactionPagination: null,
        onlinePaymentTransactionLoadmore: false,
        isErrorOnlinePaymentRequest: false,
      };
    case actions.LOADMORE_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        onlinePaymentTransactionLoadmore: true,
      };
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingOnlinePaymentTransaction: false,
        onlinePaymentTransactionLoadmore: false,
      };
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorOnlinePaymentRequest: action.payload,
        onlinePaymentTransactionLoadmore: false,
      };
    case actions.RESET_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        onlinePaymentTransactionData: null,
        onlinePaymentTransactionPagination: null,
        onlinePaymentTransactionLoadmore: false,
        fetchingOnlinePaymentTransaction: false,
        isErrorOnlinePaymentRequest: false,
      };
    //
    case actions.LOADING_BALANCES:
      return {
        ...state,
        loadingBalances: true,
      };
    case actions.LOADING_LIMIT:
      return {
        ...state,
        loadingLimit: true,
      };
    case actions.BALANCES_LOADED:
      return {
        ...state,
        loadingBalances: false,
        walletDetails: action.walletDetails,
      };
    case actions.LOAD_WALLET_BALANCE_RESET:
      return {
        ...state,
        loadingBalances: false,
        walletDetails: null,
      };
    case actions.LIMIT_LOADED:
      return {
        ...state,
        loadingLimit: false,
        limitDetails: action.limitDetails,
      };
    case actions.LOADING_BANK_ACCOUNTS:
      return {
        ...state,
        loadingBankAccounts: true,
      };
    case actions.BANK_ACCOUNTS_LOADED:
      return {
        ...state,
        loadingBankAccounts: false,
        bankAccounts: action.bankAccounts,
      };
    case actions.LOADING_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        bankAccounts: null,
      };
    case actions.LOADING_LIMIT_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        limitDetails: null,
      };
    case actions.LOADING_BALANCES_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        walletDetails: null,
      };
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null,
      };
    case actions.REQUESTING_OTP:
      return {
        ...state,
        isRequestOtp: action.payload,
      };
    case actions.REQUEST_OTP_FAILED:
      return {
        ...state,
        isRequestOtp: false,
      };
    case actions.REQUESTED_OTP:
      return {
        ...state,
        otpMessage: action.payload,
        isErrorOtp: action.isErrorOtp,
      };
    // VERIFY OTP
    case actions.VERIFIED_OTP:
      return {
        ...state,
        verifyOtpMessage: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
      };
    case actions.RESET_VERIFIED_OTP:
      return {
        ...state,
        verifyOtpMessage: null,
        isErrorVerifyOtp: false,
      };
    case actions.VERIFYING_OTP:
      return {
        ...state,
        isVerifyOtp: action.payload,
      };
    case actions.VERIFY_OTP_FAILED:
      return {
        ...state,
        isVerifyOtp: false,
      };
    case actions.LOADING_SUPPORTED_BANKS:
      return {
        ...state,
        loadingSupportedBanks: true,
      };
    case actions.SUPPORTED_BANK_LOADED:
      return {
        ...state,
        loadingSupportedBanks: false,
        supportedBanks: action.supportedBanks,
      };
    case actions.LOADING_SUPPORTED_BANKS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        loadingSupportedBanks: false,
        supportedBanks: null,
      };
    case actions.SENDING_SMS_OTP:
      return {
        ...state,
        loadingSendingOtp: true,
        otpSent: false,
      };
    case actions.SMS_OTP_SENT:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: action.otpSent,
        otpMessage: action.otpMessage,
      };
    case actions.SENDING_SMS_OTP_FAILED:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: action.otpSent,
        otpMessage: action.otpMessage,
      };
    // case actions.RESET_OTP:
    //   return {
    //     ...state,
    //     loadingSendingOtp: false,
    //     otpSent: false,
    //     otpMessage: null,
    //   };
    case actions.SAVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingSavingBank: true,
      };
    case actions.BANK_ACCOUNT_SAVED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: action.result,
        bankAccounts: action.bankAccounts,
      };
    case actions.SAVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: null,
      };
    // case actions.RESET_SAVING_NOTIF:
    //   return {
    //     ...state,
    //     loadingSavingBank: false,
    //     savingBankResult: null,
    //   };
    case actions.RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.RESEND_OTP_SENT:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.RESENDING_OTP_FAILED:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.W_RESET_RESEND_OTP:
      return {
        ...state,
        resendSuccessMessage: null,
        isErrorResendOtp: false,
      };
    case actions.W_RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.W_RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendSuccessMessage: action.payload,
        isErrorResendOtp: action.isErrorResendOtp,
      };
    case actions.W_RESEND_OTP_FAILED:
      return {
        ...state,
        isResendOtp: false,
        loadingResendingOtp: true,
      };
    case actions.UPDATING_BANK_ACCOUNT:
      return {
        ...state,
        loadingUpdatingBank: true,
      };
    case actions.BANK_ACCOUNT_UPDATED:
      const updatedBankAccount = action.updatedBankAccount;
      const bankAccountList = state.bankAccounts;
      for (let i = 0; i < bankAccountList.length; i++) {
        const account = bankAccountList[i];
        if (updatedBankAccount.Id === account.Id) {
          bankAccountList[i].alias = updatedBankAccount.alias;
          bankAccountList[i].accHolderFname = updatedBankAccount.accHolderFname;
          bankAccountList[i].accHolderMname = updatedBankAccount.accHolderMname;
          bankAccountList[i].accHolderLname = updatedBankAccount.accHolderLname;
          bankAccountList[i].accHolderPhone = updatedBankAccount.accHolderPhone;
          bankAccountList[i].accHolderEmail = updatedBankAccount.accHolderEmail;
          bankAccountList[i].accHolderAddress = updatedBankAccount.accHolderAddress;
          bankAccountList[i].accHolderCity = updatedBankAccount.accHolderCity;
          bankAccountList[i].accState = updatedBankAccount.accState;
          bankAccountList[i].zip = updatedBankAccount.zip;
        }
      }
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        // bankAccounts: action.bankAccounts,
        bankAccounts: bankAccountList,
        updateSuccess: true,
      };
    case actions.UPDATING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        updateSuccess: false,
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: null,
        loadingSavingBank: false,
        savingBankResult: null,
        loadingSendingOtp: false,
        otpSent: false,
        otpMessage: null,
        loadingUpdatingBank: false,
        updatedBankResult: false,
        updateSuccess: false,
        loadingRemovingBank: false,
        removedBankResult: false,
        removeSuccess: false,
        changingFailed: false,
        loadingChangingFavorite: false,
        changingStatusFailed: false,
        changingStatusSuccess: false,
        loadingChangingStatus: false,
        loadingSendingAutosweepOtp: false,
        autosweepOtpSent: false,
        autosweepOtpMessage: null,
        savingAutoSweep: false,
        savingAutoSweepResult: null,
        savingAutoSweepMessage: null,
      };
    case actions.REMOVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingRemovingBank: true,
      };
    case actions.BANK_ACCOUNT_REMOVED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        bankAccounts: action.bankAccounts,
        removeSuccess: true,
      };
    case actions.REMOVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        removeSuccess: false,
      };
    case actions.CHANGING_FAVORITE:
      return {
        ...state,
        loadingChangingFavorite: true,
      };
    case actions.FAVORITE_CHANGED:
      return {
        ...state,
        loadingChangingFavorite: false,
        bankAccounts: action.bankAccounts,
        fetchEnrolledBankAccountPagination: action.pagination,
        // fetchedEnrolledBankAccountData: action.fetchedEnrolledBankAccountData

      };
    case actions.CHANGING_FAVORITE_FAILED:
      return {
        ...state,
        loadingChangingFavorite: false,
        changingFailed: true,
      };
    case actions.CHANGING_STATUS:
      return {
        ...state,
        loadingChangingStatus: true,
      };
    case actions.STATUS_CHANGED:
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        fetchEnrolledBankAccountPagination: action.pagination,
        // fetchedEnrolledBankAccountData: action.fetchedEnrolledBankAccountData,
        loadingChangingStatus: false,
        changingStatusSuccess: true,
      };
    case actions.CHANGING_STATUS_FAILED:
      return {
        ...state,
        changingStatusFailed: true,
        loadingChangingStatus: false,
      };
    case actions.EMAIL_NOTIF:
      return {
        ...state,
        emailNotif: action.payload,
        // changingFailed: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADING:
      return {
        ...state,
        downloadingWithdrawal: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADED:
      return {
        ...state,
        downloadedWithdrawalData: action.payload,
        downloadingWithdrawal: false,
      };
    case actions.DOWNLOAD_WITHDRAWAL_MESSAGE:
      return {
        ...state,
        downloadWithdrawalMessage: action.payload,
        downloadedWithdrawalData: null,
        downloadingWithdrawal: false,
      };
    case actions.DOWNLOAD_SPLIT_LOADING:
      return {
        ...state,
        downloadingSplit: true,
      };
    case actions.DOWNLOAD_SPLIT_LOADED:
      return {
        ...state,
        downloadedSplitData: action.payload,
        downloadingSplit: false,
      };
    case actions.DOWNLOAD_SPLIT_MESSAGE:
      return {
        ...state,
        downloadSplitMessage: action.payload,
        downloadedSplitData: null,
        downloadingSplit: false,
      };
    case actions.DOWNLOAD_TOPUP_LOADING:
      return {
        ...state,
        downloadingTopup: true,
      };
    case actions.DOWNLOAD_TOPUP_LOADED:
      return {
        ...state,
        downloadedTopupData: action.payload,
        downloadingTopup: false,
      };
    case actions.DOWNLOAD_TOPUP_MESSAGE:
      return {
        ...state,
        downloadTopupMessage: action.payload,
        downloadedTopupData: null,
        downloadingTopup: false,
      };
      case actions.DOWNLOAD_AUTO_SETTLEMENT_LOADING:
        return {
          ...state,
          downloadingAutoSettlement: true,
        };
      case actions.DOWNLOAD_AUTO_SETTLEMENT_LOADED:
        return {
          ...state,
          downloadedAutoSettlementData: action.payload,
          downloadingAutoSettlement: false,
        };
      case actions.DOWNLOAD_AUTO_SETTLEMENT_MESSAGE:
        return {
          ...state,
          downloadAutoSettlementMessage: action.payload,
          downloadedAutoSettlementData: null,
          downloadingAutoSettlement: false,
        };
    case actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.FETCH_WITHDRAWAL_REQUEST_FAILED:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.UPDATING_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: action.payload,
      };
    case actions.UPDATED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateData: action.payload,
      };
    case actions.UPDATING_FAILED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: false,
      };
    case actions.SENDING_AUTOSWEEP_OTP:
      return {
        ...state,
        loadingSendingAutosweepOtp: true,
        autosweepOtpSent: false,
      };
    case actions.AUTOSWEEP_OTP_SENT:
      return {
        ...state,
        loadingSendingAutosweepOtp: false,
        autosweepOtpSent: action.otpSent,
        autosweepOtpMessage: action.otpMessage,
      };
    case actions.SENDING_AUTOSWEEP_OTP_FAILED:
      return {
        ...state,
        loadingSendingAutosweepOtp: false,
        autosweepOtpSent: action.otpSent,
        autosweepOtpMessage: action.otpMessage,
      };
    case actions.SAVING_AUTO_SWEEP:
      return {
        ...state,
        savingAutoSweep: true,
      };
    case actions.SAVED_AUTO_SWEEP:
      const newAutoSweep = action.result;
      delete newAutoSweep.success;
      const bankList = state.bankAccounts;
      for (let i = 0; i < bankList.length; i++) {
        const bank = bankList[i];
        if (newAutoSweep.bankAccountNo === bank.bankAccountNo) {
          bankList[i].autosweepInfo = newAutoSweep;
        }
      }
      return {
        ...state,
        savingAutoSweep: false,
        savingAutoSweepResult: action.result,
        savingAutoSweepMessage: action.message,
        bankInfo: bankList,
      };
    case actions.SAVING_AUTO_SWEEP_FAILED:
      return {
        ...state,
        savingAutoSweep: false,
        savingAutoSweepResult: null,
        savingAutoSweepMessage: action.message,
      };
    case actions.WALLET_TOP_UP_SUCCESS:
      return {
        ...state,
        walletTopUpResponse: action.result,
      };
    case actions.WALLET_TOP_UP_FAILED:
      return {
        ...state,
        walletTopUpErrorMessage: action.message,
      };
    case actions.WALLET_TOP_UP_RESET:
      return {
        ...state,
        walletTopUpResponse: null,
        walletTopUpErrorMessage: null,
      };
    case actions.DOWNLOADING_BIZWALLET_REPORT:
      return {
        ...state,
        downloadingBizwalletReport: true,
      };
    case actions.DOWNLOADED_BIZWALLET_REPORT:
      return {
        ...state,
        downloadedBizwalletReport: action.payload,
        downloadingBizwalletReport: false,
      };
    case actions.DOWNLOAD_BIZWALLET_REPORT_ERROR:
      return {
        ...state,
        downloadingBizwalletReport: false,
        downloadedBizwalletReport: null,
        downloadBizwalletReportMessage: action.payload,
      };
    case actions.RESET_DOWNLOAD_BIZWALLET_REPORT:
      return {
        ...state,
        downloadingBizwalletReport: false,
        downloadedBizwalletReport: null,
        downloadBizwalletReportMessage: null,
      };
    case actions.CENTINEL_TOPPING_UP:
      return {
        ...state,
        centinelTopUpLoading: true,
      };
    case actions.CENTINEL_TOP_UP_SUCCESS:
      return {
        ...state,
        centinelTopUpLoading: false,
        centinelTopUpResponse: action.result,
        walletDetails: action.walletDetails ? action.walletDetails : state.walletDetails,
      };
    case actions.CENTINEL_TOP_UP_FAILED:
      return {
        ...state,
        centinelTopUpLoading: false,
        centinelTopUpErrorMessage: action.message,
      };
    case actions.CENTINEL_TOP_UP_RESET:
      return {
        ...state,
        centinelTopUpLoading: false,
        centinelTopUpResponse: null,
        centinelTopUpErrorMessage: null,
      };
    case actions.DONE_SENDING_REPORT_EMAIL:
      return {
        ...state,
        bocReport: action.result,
        isBocReport: false,
      };
    case actions.SENDING_REPORT_EMAIL:
      return {
        ...state,
        isBocReport: true,
      };
    case actions.RESET_REPORT_EMAIL:
      return {
        ...state,
        isBocReport: false,
        bocReport: null,
      };
    case actions.SEARCHED_WITHDRAW_TRANSACTION:
      return {
        ...state,
        searchingWithdrawTransaction: false,
        searchWithdrawTransactionLoadmore: false,
        searchedWithdrawTransactionData: action.payload,
        searchWithdrawTransactionPagination: action.pagination,
      };
    case actions.SEARCHING_WITHDRAW_TRANSACTION:
      return {
        ...state,
        searchingWithdrawTransaction: true,
        searchedWithdrawTransactionData: null,
        searchWithdrawTransactionPagination: null,
        searchWithdrawTransactionLoadmore: false,
        isErrorSearchWithdrawRequest: false,
      };
    case actions.SEARCH_LOADMORE_WITHDRAW_TRANSACTION:
      return {
        ...state,
        searchWithdrawTransactionLoadmore: true,
      };
    case actions.SEARCH_WITHDRAW_TRANSACTION_FAILED:
      return {
        ...state,
        searchingWithdrawTransaction: false,
        searchWithdrawTransactionLoadmore: false,
      };
    case actions.SEARCH_WITHDRAW_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSearchWithdrawRequest: action.payload,
        searchWithdrawTransactionLoadmore: false,
      };
    case actions.RESET_SEARCH_WITHDRAW_TRANSACTION:
      return {
        ...state,
        searchedWithdrawTransactionData: null,
        searchingWithdrawTransaction: false,
        searchWithdrawTransactionPagination: null,
        searchWithdrawTransactionLoadmore: false,
        isErrorSearchWithdrawRequest: false,
      };
    //
    case actions.SEARCHED_SPLIT_TRANSACTION:
      return {
        ...state,
        searchingSplitTransaction: false,
        searchSplitTransactionLoadmore: false,
        searchedSplitTransactionData: action.payload,
        searchSplitTransactionPagination: action.pagination,
      };
    case actions.SEARCHING_SPLIT_TRANSACTION:
      return {
        ...state,
        searchingSplitTransaction: true,
        searchedSplitTransactionData: null,
        searchSplitTransactionPagination: null,
        searchSplitTransactionLoadmore: false,
        isErrorSearchSplitRequest: false,
      };
    case actions.SEARCH_LOADMORE_SPLIT_TRANSACTION:
      return {
        ...state,
        searchSplitTransactionLoadmore: true,
      };
    case actions.SEARCH_SPLIT_TRANSACTION_FAILED:
      return {
        ...state,
        searchingSplitTransaction: false,
        searchSplitTransactionLoadmore: false,
      };
    case actions.SEARCH_SPLIT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSearchSplitRequest: action.payload,
        searchSplitTransactionLoadmore: false,
      };
    case actions.RESET_SEARCH_SPLIT_TRANSACTION:
      return {
        ...state,
        searchedSplitTransactionData: null,
        searchingSplitTransaction: false,
        searchSplitTransactionPagination: null,
        searchSplitTransactionLoadmore: false,
        isErrorSearchSplitRequest: false,
      };
    //
    case actions.SEARCHED_AUTO_SETTLEMENT:
      return {
        ...state,
        searchingAutoSettlement: false,
        searchAutoSettlementLoadmore: false,
        searchedAutoSettlementData: action.payload,
        searchAutoSettlementPagination: action.pagination,
        fetchingAutoSettlement: false,
      };
    case actions.SEARCHING_AUTO_SETTLEMENT:
      return {
        ...state,
        searchingAutoSettlement: true,
        searchedAutoSettlementData: null,
        searchAutoSettlementPagination: null,
        searchAutoSettlementLoadmore: false,
        isErrorSearchAutoSettlementRequest: false,
      };
    case actions.SEARCH_LOADMORE_AUTO_SETTLEMENT:
      return {
        ...state,
        searchAutoSettlementLoadmore: true,
      };
    case actions.SEARCH_AUTO_SETTLEMENT_FAILED:
      return {
        ...state,
        searchingAutoSettlement: false,
        searchAutoSettlementLoadmore: false,
      };
    case actions.SEARCH_AUTO_SETTLEMENT_ERROR:
      return {
        ...state,
        isErrorSearchAutoSettlementRequest: action.payload,
        searchAutoSettlementLoadmore: false,
      };
    case actions.RESET_SEARCH_AUTO_SETTLEMENT:
      return {
        ...state,
        searchedAutoSettlementData: null,
        searchingAutoSettlement: false,
        searchAutoSettlementPagination: null,
        searchAutoSettlementLoadmore: false,
        isErrorSearchAutoSettlementRequest: false,
      };
    //
    case actions.SEARCHED_TOPUP_TRANSACTION:
      return {
        ...state,
        searchingTopupTransaction: false,
        searchTopupTransactionLoadmore: false,
        searchedTopupTransactionData: action.payload,
        searchTopupTransactionPagination: action.pagination,
      };
    case actions.SEARCHING_TOPUP_TRANSACTION:
      return {
        ...state,
        searchingTopupTransaction: true,
        searchedTopupTransactionData: null,
        searchTopupTransactionPagination: null,
        searchTopupTransactionLoadmore: false,
        isErrorSearchTopupRequest: false,
      };
    case actions.SEARCH_LOADMORE_TOPUP_TRANSACTION:
      return {
        ...state,
        searchTopupTransactionLoadmore: true,
      };
    case actions.SEARCH_TOPUP_TRANSACTION_FAILED:
      return {
        ...state,
        searchingTopupTransaction: false,
        searchTopupTransactionLoadmore: false,
      };
    case actions.SEARCH_TOPUP_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSearchTopupRequest: action.payload,
        searchTopupTransactionLoadmore: false,
      };
    //
    case actions.SEARCHED_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        searchingOnlinePaymentTransaction: false,
        searchOnlinePaymentTransactionLoadmore: false,
        searchedOnlinePaymentTransactionData: action.payload,
        searchOnlinePaymentTransactionPagination: action.pagination,
      };
    case actions.SEARCHING_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        searchingOnlinePaymentTransaction: true,
        searchedOnlinePaymentTransactionData: null,
        searchOnlinePaymentTransactionPagination: null,
        searchOnlinePaymentTransactionLoadmore: false,
        isErrorSearchOnlinePaymentRequest: false,
      };
    case actions.SEARCH_LOADMORE_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        searchOnlinePaymentTransactionLoadmore: true,
      };
    case actions.SEARCH_ONLINE_PAYMENT_TRANSACTION_FAILED:
      return {
        ...state,
        searchingOnlinePaymentTransaction: false,
        searchOnlinePaymentTransactionLoadmore: false,
      };
    case actions.SEARCH_ONLINE_PAYMENT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSearchOnlinePaymentRequest: action.payload,
        searchOnlinePaymentTransactionLoadmore: false,
      };
    case actions.RESET_SEARCH_TOPUP_TRANSACTION:
      return {
        ...state,
        searchedTopupTransactionData: null,
        searchingTopupTransaction: false,
        searchTopupTransactionPagination: null,
        searchTopupTransactionLoadmore: false,
        isErrorSearchTopupRequest: false,
      };
    case actions.RESET_SEARCH_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        searchedOnlinePaymentTransactionData: null,
        searchingOnlinePaymentTransaction: false,
        searchOnlinePaymentTransactionPagination: null,
        searchOnlinePaymentTransactionLoadmore: false,
        isErrorSearchOnlinePaymentRequest: false,
      };
    //
    case actions.FETCHED_ENROLLED_BANK_ACCOUNT:
      return {
        ...state,
        fetchingEnrolledBankAccount: false,
        fetchEnrolledBankAccountLoadmore: false,
        bankAccounts: action.payload,
        fetchEnrolledBankAccountPagination: action.pagination,
      };
    case actions.FETCHING_ENROLLED_BANK_ACCOUNT:
      return {
        ...state,
        fetchingEnrolledBankAccount: true,
      };
    case actions.FETCH_LOADMORE_ENROLLED_BANK_ACCOUNT:
      return {
        ...state,
        fetchEnrolledBankAccountLoadmore: true,
      };
    case actions.FETCH_ENROLLED_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        fetchingEnrolledBankAccount: false,
        fetchEnrolledBankAccountLoadmore: false,
      };
    case actions.FETCH_ENROLLED_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        isErrorFetchEnrolledBankAccountRequest: action.payload,
        fetchEnrolledBankAccountLoadmore: false,
      };
    case actions.RESET_FETCH_ENROLLED_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: null,
        fetchingEnrolledBankAccount: false,
        fetchEnrolledBankAccountPagination: null,
        fetchEnrolledBankAccountLoadmore: false,
        isErrorFetchEnrolledBankAccountRequest: false,
      };
    case actions.FETCHED_ALL_WALLETS:
      return {
        ...state,
        isFetchingAllWallet: false,
        allWallets: action.payload,
      };
    case actions.FETCHED_ALL_WALLETS_RESET:
      return {
        ...state,
        isFetchingAllWallet: false,
        allWallets: null,
        allWalletError: null,
        walletId: null
      };
    case actions.FETCHING_ALL_WALLETS:
      return {
        ...state,
        isFetchingAllWallet: true,
      };
    case actions.FETCH_ALL_WALLETS_FAILED:
      return {
        ...state,
        isFetchingAllWallet: false,
        allWalletError: action.payload
      };
    case actions.SET_WALLET_ID:
      return {
        ...state,
        walletId: action.payload,
      };
    case actions.SAVED_WALLET:
      return {
        ...state,
        savedWallet: action.payload,
        isSavingWallet: false,
        savedWalletError: null,
      };
    case actions.RESET_SAVED_WALLET:
      return {
        ...state,
        savedWallet: null,
        isSavingWallet: false,
        savedWalletError: null,
      };
    case actions.SAVING_WALLET:
      return {
        ...state,
        isSavingWallet: true,
      };
    case actions.SAVE_WALLET_FAILED:
      return {
        ...state,
        isSavingWallet: false,
        savedWallet: null,
        savedWalletError: action.payload,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    case actions.FETCHED_WALLET_BY_MID:
      return {
        ...state,
        fetchingWallet: false,
        fetchedWallet: action.payload,
      };
    case actions.FETCHING_WALLET_BY_MID:
      return {
        ...state,
        fetchingWallet: true,
      };
    case actions.FETCH_WALLET_BY_MID_FAILED:
      return {
        ...state,
        fetchingWallet: false,
        fetchedWalletError: action.payload
      };
    case actions.APPROVED_WALLET:
      const fetchedWallet = state.fetchedWallet;
      const updatedWallet = [];
      for (let i = 0; i < fetchedWallet.length; i++) {
        const item = fetchedWallet[i];
        if (action.payload.walletId === item.walletId) {
          fetchedWallet[i].status = action.payload.status;
        }
        updatedWallet.push(fetchedWallet[i]);
      }
      return {
        ...state,
        approvedWallet: action.payload,
        approvingWallet: false,
        approvedWalletError: null,
        fetchedWallet: updatedWallet
      };
    case actions.RESET_APPROVED_WALLET:
      return {
        ...state,
        approvedWallet: null,
        approvingWallet: false,
        approvedWalletError: null,
      };
    case actions.APPROVING_WALLET:
      return {
        ...state,
        approvingWallet: true,
      };
    case actions.APPROVE_WALLET_FAILED:
      return {
        ...state,
        approvingWallet: false,
        approvedWallet: null,
        approvedWalletError: action.message,
      };
    case actions.FETCHED_ROLLING_RESERVE:
      return {
        ...state,
        fetchingRollingReserveAmount: false,
        fetchedRollingReserveAmount: action.payload,
      };
    case actions.FETCHING_ROLLING_RESERVE:
      return {
        ...state,
        fetchingRollingReserveAmount: true,
      };
    case actions.FETCH_ROLLING_RESERVE_FAILED:
      return {
        ...state,
        fetchingRollingReserveAmount: false,
        fetchedRollingReserveAmount: action.payload
      };
    case actions.RESET_ROLLING_RESERVE:
        return {
          ...state,
          fetchedRollingReserveAmount: null,
          fetchingRollingReserveAmount: false,
        };
    case actions.FETCH_WALLET_LINK_SUCCESS:
      return {
        ...state,
        walletLinkData: action.payload,
        walletLinkLoading: false,
        walletLinkFailed: null,
      };
    case actions.FETCH_WALLET_LINK_LOADING:
      return {
        ...state,
        walletLinkLoading: true,
      };
    case actions.FETCH_WALLET_LINK_FAILED:
      return {
        ...state,
        walletLinkFailed: action.payload,
        walletLinkLoading: false,
        walletLinkData: null,
      };
    case actions.CANCEL_ONLINE_PAYMENT_SUCCESS:
      return {
        ...state,
        cancelOnlinePaymentData: action.payload,
        cancelOnlinePaymentLoading: false,
        cancelOnlinePaymentFailed: null,
      };
    case actions.CANCEL_ONLINE_PAYMENT_LOADING:
      return {
        ...state,
        cancelOnlinePaymentLoading: true,
      };
    case actions.CANCEL_ONLINE_PAYMENT_FAILED:
      return {
        ...state,
        cancelOnlinePaymentData: null,
        cancelOnlinePaymentLoading: false,
        cancelOnlinePaymentFailed: action.payload,
      };
    case actions.CANCEL_ONLINE_PAYMENT_RESET:
      return {
        ...state,
        cancelOnlinePaymentData: null,
        cancelOnlinePaymentLoading: false,
        cancelOnlinePaymentFailed: null,
      };
  case actions.SEND_OTP_CENTINEL_TOPUP_LOADING:
    return {
      ...state,
      sendOtpCentinelTopupLoading: true,
        sendOtpCentinelTopupError: null,
        sendOtpCentinelTopupSuccess: null,
      };
  case actions.SEND_OTP_CENTINEL_TOPUP_ERROR:
    return {
      ...state,
      sendOtpCentinelTopupLoading: false,
      sendOtpCentinelTopupError: action.payload,
      sendOtpCentinelTopupSuccess: null,
    };
  case actions.SEND_OTP_CENTINEL_TOPUP_SUCCESS:
    return {
      ...state,
      sendOtpCentinelTopupLoading: false,
      sendOtpCentinelTopupError: null,
      sendOtpCentinelTopupSuccess: action.payload,
    };
  case actions.SEND_OTP_CENTINEL_TOPUP_RESET:
    return {
      ...state,
      sendOtpCentinelTopupLoading: false,
      sendOtpCentinelTopupError: null,
      sendOtpCentinelTopupSuccess: null,
    };
    

  case actions.RESEND_OTP_CENTINEL_TOPUP_LOADING:
    return {
      ...state,
      resendOtpCentinelTopupLoading: true,
      resendOtpCentinelTopupError: null,
      resendOtpCentinelTopupSuccess: null,
    };
  case actions.RESEND_OTP_CENTINEL_TOPUP_ERROR:
    return {
      ...state,
      resendOtpCentinelTopupLoading: false,
      resendOtpCentinelTopupError: action.payload,
      resendOtpCentinelTopupSuccess: null,
    };
  case actions.RESEND_OTP_CENTINEL_TOPUP_SUCCESS:
    return {
      ...state,
      resendOtpCentinelTopupLoading: false,
      resendOtpCentinelTopupError: null,
      resendOtpCentinelTopupSuccess: action.payload,
    };
  case actions.RESEND_OTP_CENTINEL_TOPUP_RESET:
    return {
      ...state,
      resendOtpCentinelTopupLoading: false,
      resendOtpCentinelTopupError: null,
      resendOtpCentinelTopupSuccess: null,
    };

  case actions.VERIFY_OTP_CENTINEL_TOPUP_LOADING:
    return {
      ...state,
      verifyOtpCentinelTopupLoading: true,
      verifyOtpCentinelTopupError: null,
      verifyCentinelTopupSuccess: null,
    };
  case actions.VERIFY_OTP_CENTINEL_TOPUP_ERROR:
    return {
      ...state,
      verifyOtpCentinelTopupLoading: false,
      verifyOtpCentinelTopupError: action.payload,
      verifyCentinelTopupSuccess: null,
    };
  case actions.VERIFY_OTP_CENTINEL_TOPUP_SUCCESS:
    return {
      ...state,
      verifyOtpCentinelTopupLoading: false,
      verifyOtpCentinelTopupError: null,
      verifyOtpCentinelTopupSuccess: action.payload,
    };
  case actions.VERIFY_OTP_CENTINEL_TOPUP_RESET:
    return {
      ...state,
      verifyOtpCentinelTopupLoading: false,
      verifyOtpCentinelTopupError: null,
      verifyOtpCentinelTopupSuccess: null,
    };
  case actions.FETCH_MERCHANT_BIR_DOCUMENTS_SUCCESS:
    return {
      ...state,
      fetchMerchantBirDocsSuccess: action.payload,
      fetchMerchantBirDocsLoading: false,
      fetchMerchantBirDocsFailed: null,
    };
  case actions.FETCH_MERCHANT_BIR_DOCUMENTS_LOADING:
    return {
      ...state,
      fetchMerchantBirDocsLoading: true,
    };
  case actions.FETCH_MERCHANT_BIR_DOCUMENTS_FAILED:
    return {
      ...state,
      fetchMerchantBirDocsSuccess: null,
      fetchMerchantBirDocsLoading: false,
      fetchMerchantBirDocsFailed: action.payload,
    };
  case actions.FETCH_MERCHANT_BIR_DOCUMENTS_RESET:
    return {
      ...state,
      fetchMerchantBirDocsSuccess: null,
      fetchMerchantBirDocsLoading: false,
      fetchMerchantBirDocsFailed: null,
    };
  case actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_SUCCESS:
    return {
      ...state,
      submitMerchantBirDocsSuccess: action.payload,
      submitMerchantBirDocsLoading: false,
      submitMerchantBirDocsFailed: null,
    };
  case actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_LOADING:
    return {
      ...state,
      submitMerchantBirDocsLoading: true,
    };
  case actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_FAILED:
    return {
      ...state,
      submitMerchantBirDocsSuccess: null,
      submitMerchantBirDocsLoading: false,
      submitMerchantBirDocsFailed: action.payload,
    };
  case actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_RESET:
    return {
      ...state,
      submitMerchantBirDocsSuccess: null,
      submitMerchantBirDocsLoading: false,
      submitMerchantBirDocsFailed: null,
    };
  case actions.UPDATE_BIR_DOCUMENTS_STATUS_SUCCESS:
    return {
      ...state,
      updateBirDocsSuccess: action.payload,
      updateBirDocsLoading: false,
      updateBirDocsFailed: null,
    };
  case actions.UPDATE_BIR_DOCUMENTS_STATUS_LOADING:
    return {
      ...state,
      updateBirDocsLoading: true,
    };
  case actions.UPDATE_BIR_DOCUMENTS_STATUS_FAILED:
    return {
      ...state,
      updateBirDocsSuccess: null,
      updateBirDocsLoading: false,
      updateBirDocsFailed: action.payload,
    };
  case actions.UPDATE_BIR_DOCUMENTS_STATUS_RESET:
    return {
      ...state,
      updateBirDocsSuccess: null,
      updateBirDocsLoading: false,
      updateBirDocsFailed: null,
    };
  case actions.DELETE_MERCHANT_BIR_DOCUMENTS_SUCCESS:
    return {
      ...state,
      deleteMerchantBirDocsSuccess: action.payload,
      deleteMerchantBirDocsLoading: false,
      deleteMerchantBirDocsFailed: null,
    };
  case actions.DELETE_MERCHANT_BIR_DOCUMENTS_LOADING:
    return {
      ...state,
      deleteMerchantBirDocsLoading: true,
    };
  case actions.DELETE_MERCHANT_BIR_DOCUMENTS_FAILED:
    return {
      ...state,
      deleteMerchantBirDocsSuccess: null,
      deleteMerchantBirDocsLoading: false,
      deleteMerchantBirDocsFailed: action.payload,
    };
  case actions.DELETE_MERCHANT_BIR_DOCUMENTS_RESET:
    return {
      ...state,
      deleteMerchantBirDocsSuccess: null,
      deleteMerchantBirDocsLoading: false,
      deleteMerchantBirDocsFailed: null,
    };
  case actions.FETCH_FILE_BASE64_SUCCESS:
    return {
      ...state,
      fetchFileBase64Success: action.payload,
      fetchFileBase64Loading: false,
      fetchFileBase64Failed: null,
    };
  case actions.FETCH_FILE_BASE64_LOADING:
    return {
      ...state,
      fetchFileBase64Loading: true,
    };
  case actions.FETCH_FILE_BASE64_FAILED:
    return {
      ...state,
      fetchFileBase64Success: null,
      fetchFileBase64Loading: false,
      fetchFileBase64Failed: action.payload,
    };
  case actions.FETCH_FILE_BASE64_RESET:
    return {
      ...state,
      fetchFileBase64Success: null,
      fetchFileBase64Loading: false,
      fetchFileBase64Failed: null,
    };
    
  // OTP REMOVED ENROLLED BANK ACCOUNTS
  case actions.REQUESTING_REMOVE_ENROLLED_BANK_OTP:
    return{
      ...state,
      otpRemoveBankLoading: action.loading,
      isErrorVerifyRemoveBankOtp: false,
      otpRemoveBankLimitReachMessage: action.otpRemoveBankLimitReachMessage,
      verifyRemoveBankOtpMessage: null,
      removeEnrolleBankError: null,
    };
  case actions.RESENDING_REMOVE_BANK_OTP:
  return {  
    ...state,
    resendingRemoveBankOtpLoading: action.payload,
    isErrorVerifyRemoveBankOtp: action.isErrorVerifyRemoveBankOtp,
    verifyRemoveBankOtpMessage: null,
  };
  case actions.SET_OTP_REMOVE_BANNER:
    return{
      ...state,
      otpRemoveBankBannerModal: action.otpRemoveBankBanner,
      otpRemoveBankData: action.otpData

    };
  case actions.SET_OTP_REMOVE_MODAL:
    return {
      ...state,
      otpRemoveBankModal: action.otpRemoveBankModal,
      otpRemoveBankBannerModal: action.otpRemoveBankBanner,
      otpRemoveBankData: action.otpData,
      otpRemoveBankMessage: action.otpMessage,
      otpRemoveBankEmail: action.otp_email,
      isConfirmRemovalModal: action.isConfirmRemovalModal,
    };
  case actions.OTP_REMOVE_BANK_TOKEN:
    return {
      ...state,
      otpRemoveBankToken: action.payload,
    };
  case actions.REMOVE_ENROLLED_BANK_ERROR:
    return{
      ...state,
      removeEnrolleBankError: action.error,
    };
  case actions.VERIFYING_ENROLLED_BANK_ERROR:
    return{
      ...state,
      verifyingEnrolleBankError: action.error,
    };
  case actions.VERIFYING_REMOVE_BANK_OTP:
    return {
      ...state,
      verifyingRemoveBankOtpLoading: action.loading,
      verifyingEnrolleBankError: action.verifyingEnrolleBankError,
    };
  case actions.VALIDATED_REMOVE_ENROLLED_BANK_OTP:
    return{
      ...state,
      verifiedOtp: action.verifiedOtp,
      verifyingRemoveBankOtpLoading: false,
      verifyingRemoveBankData: action.payload,
      isErrorVerifyRemoveBankOtp: action.isErrorVerifyRemoveBankOtp,
      verifyRemoveBankOtpMessage: action.verifyRemoveBankOtpMessage,
      otpRemoveBankBannerModal: action.otpRemoveBankBanner,
      otpRemoveBankModal: action.otpRemoveBankModal,
      otpRemoveBankLimitReachMessage: action.limitReachMessage,
    };
  case actions.CLEAR_REMOVE_BANK_OTP_DATA:
    return {
      ...state,
      otpRemoveBankData: null,
      otpRemoveBankToken: null,
      removeEnrolleBankError: null,
      verifyingRemoveBankData: null,
      isErrorVerifyRemoveBankOtp: false,
      verifyRemoveBankOtpMessage: null,
      otpRemoveBankMessage: null,
      otpRemoveBankEmail: null,

    };
  case actions.CLEAR_OTP_REMOVE_BANK_MESSAGE:
    return {
      ...state,
      verifyRemoveBankOtpMessage: null,
      otpRemoveBankLimitReachMessage: null,
      otpRemoveBankMessage: null,

    };
  //END
  case actions.CLEAR_VERIFIED_OTP:
    return {
      ...state,
      verifiedOtp: false,
    };
  case actions.POP_REMOVE_BANK_BANNER:
    return {
      ...state,
      otpRemoveBankBannerModal: false,
    };
  case actions.POP_REMOVE_BANK_MODAL:
    return {
      ...state,
      otpRemoveBankModal: false,
    };
  case actions.CONFIRM_REMOVAL_MODAL:
    return {
      ...state,
      isConfirmRemovalModal: action.payload,
    };
  default:
      return state;
  }
}