import actions from './actions';

const initState = {
  isDownloadStoreQr: false,
  downloadedStoreQr: null,
  savingStoreMessage: null,
  isError: null,
  isAddDialog: false,
  fetchingStorePchannel: false,
  storePchannel: null,
  storePchannelStatus: null,
  changingPchannelStatus: false,
  deletingStore: false,
  deleteSuccess: false,
  deleteFailed: false,
  isDownloadPlainQr: false,
  downloadedPlainQr: null,
  storeRepModal: false,
  fetchAllPchannelsInfoSuccessData: null,
  fetchAllPchannelsInfoLoading: false,
  fetchAllPchannelsInfoFailedData: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.DOWNLOADED_STORE_QR:
      return {
        ...state,
        downloadedStoreQr: action.payload,
      };
    case actions.DOWNLOADING_STORE_QR:
      return {
        ...state,
        isDownloadStoreQr: action.payload,
      };
    case actions.DOWNLOAD_STORE_QR_FAILED:
      return {
        ...state,
        isDownloadStoreQr: false,
      };
    case actions.RESET_DOWNLOADED_STORE_QR:
      return {
        ...state,
        downloadedStoreQr: null,
      };
      case actions.DOWNLOADED_PLAIN_QR:
      return {
        ...state,
        downloadedPlainQr: action.payload,
      };
    case actions.DOWNLOADING_PLAIN_QR:
      return {
        ...state,
        isDownloadPlainQr: action.payload,
      };
    case actions.DOWNLOAD_PLAIN_QR_FAILED:
      return {
        ...state,
        isDownloadPlainQr: false,
      };
    case actions.RESET_DOWNLOADED_PLAIN_QR:
      return {
        ...state,
        downloadedPlainQr: null,
      };
    case actions.SAVING_STORE:
      return {
        ...state,
        isSavingStore: action.payload,
      };
    case actions.SAVED_STORE_MESSAGE:
      return {
        ...state,
        savingStoreMessage: action.payload,
        isSavingStore: false,
        isError: action.isError,
      };
    case actions.DIALOG_ADD_ACTION:
      return {
        ...state,
        isAddDialog: action.payload,
      };
    //STORE PCHANNEL
    case actions.FETCH_STORE_PCHANNEL:
      return initState;
    case actions.FETCHING_STORE_PCHANNEL:
      return {
        ...state,
        fetchingStorePchannel: true,
      };
    case actions.FETCHED_STORE_PCHANNEL:
      return {
        ...state,
        fetchingStorePchannel: false,
        storePchannel: action.storePchannel,
      };
    case actions.FETCH_STORE_PCHANNEL_FAILED:
      return {
        ...state,
        fetchingStorePchannel: false,
        storePchannel: null,
      };
    //CHANGE PCHANNEL STATUS
    case actions.CHANGE_STORE_PCHANNEL_STATUS:
      return initState;
    case actions.CHANGING_STORE_PCHANNEL_STATUS:
      return {
        ...state,
        changingPchannelStatus: true,
      };
    case actions.STORE_PCHANNEL_STATUS_CHANGED:
      return {
        ...state,
        changingPchannelStatus: false,
        storePchannelStatus: action.storePchannelStatus,
      };
    case actions.CHANGE_STORE_PCHANNEL_STATUS_FAILED:
      return {
        ...state,
        changingPchannelStatus: false,
        storePchannelStatus: null,
      };
    case actions.DELETING_STORE:
      return {
        ...state,
        deletingStore: true,
      };
    case actions.DELETED_STORE:
      return {
        ...state,
        deletingStore: false,
        deleteSuccess: true,
      };
    case actions.DELETE_STORE_FAILED:
      return {
        ...state,
        deletingStore: false,
        deleteSuccess: false,
        deleteFailed: true,
      };
    case actions.RESET_DELETE_STORE:
      return {
        ...state,
        deletingStore: false,
        deleteSuccess: false,
        deleteFailed: false,
      };
    case actions.OPEN_DOWNLOAD_STORE_REPORT_MODAL:
      return {
        ...state,
        storeRepModal: action.payload,
      };
    case actions.FETCH_ALL_PCHANNELS_INFO_SUCCESS:
      return {
        ...state,
        fetchAllPchannelsInfoSuccessData: action.payload,
        fetchAllPchannelsInfoLoading: false,
        fetchAllPchannelsInfoFailedData: null,
      }
    case actions.FETCH_ALL_PCHANNELS_INFO_LOADING:
      return {
        ...state,
        fetchAllPchannelsInfoLoading: true,
      }
    case actions.FETCH_ALL_PCHANNELS_INFO_FAILED:
      return {
        ...state,
        fetchAllPchannelsInfoSuccessData: null,
        fetchAllPchannelsInfoLoading: false,
        fetchAllPchannelsInfoFailedData: action.payload,
      }

    default:
      return state;
  }
}
