import { all, put, takeEvery, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';
import { AuthorizedGet, AuthorizedPut, AuthorizedPost } from '@iso/lib/helpers/resApiRequestorAuth';
import { encryptedGet, encryptedPost } from '../../library/helpers/resApiEncryptedRequestor';
import netcore from '@iso/lib/helpers/netcore';

export function* loadMerchantInfo() {
  yield takeEvery('LOAD_MERCHANT_INFO', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Corporate View' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.LOADING_MERCHANT_INFO,
      });
      const apiResult = yield fetchMerchantByMid(merchantId);
      // const result = apiResult.data;
      const imgResult = yield fetchImageRequest(apiResult.data.data.fileName);
      const withdrawalAccounts = yield fetchWithdrawalAccounts(merchantId);
      const finalBase64 = `data:${apiResult.data.data.fileType};base64,${imgResult.data.base64}`;
      const merchantInfo = yield fetchMerchantById(apiResult.data.data.Id);
      yield put({
        type: actions.MERCHANT_INFO_LOADED,
        merchantInfo: merchantInfo.data.data,
        merchantLogo: finalBase64,
        bankAccount: withdrawalAccounts.data.data,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_MERCHANT_INFO_FAILED,
      });
    }
  });
}

export function* fetchMerchantImage() {
  yield takeEvery('FETCH_MERCHANT_IMAGE', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_IMAGE,
      });
      const checkVerifiedStore = yield checkVerifiedEncrypted(payload.payload);
      if (checkVerifiedStore.status === 200 && checkVerifiedStore.data.store === 'VERIFIED') {
        const storeImg = yield fetchStoreImgEncrypted(payload.payload);
        if (storeImg.status === 200) {
          const imgResult = yield fetchImageRequestEncrypted(storeImg.data.data.fileName);
          const finalBase64 = imgResult.data.base64 !== 'NO IMAGE FOUND' ? `data:${storeImg.data.data.fileType};base64,${imgResult.data.base64}` : imgResult.data.base64;
          const storeChannels = yield fetchStoreChannelsEncrypted(payload.payload);
          const storeInfo = yield fetchStoreInformationEncrypted(payload.payload);
          // const paymentCount = yield getPaymentCount(storeInfo.data.data.merchantId);
          const limit = yield getLimitEncrypted(storeInfo.data.data.merchantId);
          const template = yield fetchStoreTemplateEncrypted({
            merchant_id: storeInfo.data.data.merchantId,
            type: payload.payload
          });
          if (storeChannels.status === 200 && storeInfo.data.success === true) {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: storeChannels.data.data,
              merchantLogo: finalBase64,
              grabToken: storeImg.data.data.grabToken,
              storeInfo: storeInfo.data.data,
              monthlyLimit: limit.data,
              // reachLimit: paymentCount.data.data,
              storeTemplate: template.data.template
            });
          } else if (storeInfo.data.success === false) {
            yield put({
              type: actions.FETCH_MERCHANT_IMAGE_FAILED,
              payload: 'Store not found.',
            });
          } else {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: [],
              merchantLogo: finalBase64,
              grabToken: storeImg.data.data.grabToken,
              storeInfo: storeInfo.data.data,
              monthlyLimit: limit.data,
              // reachLimit: paymentCount.data.data,
              storeTemplate: template.data.template
            });
          }
        } else {
          const storeChannels = yield fetchStoreChannelsEncrypted(payload.payload);
          const storeInfo = yield fetchStoreInformationEncrypted(payload.payload);
          // CHECK LIMIT
          // const paymentCount = yield getPaymentCount(storeInfo.data.data.merchantId);
          const limit = yield getLimitEncrypted(storeInfo.data.data.merchantId);
          const template = yield fetchStoreTemplateEncrypted({
            merchant_id: storeInfo.data.data.merchantId,
            type: payload.payload
          });
          // const monthlyLimit = limit.data ? limit.data.limit_per_month : 0;
          // const reachLimit = Number(paymentCount.data.data.reachLimit) || 0;
          if (storeChannels.status === 200 && storeInfo.data.success === true) {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: storeChannels.data.data,
              merchantLogo: null,
              grabToken: null,
              storeInfo: storeInfo.data.data,
              monthlyLimit: limit.data,
              // reachLimit: paymentCount.data.data,
              storeTemplate: template.data.template
            });
          } else if (storeInfo.data.success === false) {
            yield put({
              type: actions.FETCH_MERCHANT_IMAGE_FAILED,
              payload: 'Store not found.',
            });
          } else {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: null,
              merchantLogo: null,
              grabToken: null,
              storeInfo: storeInfo.data.data,
              monthlyLimit: limit.data,
              // reachLimit: paymentCount.data.data,
              storeTemplate: template.data.template
            });
          }
        }
      } else if (checkVerifiedStore.status === 200 && checkVerifiedStore.data.store === 'NOT VERIFIED') {
        yield put({
          type: actions.FETCH_MERCHANT_IMAGE_FAILED,
          // payload: checkVerifiedStore.data.message,
          payload: 'Payment via scan QR is not yet activated for this merchant. KYC approval is required.',
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_IMAGE_FAILED,
          payload: 'Store not found.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_IMAGE_FAILED,
        payload: 'Store not found.',
      });
    }
  });
}

export function* fetchMaintenancePChannels() {
  yield takeEvery('FETCH_MAINTENANCE_PCHANNELS', function* () {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCH_MAINTENANCE_PCHANNELS_LOADING,
      });
      const apiResult = identity ? yield fetchMaintenancePChannelsRequest() : yield fetchMaintenancePChannelsRequestEncrypted();
      const data = apiResult.data.data;
      if (apiResult.status === 200 && data.success) {
        const pchannels = []
        const nonbankOTC = []
        data.data.forEach((e) => {
          if (e.pmethod !== "nonbank_otc") {
            pchannels.push(e);
          } else {
            nonbankOTC.push(e);
          }
        });
        yield put({
          type: actions.FETCH_MAINTENANCE_PCHANNELS_SUCCESS,
          payload: {
            pchannels: pchannels,
            nonbankOTC: nonbankOTC
          }
        });
      }
      else {
        yield put({
          type: actions.FETCH_MAINTENANCE_PCHANNELS_FAILED,
          payload: apiResult
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MAINTENANCE_PCHANNELS_FAILED,
        payload: error
      });
    }
  })
}

export function* fetchCustomerData() {
  yield takeEvery('FETCH_CUSTOMER_DATA', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_CUSTOMER_DATA,
      });
      const customerData = yield getCustomerData(payload.payload);
      if (customerData.status === 200) {
        yield put({
          type: actions.FETCHED_CUSTOMER_DATA,
          payload: customerData.data.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CUSTOMER_DATA_FAILED,
      });
    }
  });
}

export function* paymentTransactionRequest() {
  yield takeEvery('PAYMENT_TRANSACTION_REQUEST', function* (payload) {
    try {
      yield put({
        type: actions.PAYMENT_TRANSACTION_REQUESTING,
      });
      const payTrxRequest = yield payTransactionRequest(payload.payload);
      const res = payTrxRequest.data;
      if (payTrxRequest.status === 200) {
        if (res.response_code === 'GR033') {
          // return dispatch(successItem(res));
          yield put({
            type: actions.PAYMENT_TRANSACTION_REQUESTED,
            payload: res,
          });
        } else {
          if (res.response_message) {
            yield put({
              type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
              payload: res.response_message,
            });
          } else {
            yield put({
              type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
              payload: 'Error on creating transaction.',
            });
          }
        }
      } else {
        if (res.message && !res.success) {
          yield put({
            type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
            payload: res.message,
          });
        } else {
          yield put({
            type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
            payload: 'Error on creating transaction.',
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        yield put({
          type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
          payload: error.response.data.message,
        });
      } else {
        yield put({
          type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
          payload: 'Error on creating transaction.',
        });
      }
    }
  });
}

export function* loadPchannels() {
  yield takeEvery('LOAD_MERCHANT_PCHANNEL', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      const apiResult = yield fetchPchannels(merchantId);
      const apiResultTopupChannel = yield fetchPchannelsTopup(merchantId);
      const result = apiResult.data;
      const resultTopup = apiResultTopupChannel.data;
      yield put({
        type: actions.LOADING_MERCHANT_PCHANNEL,
      });
      yield put({
        type: actions.MERCHANT_PCHANNEL_LOADED,
        merchantPchannel: result.data,
        merchantPchannelTopup: resultTopup.data,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_MERCHANT_PCHANNEL_FAILED,
      });
    }
  });
}
export function* sendEmailLimitWarning() {
  yield takeEvery('SEND_EMAIL_LIMIT_WARNING', function* (payload) {
    // const state = yield select();
    try {
      yield put({
        type: actions.SENDING_EMAIL_LIMIT_WARNING,
      });
      const apiResult = yield sendingEmailLimitWarning(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.SENT_EMAIL_LIMIT_WARNING,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.SEND_EMAIL_LIMIT_WARNING_FAILED,
      });
    }
  });
}
export function* fetchMerchantUsers() { // 
  yield takeEvery('FETCH_MERCHANT_USERS', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    const savedSearchMerchant = state.Merchant.savedSearchMerchant;
    payload.payload.search = savedSearchMerchant;
    const selectedMerchant = state.Auth.selectedMerchant;
    const store = state.Auth.stores;
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      yield put({
        type: actions.LOADING_MORE_MERCHANT_USERS,
      });
    } else {
      yield put({
        type: actions.FETCHING_MERCHANT_USERS,
      });
    }
    try {
      const apiResult = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload, selectedMerchant);
      const result = apiResult.data;
      const currentList = payload.payload.currentUserList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      yield put({
        type: actions.MERCHANT_USERS_FETCHED,
        merchantUsersData: finalData,
        pagination: result.data.pagination
      });
      if (!loadmore) {
        yield put({
          type: actions.SET_MERCHANT_USER_DATA,
          payload: result.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_USERS_FAILED,
      });
    }
  });
}
export function* updateMerchantUser() {
  yield takeEvery('UPDATE_MERCHANT_USER', function* (payload) {
    const state = yield select();
    const store = state.Auth.stores;
    const merchantIdentity = state.Auth.identity;
    const merchantStores = state.Auth.stores;
    const selectedMerchant = state.Auth.selectedMerchant;

    let apiResultAllUsers;
    try {
      const apiResult = yield updateMerchantUserRequest(payload.payload, merchantIdentity);
      if (merchantIdentity.merchantSettings.isBusCompany) {
        apiResultAllUsers = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload, selectedMerchant);
      }
      const result = apiResult.data;
      yield put({
        type: actions.UPDATING_MERCHANT_USER,
      });
      if (result.success === true) {
        // Get and delete walletlink from result.data
        const walletLink = {...result.data.walletLink};
        delete result.data.walletLink
        yield put({
          type: actions.MERCHANT_USER_UPDATED,
          merchantUserUpdatedData: result.data,
          stores: merchantStores,
          walletLink,
        });
        yield put({
          type: actions.UPDATE_MERCHANT_USER_SUCCESS,
        });
        if (merchantIdentity.merchantSettings.isBusCompany && apiResultAllUsers) {
          const apiResultAllUsers = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload, selectedMerchant);
          const resultUsers = apiResultAllUsers.data;
          yield put({
            type: actions.MERCHANT_USERS_FETCHED,
            merchantUsersData: resultUsers.data.result,
            pagination: resultUsers.data.pagination
          });
          yield put({
            type: actions.SET_MERCHANT_USER_DATA,
            payload: resultUsers.data.result[0],
          });
        }
      } else {
        yield put({
          type: actions.UPDATE_MERCHANT_USER_FAILED,
          updateError: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_MERCHANT_USER_FAILED,
      });
    }
  });
}
export function* addMerchantUser() {
  yield takeEvery('ADD_MERCHANT_USER', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_MERCHANT_USER,
      });

      const state = yield select();
      const merchantIdentity = state.Auth.identity;

      const apiResult = yield addMerchantUserRequest(payload.payload, merchantIdentity);
      const result = apiResult.data;
      
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_USER_ADDED,
        });
      } else {
        yield put({
          type: actions.ADD_MERCHANT_USER_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_MERCHANT_USER_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchAllFilteredRole() {
  yield takeEvery('FETCH_ALL_FILTERED_ROLE', function* () {
    try {
      const apiResult = yield fetchAllFilteredRoleRequest();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_ALL_FILTERED_ROLE,
      });
      if (result.success === true) {
        yield put({
          type: actions.FILTERED_ROLE_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_FILTERED_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchAuthManagementRole() { //delete
  yield takeEvery('FETCH_AUTH_MANAGEMENT_ROLE', function* () {
    try {
      const apiResult = yield fetchAuthManagementRoleRequest();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
      });
      if (result.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_ROLE_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_AUTH_MANAGEMENT_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
export function* deactivateMerchantUser() {
  yield takeEvery('DEACTIVATE_MERCHANT_USER', function* (payload) {
    try {
      yield put({
        type: actions.DEACTIVATING_MERCHANT_USER,
      });

      const state = yield select();
      const merchantIdentity = state.Auth.identity;
      const store = state.Auth.stores;
      const selectedMerchant = state.Auth.selectedMerchant;

      const apiResult = yield deactivateMerchantUserRequest(payload.payload.values, merchantIdentity);
      const result = apiResult.data;
      
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_USER_DEACTIVATED,
        });
        yield put({
          type: actions.FETCHING_MERCHANT_USERS,
        });
        const apiResultAllUsers = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload, selectedMerchant);
        const resultUsers = apiResultAllUsers.data;
        yield put({
          type: actions.MERCHANT_USERS_FETCHED,
          merchantUsersData: resultUsers.data.result,
          pagination: resultUsers.data.pagination
        });
        yield put({
          type: actions.SET_MERCHANT_USER_DATA,
          payload: resultUsers.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.DEACTIVATE_MERCHANT_USER_FAILED,
      });
    }
  });
}
// USER PORTAL
export function* resetMerchantUserPassword() {
  yield takeEvery('RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      yield put({
        type: actions.RESETTING_MERCHANT_USER_PASSWORD,
      });
      const apiResult = yield resetMerchantUserPasswordRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS,
        });
      } else {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

export function* getAllPchannels() {
  yield takeEvery('GET_ALL_PCHANNELS', function* () {
    try {
      yield put({
        type: actions.GETTING_ALL_PCHANNELS,
      });
      const apiResult = yield fetchAllPchannels();
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.GET_ALL_PCHANNELS_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.GET_ALL_PCHANNELS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_PCHANNELS_FAILED,
      });
    }
  });
}
export function* getAllPchannelsValues() {
  yield takeEvery('GET_PCHANNELS_VALUES', function* (payload) {
    try {
      yield put({
        type: actions.GETTING_PCHANNELS_VALUES,
      });
      const apiResult = yield fetchAllPchannelsValues(payload);
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.GETTING_PCHANNELS_VALUES_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.GETTING_PCHANNELS_VALUES_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_PCHANNELS_VALUES_FAILED,
      });
    }
  });
}
export function* getAllPchannelsValuesWithdraw() {
  yield takeEvery('GET_PCHANNELS_VALUES_WITHDRAW', function* (payload) {
    try {
      yield put({
        type: actions.GETTING_PCHANNELS_VALUES_WITHDRAW,
      });
      const apiResult = yield fetchAllPchannelsValuesWithdraw(payload);
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.GETTING_PCHANNELS_VALUES_WITHDRAW_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.GETTING_PCHANNELS_VALUES_WITHDRAW_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_PCHANNELS_VALUES_WITHDRAW_FAILED,
      });
    }
  });
}
export function* editMerchantDetails() {
  yield takeEvery('EDIT_MERCHANT_DETAILS', function* (payload) {
    try {
      yield put({
        type: actions.EDITING_MERCHANT_DETAILS,
      });
      const apiResult = yield updateMerchantDetails(payload);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_DETAILS_EDITED,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.EDIT_MERCHANT_DETAILS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_MERCHANT_DETAILS_FAILED,
      });
    }
  });
}
export function* getAllPchannelsForFee() {
  yield takeEvery('GET_PCHANNELS_FOR_FEE', function* () {
    try {
      yield put({
        type: actions.GETTING_PCHANNELS_FOR_FEE,
      });
      const apiResult = yield fetchAllPchannelsForFee();
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.GETTING_PCHANNELS_FOR_FEE_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.GETTING_PCHANNELS_FOR_FEE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_PCHANNELS_FOR_FEE_FAILED,
      });
    }
  });
}
export function* downloadHistory() {
  yield takeEvery('DOWNLOAD_HISTORY', function* (payload) {
    try {
      yield put({
        type: actions.DOWNLOADING_HISTORY,
      });
      const apiResult = yield generateData(payload.payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        if (result.count <= 0) {
          yield put({
            type: actions.DOWNLOAD_HISTORY_FAILED,
            message: 'No transaction found.'
          });
        }
      } else {
        yield put({
          type: actions.DOWNLOAD_HISTORY_FAILED,
          message: 'Failed to download history.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_HISTORY_FAILED,
        message: 'Failed to download history.'
      });
    }
  });
}

export function* downloadStoreTxn() {
  yield takeEvery('DOWNLOAD_STORE_TXN', function* (payload) {
    try {
      yield put({
        type: actions.DOWNLOADING_STORE_TXN,
        result: payload.payload.storeId,
      });
      const apiResult = yield generateStoreData(payload.payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        if (result.count <= 0) {
          yield put({
            type: actions.DOWNLOAD_STORE_TXN_FAILED,
            message: 'No transaction found.'
          });
        }
      } else {
        yield put({
          type: actions.DOWNLOAD_STORE_TXN_FAILED,
          message: 'Failed to download transactions.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_STORE_TXN_FAILED,
        message: 'Failed to download transactions.'
      });
    }
  });
}
export function* fetchRiskProfile() {
  yield takeEvery('FETCH_RISK_PROFILE', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const selectedMerchant = state.Auth.selectedMerchant;
    try {
      yield put({
        type: actions.FETCHING_RISK_PROFILE,
      });
      let apiResult;
      if (role !== 'Administrator' && role !== 'Corporate View' && role !== 'Finance' && role !== 'Sales') {
        apiResult = yield fetchRiskProfileRequest();
      } else {
        if (selectedMerchant !== 'All Merchant') {
          apiResult = yield fetchRiskProfileRequestAdmin(selectedMerchant);
        } 
      }
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHING_RISK_PROFILE_SUCCESS,
          riskProfileData: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_RISK_PROFILE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_RISK_PROFILE_FAILED,
      });
    }
  });
}

export function* getKycQualified() {
  yield takeEvery('GET_KYC_QUALIFIED', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.GETTING_KYC_QUALIFIED,
      });
      const apiResult = yield fetchKycQualified(merchantId);
      if (apiResult.status === 200) {
        const kycRequirements = yield fetchKycRequirements();
        const result = apiResult.data;
        yield put({
          type: actions.GOT_KYC_QUALIFIED,
          data: result.data ? result.data : null,
          requirements: kycRequirements.status === 200 ? kycRequirements.data.data : [],
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_KYC_QUALIFIED_FAILED,
      });
    }
  });
}

export function* submitKycDocs() {
  yield takeEvery('POST_KYC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_KYC_DOCS,
      });
      if (payload.payload.update) {
        yield updateKycDocs(payload.payload);
        yield put({
          type: actions.UPDATED_KYC_DOCS,
        });
      } else {
        yield postKycDocs(payload.payload);
      }
      // const apiResult = yield postKycDocs(payload.payload);
      // if (apiResult.data.success) {
      //   const kyc = yield fetchKycQualified(payload.payload.merchantId);
      //   if (kyc.status === 200) {
      //     yield put({
      //       type: actions.POSTED_KYC_DOCS,
      //       data: kyc.data.data,
      //     });
      //   }
      // } else {
      //   yield put({
      //     type: actions.POSTING_KYC_DOCS_FAILED,
      //   });
      // }
    } catch (error) {
      yield put({
        type: actions.POSTING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* reloadKyc() {
  yield takeEvery('RELOAD_KYC', function* () {

    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      const kyc = yield fetchKycQualified(merchantId);
      if (kyc.status === 200) {
        yield put({
          type: actions.POSTED_KYC_DOCS,
          data: kyc.data.data,
        });
      } else {
        yield put({
          type: actions.POSTING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* previewKycDocs() {
  yield takeEvery('PREVIEW_KYC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.PREVIEWING_KYC_DOCS,
      });
      const apiResult = yield getMerchantDocs(payload.payload);
      if (apiResult.data.success) {
        const result = apiResult.data;
        yield put({
          type: actions.PREVIEWED_KYC_DOCS,
          data: result ? result.base64 : null,
        });
      } else {
        yield put({
          type: actions.PREVIEWING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.PREVIEWING_KYC_DOCS_FAILED,
      });
    }
  });
}
export function* fetchMerchantReferralLink() {
  yield takeEvery('FETCH_MERCHANT_REFERRAL_LINK', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_REFERRAL_LINK,
      });
      const apiResult = yield fetchMerchantReferralLinkRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_MERCHANT_REFERRAL_LINK_SUCCESS,
          referralLinkdata: result.data,
        });
      }
      /**
       * @param  {catch} error
       * TODO add a handler for catch
       */
    } catch (error) {
    }
  });
}

export function* uploadLogo() {
  yield takeEvery('UPLOAD_MERCHANT_LOGO', function* (payload) {
    try {
      yield put({
        type: actions.UPLOADING_MERCHANT_LOGO,
      });
      const apiResult = yield uploadMerchLogo(payload.payload[0]);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.UPLOADED_MERCHANT_LOGO,
          data: result.data,
          // message: 'Logo uploaded successfully.'
        });
      } else {
        yield put({
          type: actions.UPLOAD_MERCHANT_LOGO_FAILED,
          message: 'Logo upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPLOAD_MERCHANT_LOGO_FAILED,
        message: 'Logo upload failed.'
      });
    }
  });
}

export function* askForReupload() {
  yield takeEvery('ASK_FOR_REUPLOAD', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.ASKING_FOR_REUPLOAD,
      });
      const apiResult = yield requestReupload(merchantId, payload.payload);
      if (apiResult.status === 200) {
        const kyc = yield fetchKycQualified(merchantId);
        if (kyc.status === 200) {
          yield put({
            type: actions.ASKED_FOR_REUPLOAD,
            data: kyc.data.data,
          });
        } else {
          yield put({
            type: actions.ASKING_FOR_REUPLOAD_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.ASKING_FOR_REUPLOAD_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ASKING_FOR_REUPLOAD_FAILED,
      });
    }
  });
}

export function* approveKycDocs() {
  yield takeEvery('APPROVE_KYC_DOCS', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.APPROVING_KYC_DOCS,
      });
      const apiResult = yield requestApprove(merchantId);
      if (apiResult.data.success) {
        const kyc = yield fetchKycQualified(merchantId);
        if (kyc.status === 200) {
          yield put({
            type: actions.APPROVED_KYC_DOCS,
            data: kyc.data.data,
          });
        } else {
          yield put({
            type: actions.APPROVING_KYC_DOCS_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.APPROVING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.APPROVING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* updateMerchantChannels() {
  yield takeEvery('UPDATE_MERCHANT_CHANNELS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_MERCHANT_CHANNELS,
      });
      const apiResult = yield editMerchantChannels(payload);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_CHANNELS_UPDATED,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_MERCHANT_CHANNELS_FAILED,
          message: 'Logo upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_MERCHANT_CHANNELS_FAILED,
        message: 'Logo upload failed.'
      });
    }
  });
}

export function* newResetMerchantUserPassword() {
  yield takeEvery('NEW_RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      yield put({
        type: actions.NEW_RESETTING_MERCHANT_USER_PASSWORD,
      });
      const apiResult = yield newResetMerchantUserPasswordRequest(payload.payload.newData, payload.payload.id);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

export function* resetTokenData() {
  yield takeEvery('RESET_TOKEN_DATA', function* (payload) {
    try {
      const apiResult = yield resettingTokenData(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.RESETTING_TOKEN_DATA,
      });
      if (result.success) {
        yield put({
          type: actions.RESET_TOKEN_DATA_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.RESET_TOKEN_DATA_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_TOKEN_DATA_FAILED,
      });
    }
  });
}

export function* fetchMerchantSettings() {
  yield takeEvery('FETCH_MERCHANT_SETTINGS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_SETTINGS,
      });
      const apiResult = yield getMerchantSettings(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_MERCHANT_SETTINGS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_MERCHANT_SETTINGS_FAILED,
          message: 'Failed to fetch merchant settings.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_MERCHANT_SETTINGS_FAILED,
        message: 'Failed to fetch merchant settings.'
      });
    }
  });
}
export function* sendOtpMerchantSettings() {
  yield takeEvery('SEND_OTP', function* (payload) {
    try {
      yield put({
        type: actions.SENDING_OTP,
      });
      const apiResult = yield sendOtpMerchantSettingsRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SEND_OTP_SUCCESS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.SEND_OTP_FAILED,
          message: 'Failed to process your OTP request'
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEND_OTP_ERROR,
        message: 'Failed to process your OTP request'
      });
    }
  });
}
export function* updateMerchantSettings() {
  yield takeEvery('UPDATE_MERCHANT_SETTINGS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_MERCHANT_SETTINGS,
      });
      const apiResult = yield updateMerchantSettingsRequest(payload.payload.fkMerchantId, payload.payload);
      const result = apiResult.data;
      if (result.success) {
        const merchantInfo = yield fetchMerchantById(payload.payload.Id);
        yield put({
          type: actions.UPDATED_MERCHANT_SETTINGS,
          data: merchantInfo.data.data,
        });
      } else {
        yield put({
          type: actions.UPDATING_MERCHANT_SETTINGS_FAILED,
          message: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATING_MERCHANT_SETTINGS_ERROR,
        message: 'Failed to process request.'
      });
    }
  });
}
//
export function* updateCourierSetting() {
  yield takeEvery('UPDATE_COURIER_SETTING', function* ({ payload }) {
    const state = yield select();
    try {
      yield put({
        type: actions.UPDATING_COURIER_SETTING
      });
      const mid = payload.merchant_id;
      const newBody = {
        Id: payload.id,
        enable_courier: payload.enable_courier
      }
      let apiResult = yield updateCoutierSettingRequest(mid, newBody);

      const result = apiResult.data;
      if (result.success) {

        yield put({
          type: actions.FETCHING_MERCHANT_SETTINGS,
        });

        const selectedMerchantSettings = state.Merchant.selectedMerchantSettings;
        selectedMerchantSettings.enableCourier = !selectedMerchantSettings.enableCourier;
        yield put({
          type: actions.FETCHED_MERCHANT_SETTINGS,
          data: selectedMerchantSettings,
        });

        yield put({
          type: actions.UPDATED_COURIER_SETTING,
          message: 'Courier setting successfully updated.',
        });
      } else {
        yield put({
          type: actions.SAVE_INVENTORY_DETAILS_FAILED,
          message: result.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_INVENTORY_DETAILS_FAILED,
        message: 'Error saving inventory details, please try again'
      });
    }
  });
}
//
export function* fetchWebstoreData() {
  yield takeEvery('FETCH_WEBSTORE_DATA', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    try {
      yield put({
        type: actions.FETCHING_WEBSTORE_DATA,
      });
      const apiResult = yield fetchWebstoreDataRequest(merchantId);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_WEBSTORE_DATA_SUCCESS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_WEBSTORE_DATA_FAILED,
          message: 'Failed to fetch merchant settings.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_WEBSTORE_DATA_ERROR,
        message: 'Failed to fetch merchant settings.'
      });
    }
  });
}
export function* saveWebstoreData() {
  yield takeEvery('SAVE_WEBSTORE_DATA', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.merchantId = merchantId;
    try {
      yield put({
        type: actions.SAVING_WEBSTORE_DATA,
      });
      const apiResult = yield saveWebstoreDataRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SAVE_WEBSTORE_DATA_SUCCESS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.SAVE_WEBSTORE_DATA_FAILED,
          message: 'Failed to fetch merchant settings.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_WEBSTORE_DATA_ERROR,
        message: 'Failed to fetch merchant settings.'
      });
    }
  });
}
export function* updateWebstoreData() {
  yield takeEvery('UPDATE_WEBSTORE_DATA', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const data = payload.payload;
    const isCatalog = data.updateType;
    //delete data.updateType;
    data.merchantId = merchantId;
    try {
      yield put({
        type: isCatalog === 'catalog' ? actions.UPDATING_WEBSTORE_CATALOG : actions.UPDATING_WEBSTORE_DATA,
      });
      const apiResult = yield updateWebstoreDataRequest(data, data.Id);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: isCatalog === 'catalog' ? actions.UPDATE_WEBSTORE_CATALOG_SUCCESS : actions.UPDATE_WEBSTORE_DATA_SUCCESS,
          data: result.data,
        });

        // NETCORE START
        netcore({
          type: 'activity',
          email: state.Auth.identity.email,
          activityName: 'webstore_created',
          activityData: {
            webstore_id: result.data.webStoreId,
            webstore_link: result.data.webStoreLink,
          }
        });
        // NETCORE END
      } else {
        yield put({
          type: isCatalog === 'catalog' ? actions.UPDATE_WEBSTORE_CATALOG_FAILED : actions.UPDATE_WEBSTORE_DATA_FAILED,
          message: result.message
        });
      }
    } catch (error) {
      yield put({
        type: isCatalog === 'catalog' ? actions.UPDATE_WEBSTORE_CATALOG_ERROR : actions.UPDATE_WEBSTORE_DATA_ERROR,
        message: 'Error on fetching webstore update response.'
      });
    }
  });
}
export function* uploadWebstoreBanner() {
  yield takeEvery('UPLOAD_WEBSTORE_BANNER', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const data = payload.payload;
    data.merchantId = merchantId;
    try {
      yield put({
        type: actions.UPLOADING_WEBSTORE_BANNER,
      });
      const apiResult = yield uploadWebstoreBannerRequest(data);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPLOAD_WEBSTORE_BANNER_SUCCESS,
          data: result,
        });
      } else {
        yield put({
          type: actions.UPLOAD_WEBSTORE_BANNER_FAILED,
          message: result.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPLOAD_WEBSTORE_BANNER_ERROR,
        message: 'Error on fetching webstore update response.'
      });
    }
  });
}

export function* fetchProvince() {
  yield takeEvery('FETCH_PROVINCE', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    try {
      yield put({
        type: actions.FETCH_PROVINCE_RESET
      });
      yield put({
        type: actions.FETCHING_PROVINCE
      });
      const apiResult = yield fetchProvinceRequest(merchantId);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_PROVINCE_SUCCESS,
          provinces: result.data.length > 0 ? result.data : [],
        });
      } else {
        yield put({
          type: actions.FETCH_PROVINCE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PROVINCE_ERROR,
      });
    }
  });
}

export function* updateShippingSetup() {
  yield takeEvery('UPDATE_SHIPPING_SETUP', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const data = payload.payload;
    data.merchantId = merchantId;
    try {
      yield put({
        type: actions.UPDATING_SHIPPING_SETUP,
      });
      const apiResult = yield updateShippingSetupRequest(data);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATE_SHIPPING_SETUP_SUCCESS,
          provinces: result.data.length > 0 ? result.data : [],
        });
      } else {
        yield put({
          type: actions.UPDATE_SHIPPING_SETUP_FAILED,
          message: result.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_SHIPPING_SETUP_ERROR,
        message: 'Error on fetching webstore update response.'
      });
    }
  });
}
export function* savePchannelsFee() {
  yield takeEvery('SAVE_PCHANNEL_FEE', function* (payload) {
    try {
      yield put({
        type: actions.SAVING_PCHANNEL_FEE,
      });
      const apiResult = yield savePchannelsFeeData(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SAVE_PCHANNEL_FEE_SUCCESS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.SAVE_PCHANNEL_FEE_FAILED,
          message: 'Failed to Save Fee.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_PCHANNEL_FEE_FAILED,
        message: 'Failed to Save Fee'
      });
    }
  });
}
export function* updatePchannelsFee() {
  yield takeEvery('UPDATE_PCHANNELS_FEE', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_PCHANNELS_FEE,
      });
      const apiResult = yield updatePchannelsFeeData(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATING_PCHANNELS_FEE_SUCCESS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATING_PCHANNELS_FEE_FAILED,
          message: 'Failed to Save Fee.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATING_PCHANNELS_FEE_FAILED,
        message: 'Failed to Save Fee'
      });
    }
  });
}
export function* validateActivationCode() {
  yield takeEvery('VALIDATE_ACTIVATION_CODE', function* (payload) {
    try {
      yield put({
        type: actions.VALIDATING_ACTIVATION_CODE,
      });
      const apiResult = yield validateActivationCodeReq(payload.payload);
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.VALIDATE_ACTIVATION_CODE_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.VALIDATE_ACTIVATION_CODE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.VALIDATE_ACTIVATION_CODE_FAILED,
      });
    }
  });
}
export function* fetchCcRegistration() {
  yield takeEvery('FETCH_CC_REGISTRATION_STATUS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_CC_REGISTRATION_STATUS,
      });
      const apiResult = yield fetchCcRegistrationReq(payload.payload);
      const result = apiResult.data;

      if (result.success) {
        yield put({

          type: actions.FETCHING_CC_REGISTRATION_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_CC_RESGISTRATION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_CC_RESGISTRATION_FAILED,
      });
    }
  });
}
export function* fetchSubmerchants() {
  yield takeEvery('FETCH_SUBMERCHANTS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_SUBMERCHANTS,
      });
      const apiResult = yield fetchingSubMerchants(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({

          type: actions.FETCHED_SUBMERCHANTS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_SUBMERCHANTS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SUBMERCHANTS_FAILED,
      });
    }
  });
}
export function* fetchMerchantStoreData() {
  yield takeEvery('FETCH_MERCHANT_STORE', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_STORE,
      });
      const merchantStore = yield merchantStoreData(payload.payload);
      if (merchantStore.data.success) {
        yield put({
          type: actions.FETCHED_MERCHANT_STORE,
          payload: merchantStore.data,
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_STORE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_STORE_FAILED,
      });
    }
  });
}
export function* fetchMerchantStoreInventoryData() {
  yield takeEvery('FETCH_MERCHANT_STORE_INVENTORY', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_STORE_INVENTORY,
      });
      const merchantStoreInventory = yield merchantStoreInventoryData(payload.payload);
      if (merchantStoreInventory.data.success) {
        yield put({
          type: actions.FETCHED_MERCHANT_STORE_INVENTORY,
          payload: merchantStoreInventory.data.data,
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_STORE_INVENTORY_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_STORE_INVENTORY_FAILED,
      });
    }
  });
}
export function* getCcDocs() {
  yield takeEvery('GET_CC_DOCS', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.GETTING_CC_DOCS,
      });
      const apiResult = yield fetchCcDocs(merchantId);
      if (apiResult.status === 200) {
        const ccRequirements = yield fetchCcRequirements();
        const result = apiResult.data;
        yield put({
          type: actions.GOT_CC_DOCS,
          data: result.data ? result.data : null,
          ccRequirements: ccRequirements.status === 200 ? ccRequirements.data.data : []
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_CC_DOCS_FAILED,
      });
    }
  });
}
export function* submitCcDocs() {
  yield takeEvery('POST_CC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_CC_DOCS,
      });
      if (payload.payload.update) {
        yield updateCcDocs(payload.payload);
        yield put({
          type: actions.UPDATED_CC_DOCS,
        });
      } else {
        yield postCcDocs(payload.payload);
        yield put({
          type: actions.MQTT_CC_POSTING_RESULT,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_CC_DOCS_FAILED,
      });
    }
  });
}
export function* previewCcDocs() {
  yield takeEvery('PREVIEW_CC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.PREVIEWING_CC_DOCS,
      });
      const apiResult = yield getCcMerchantDocs(payload.payload);
      if (apiResult.data.success) {
        const result = apiResult.data;
        yield put({
          type: actions.PREVIEWED_CC_DOCS,
          data: result ? result.base64 : null,
        });
      } else {
        yield put({
          type: actions.PREVIEWING_CC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.PREVIEWING_CC_DOCS_FAILED,
      });
    }
  });
}
export function* askForCcReupload() {
  yield takeEvery('ASK_FOR_CC_REUPLOAD', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.ASKING_FOR_CC_REUPLOAD,
      });
      const apiResult = yield requestCcReupload(merchantId, payload.payload);
      if (apiResult.status === 200) {
        const cc = yield fetchCcDocs(merchantId);
        if (cc.status === 200) {
          yield put({
            type: actions.ASKED_FOR_CC_REUPLOAD,
            data: cc.data.data,
          });
        } else {
          yield put({
            type: actions.ASKING_FOR_CC_REUPLOAD_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.ASKING_FOR_CC_REUPLOAD_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ASKING_FOR_CC_REUPLOAD_FAILED,
      });
    }
  });
}
export function* approveCcDocs() {
  yield takeEvery('APPROVE_CC_DOCS', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.APPROVING_CC_DOCS,
      });
      const apiResult = yield requestCcApprove(merchantId);
      if (apiResult.data.success) {
        if(apiResult.data.data && apiResult.data.data.isExist) {
          yield put({
            type: actions.APPROVING_CC_DOCS_FAILED,
            message: apiResult.data.data.message,
          });
        } else {
          const cc = yield fetchCcDocs(merchantId);
          if (cc.status === 200) {
            yield put({
              type: actions.APPROVED_CC_DOCS,
              data: cc.data.data,
            });
          } else {
            yield put({
              type: actions.APPROVING_CC_DOCS_FAILED,
            });
          }
        }
      } else {
        yield put({
          type: actions.APPROVING_CC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.APPROVING_CC_DOCS_FAILED,
      });
    }
  });
}
export function* reloadCc() {
  yield takeEvery('RELOAD_CC', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      const cc = yield fetchCcDocs(merchantId);
      if (cc.status === 200) {
        yield put({
          type: actions.POSTED_CC_DOCS,
          data: cc.data.data,
        });
      } else {
        yield put({
          type: actions.POSTING_CC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_CC_DOCS_FAILED,
      });
    }
  });
}

export function* fetchMerchantWallet() {
  yield takeEvery('FETCH_MERCHANT_WALLET', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_WALLET,
      });
      const merchantWallet = yield merchantWalletData(payload.payload);
      if (merchantWallet.data.success) {
        yield put({
          type: actions.FETCHED_MERCHANT_WALLET,
          payload: merchantWallet.data,
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_WALLET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_WALLET_FAILED,
      });
    }
  });
}
export function* fetchFormBuilder() {
  yield takeEvery('FETCH_FORM_BUILDER', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_FORM_BUILDER,
      });
      const state = yield select();
      let merchantId = state.Auth.identity.merchantId;
      const accountRole = state.Auth.identity.role.role.label;
      if (accountRole === 'Administrator' || accountRole === 'Finance' || accountRole === 'Sales') {
        merchantId = state.Auth.selectedMerchant;
      }
      const data = {
        merchant_id: merchantId,
        type: payload.payload,
      };
      const apiResult = yield fetchingFormBuilder(data);
      const result = apiResult.data;
      if (result.success) {
        let formTemplate = {};
        result.template[0].fields.forEach(row => {
          if (row.hasDelete) {
            row.fields.forEach(column => {
                formTemplate[column.properties.paramName] = `<${column.properties.paramName}>`
            });
        }
        });
        yield put({
          type: actions.FETCHED_FORM_BUILDER,
          payload: formTemplate,
        });
      } else {
        yield put({
          type: actions.FETCH_FORM_BUILDER_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FORM_BUILDER_FAILED,
      });
    }
  });
}

export function* fetchServiceableQuery() {
  yield takeEvery('FETCH_SERVICEABLE_QUERY', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_SERVICEABLE_QUERY,
      });
      const apiResult = yield fetchServiceableQueryRequest(payload.payload);
      const result = apiResult.data;

      if (result.success) {
        yield put({
          type: actions.FETCHED_SERVICEABLE_QUERY,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_SERVICEABLE_QUERY_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SERVICEABLE_QUERY_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchRollingReserveSettings() {
  yield takeEvery('FETCH_ROLLING_RESERVE_SETTINGS', function* () {
    try {
      yield put({
        type: actions.FETCH_ROLLING_RESERVE_SETTINGS_LOADING,
      });

      const apiResult = yield fetchRollingReserveSettingsRequest();
      const result = apiResult.data;

      if (result.success && result.data !== false) {
        yield put({
          type: actions.FETCH_ROLLING_RESERVE_SETTINGS_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_ROLLING_RESERVE_SETTINGS_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ROLLING_RESERVE_SETTINGS_FAILED,
        payload: error,
      });
    }
  });
}

function fetchingFormBuilder(data) {
  return post(`formbuilders/merchant/fetch`, data);
}
function updatePchannelsFeeData(data) {
  return PUT(`merchantpchannelfees/${data.Id}`, data);
}
function fetchingSubMerchants(data) {
  return post(`merchants/fetch_all_merchants_by_mids`, data);
}
function fetchMerchantByMid(mid) {
  return get(`merchants/get_by_mid/${mid}`);
}
// function fetchMerchantByMidAny(mid) {
//   return get(`merchants/get_by_mid_any/${mid}`);
// }
function fetchMerchantById(id) {
  return get(`merchants/${id}`);
}
function updateMerchantDetails(data) {
  return PUT(`merchants/update/merchant_details/${data.payload.merchantData.merchantId}`, data.payload);
}
function editMerchantChannels(data) {
  return PUT(`merchantchannels/update_merchant_channels/${data.payload.merchantData.merchantId}`, data.payload.pchannels);
}
function fetchImageRequest(img) {
  return get(`merchants/get_merchant_image/${img}`);
}
function fetchImageRequestEncrypted(img) {
  return encryptedGet(`merchants/get_merchant_image_encrypted/${img}`);
}
// function fetchStoreChannels(id) {
//   return get(`storechannels/get_pchannel_redirect_payment/${id}`);
// }
function fetchStoreChannelsEncrypted(id) {
  return encryptedGet(`storechannels/get_pchannel_redirect_payment_encrypted/${id}`);
}
// function fetchStoreInformation(id) {
//   return get(`stores/get_store/${id}`);
// }
function fetchStoreInformationEncrypted(id) {
  return encryptedGet(`stores/get_store_encrypted/${id}`);
}
function fetchAllPchannels() {
  return get(`pchannels/get_all_available_pchannels`);
}
function fetchAllPchannelsValues(body) {
  const merchantId = {
    merchantId: body.payload,
  }
  return post(`merchantpchannelfees/fetch/pchannel_fees_values`, merchantId);
}
function fetchAllPchannelsValuesWithdraw(body) {
  const merchantId = {
    merchantId: body.payload,
  }
  return post(`merchantpchannelfees/fetch/pchannel_fees_values/withdraw`, merchantId);
}

function fetchMaintenancePChannelsRequest() {
  return get(`pchannels/get_pchannels_with_status/maintenance`)
}

function fetchMaintenancePChannelsRequestEncrypted() {
  return encryptedGet(`pchannels/get_pchannels_with_status_encrypted/maintenance`)
}

function fetchAllPchannelsForFee() {
  return get(`pchannels/get_all_available_pchannels_fee`);
}

function fetchPchannels(mid) {
  return get(`merchantchannels/get_pchannels_by_mid/${mid}`);
}
function fetchPchannelsTopup(mid) {
  return get(`merchantchannels/get_pchannels_by_mid_topup/${mid}`);
}

function fetchWithdrawalAccounts(mid) {
  return get(`merchantwithdrawalaccounts/fetch_by_mid/${mid}`);
}
// function checkVerified(id) {
//   return get(`merchants/check_verified/${id}`);
// }
function checkVerifiedEncrypted(id) {
  return encryptedGet(`merchants/check_verified_encrypted/${id}`);
}
function getCustomerData(id) {
  return get(`customersapptokens/get_data_by_id/${id}`);
}
// function fetchStoreImg(id) {
//   return get(`stores/get_file_name_by_store_id/${id}`);
// }
function fetchStoreImgEncrypted(id) {
  return encryptedGet(`stores/get_file_name_by_store_id_encrypted/${id}`);
}
function fetchMerchantUsersRequest(merchantIdentity, store, payload, selectedMerchant) {
  const newData = {
    mid: selectedMerchant && selectedMerchant !== 'All Merchant' ? selectedMerchant : payload && payload.mid ? payload.mid : merchantIdentity.merchantId,
    role: merchantIdentity.role.role.label,
    store: store.length > 0 ? store[0].storeId : '',
    search: payload.search ? payload.search : '',
    authId: merchantIdentity.Id,
  };
  return get(`users/get_all/new/`, newData, payload.page, null, null);
}
function updateMerchantUserRequest(data, merchantData) {
  data['lastUpdatedBy'] = merchantData.Id;
  return post(`authenticate/update_user/new`, data);
}
function addMerchantUserRequest(data, merchantData) {
  data['createdBy'] = merchantData.Id;
  return post(`authenticate/add_user/new`, data);
}
function fetchAllFilteredRoleRequest() {
  return get(`roles/fetch/user_management_roles`);
}
function fetchAuthManagementRoleRequest() {
  return get(`roles/fetch_all_roles/ui`);
}
function deactivateMerchantUserRequest(data, merchantData) {
  const newData = {
    userId: data.id,
    status: data.status,
    deletedBy: merchantData.Id,
  }
  return post(`authenticate/delete_user/`, newData);
}
function resetMerchantUserPasswordRequest(id) {
  return get(`authenticate/reset_password/${id}`);
}
function generateData(data) {
  return post(`merchants/generate_data`, data);
}
function generateStoreData(data) {
  return post(`payment_transactions/generate_data`, data);
}
function fetchRiskProfileRequest() {
  return get(`riskprofiles/fetch/risk_profile/`);
}
function fetchRiskProfileRequestAdmin(mid) {
  return get(`riskprofiles/fetch/risk_profile/${mid}`);
}
function fetchKycQualified(mid) {
  return get(`kycqualifieds/fetch/by_mid/${mid}`);
}
function postKycDocs(data) {
  return post(`kycapplications/upload_merchant_documents/`, data);
}
function updateKycDocs(data) {
  return PUT(`kycdocs/update/kyc_docs/${data.merchantId}`, data);
}
function fetchKycRequirements() {
  return get(`kycrequirements/fetch/kyc_docs/`);
}
function getMerchantDocs(id) {
  return get(`kycapplications/get_merchant_document/${id}`);
}
function getMerchantSettings(id) {
  return get(`merchantsettings/fetch_by_mid/${id}`);
}
function sendOtpMerchantSettingsRequest(data) {
  if (!data.resend) {
    return post(`onetimepasswords/merchant_settings/otp_request/`, data);
  } else {
    return post(`onetimepasswords/merchant_settings/resend_otp/`, data);
  }
}
function updateMerchantSettingsRequest(id, data) {
  return PUT(`merchantsettings/update_by_mid/${id}`, data);
}
function updateCoutierSettingRequest(id, data) {
  return PUT(`merchantsettings/update/courier_setting/${id}`, data);
}
function fetchMerchantReferralLinkRequest(id) {
  return get(`merchantreferrallinks/fetch_by_referral_code/${id}`);
}
function uploadMerchLogo(data) {
  return post(`merchants/upload_merchant_logo/`, data);
}
function requestReupload(mid, data) {
  return PUT(`kycdocs/request/reupload/kyc_docs/${mid}`, data);
}
function requestApprove(mid) {
  const data = {
    merchantId: mid,
  }
  return post(`kycapplications/approve_merchant_documents/`, data);
}
function payTransactionRequest(data) {
  return post(`payment_transactions/store/`, data);
}
function newResetMerchantUserPasswordRequest(data, token) {
  return PUT(`authenticate/new_reset_password/${token}`, data);
}
function resettingTokenData(id) {
  return get(`resettokens/get_data_by_token/${id}`);
}
// function getPaymentCount(mid) {
//   return get(`dashboard/get_payment_count/${mid}`);
// }
// function getLimit(mid) {
//   return get(`payment_transactions/get_aggregated_data/${mid}`);
// }
function getLimitEncrypted(mid) {
  return encryptedGet(`payment_transactions/get_aggregated_data_encrypted/${mid}`);
}
function sendingEmailLimitWarning(data) {
  return post(`payment_transactions/send_email_limit_warning`, data);
}
function fetchWebstoreDataRequest(mid) {
  return get(`webstores/get/webstore_data/${mid}`);
}
function saveWebstoreDataRequest(data) {
  return post(`webstores/save/webstore_data/`, data);
}
function savePchannelsFeeData(data) {
  return post(`merchantpchannelfees/`, data);
}
function updateWebstoreDataRequest(data, id) {
  if (data.updateType === 'SocMed') {
    delete data.updateType;
    return PUT(`webstores/update/webstore_data/soc_med/${id}`, data);
  } else {
    delete data.updateType;
    return PUT(`webstores/update/webstore_data/${id}`, data);
  }

}
function uploadWebstoreBannerRequest(data) {
  return post(`webstores/upload/webstore_banner/`, data);
}
function fetchProvinceRequest(mid) {
  return get(`provinces/fetch/all_province/${mid}`);
}
function updateShippingSetupRequest(data) {
  return post(`shippingsetups/save/shipping_setup/`, data);
}
function validateActivationCodeReq(data) {
  return AuthorizedPut(`ccregistrations/${data.id}`, data);
}
function fetchCcRegistrationReq(id) {
  return AuthorizedGet(`ccregistrations/${id}`);
}
function merchantStoreData(id) {
  return get(`stores/fetch_courier_store/${id}`)
}
function merchantStoreInventoryData(id) {
  return get(`stores/fetch_courier_store/inventory/${id}`)
}
// function fetchStoreTemplate(data) {
//   return AuthorizedPost(`formbuilders/merchant/fetch`, data)
// }
function fetchStoreTemplateEncrypted(data) {
  return encryptedPost(`formbuilders/merchant/fetch_encrypted `, data)
}
function fetchCcDocs(mid) {
  return get(`ccapplications/fetch_by_mid/${mid}`);
}
function fetchCcRequirements() {
  return get(`ccrequirements/fetch/cc_docs`);
}
function postCcDocs(data) {
  return post(`ccapplications/upload_merchant_documents/`, data);
}
function getCcMerchantDocs(id) {
  return get(`ccapplications/get_cc_merchant_document/${id}`);
}
function requestCcReupload(mid, data) {
  return PUT(`ccdocs/request/reupload/cc_docs/${mid}`, data);
}
function requestCcApprove(mid) {
  const data = {
    merchantId: mid,
  }
  return post(`ccapplications/approve_merchant_documents/`, data);
}
function updateCcDocs(data) {
  return PUT(`ccdocs/update/cc_docs/${data.merchantId}`, data);
}
function merchantWalletData(mid) {
  return get(`walletmanagements/fetch_wallet_mid/${mid}`)
}
function fetchServiceableQueryRequest(data) {
  return AuthorizedPost(`courier_integ/serviceable/query/`, data);
}
function fetchRollingReserveSettingsRequest() {
  return AuthorizedGet(`systemsettings/fetch/rolling_reserve_settings`);
}
export default function* rootSaga() {
  yield all([
    fork(loadMerchantInfo),
    fork(loadPchannels),
    fork(fetchMerchantUsers),
    fork(updateMerchantUser),
    fork(addMerchantUser),
    fork(fetchAllFilteredRole),
    fork(fetchAuthManagementRole),
    // fork(fetchRolePermission),
    fork(fetchMaintenancePChannels),
    fork(deactivateMerchantUser),
    fork(resetMerchantUserPassword),
    fork(getAllPchannels),
    fork(editMerchantDetails),
    fork(downloadHistory),
    fork(downloadStoreTxn),
    fork(fetchRiskProfile),
    fork(getKycQualified),
    fork(submitKycDocs),
    fork(previewKycDocs),
    fork(reloadKyc),
    fork(fetchMerchantReferralLink),
    fork(askForReupload),
    fork(approveKycDocs),
    fork(uploadLogo),
    fork(updateMerchantChannels),
    fork(fetchMerchantImage),
    fork(paymentTransactionRequest),
    fork(fetchCustomerData),
    fork(resetTokenData),
    fork(newResetMerchantUserPassword),
    fork(fetchMerchantSettings),
    fork(sendOtpMerchantSettings),
    fork(updateMerchantSettings),
    // fork(mqttLoginValidator),
    fork(fetchWebstoreData),
    fork(saveWebstoreData),
    fork(updateWebstoreData),
    fork(uploadWebstoreBanner),
    fork(fetchProvince),
    fork(updateShippingSetup),
    fork(sendEmailLimitWarning),
    fork(getAllPchannelsValues),
    fork(getAllPchannelsValuesWithdraw),
    fork(savePchannelsFee),
    fork(updatePchannelsFee),
    fork(getAllPchannelsForFee),
    fork(validateActivationCode),
    fork(fetchCcRegistration),
    fork(fetchSubmerchants),
    fork(fetchMerchantStoreData),
    fork(fetchMerchantStoreInventoryData),
    fork(updateCourierSetting),
    fork(getCcDocs),
    fork(submitCcDocs),
    fork(previewCcDocs),
    fork(askForCcReupload),
    fork(approveCcDocs),
    fork(reloadCc),
    fork(fetchMerchantWallet),
    fork(fetchFormBuilder),
    fork(fetchServiceableQuery),
    fork(fetchRollingReserveSettings),
  ]);
}
