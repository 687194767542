import authActions from '../auth/actions';
import actions from './actions';

const initState = {
  paymentCount: null,
  isLoadingDashboard: false,
  hasError: false,
  isRaffleEntry: false,
  raffleEntry: null,
  isSuccessEntry: null,
  isStartRaffle: false,
  winnerRaffle: null,
  isGetWinner: false,
  winner: null,
  isErrorUserSettings: false,
  isGetUserSettings: false,
  userSettings: null,
  isUpdateVat: false,
  kycUrl: null,
  applyingCc: false,
  appliedCc: null,
  applyCcMessage: null,
  applyCcMessageError: null,
  gettingCc: false,
  gotCc: null,
  downloadedTncPdf: null,
  downloadingTncPdf: false,
  downloadFailedTncPdf: null,
  url: window.location.href,
  prevUrl: null,
  fetchMerchBirDocsSuccess: null,
  fetchMerchBirDocsLoading: true,
  fetchMerchBirDocsFailed: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOADED_DASHBOARD_DATA:
      return {
        ...state,
        paymentCount: action.payload,
      };
    case actions.LOADING_DASHBOARD_DATA:
      return {
        ...state,
        isLoadingDashboard: action.payload,
      };
    case actions.LOAD_DASHBOARD_ERROR:
      return {
        ...state,
        // hasError: action.payload,
        isLoadingDashboard: false,
      };
    case actions.RESET_DASHBOARD_DATA:
      return {
        ...state,
        // hasError: action.payload,
        isLoadingDashboard: false,
        paymentCount: null,
      };
    case actions.RAFFLE_ENTRY:
      return {
        ...state,
        raffleEntry: action.payload,
        isRaffleEntry: false,
        isSuccessEntry: action.success,
      };
    case actions.SENDING_RAFFLE_ENTRY:
      return {
        ...state,
        isRaffleEntry: action.payload,
      };
    case actions.SENDING_FAILED_RAFFLE_ENTRY:
      return {
        ...state,
        isRaffleEntry: false,
      };
    case actions.STARTING_RAFFLE:
      return {
        ...state,
        isStartRaffle: action.payload,
      };
    case actions.DONE_RAFFLE:
      return {
        ...state,
        winnerRaffle: action.payload,
        isStartRaffle: false,
      };
    case actions.GETTING_WINNERS:
      return {
        ...state,
        isGetWinner: action.payload,
      };
    case actions.GOT_WINNERS:
      return {
        ...state,
        winner: action.payload,
      };
    case actions.GOT_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
        isGetUserSettings: false,
      };
    case actions.GETTING_USER_SETTINGS:
      return {
        ...state,
        isGetUserSettings: action.payload,
      };
    case actions.UPDATING_VAT_AGGREE:
      return {
        ...state,
        isUpdateVat: action.payload,
      };
    case actions.ERROR_USER_SETTINGS:
      return {
        ...state,
        isErrorUserSettings: action.payload,
      };
    case actions.KYC_URL:
      return {
        ...state,
        kycUrl: action.payload,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;

    case actions.APPLYING_CREDIT_CARD:
      return {
        ...state,
        applyingCc: true,
        applyCcMessage: null,
        applyCcMessageError: null,
      };
    case actions.APPLIED_CREDIT_CARD:
      return {
        ...state,
        applyingCc: false,
        appliedCc: action.data,
        applyCcMessage: action.message,
      };
    case actions.APPLY_CREDIT_CARD_FAILED:
      return {
        ...state,
        applyingCc: false,
        appliedCc: null,
        applyCcMessageError: action.message,
      };
    case actions.RESET_APPLY_CREDIT_CARD:
        return {
          ...state,
          applyingCc: false,
          appliedCc: null,
          applyCcMessage: null,
          applyCcMessageError: null,
        };
    case actions.GETTING_CC_APPLICATION:
      return {
        ...state,
        gettingCc: true,
        applyCcMessage: null,
        applyCcMessageError: null,
      };
    case actions.GOT_CC_APPLICATION:
      return {
        ...state,
        gettingCc: false,
        gotCc: action.data,
        applyCcMessage: null,
        applyCcMessageError: null,
      };
    case actions.RESET_GET_CC_APPLICATION:
      return {
        ...state,
        gettingCc: false,
        gotCc: null,
      };
    case actions.DOWNLOAD_TNC_PDF_SUCCESS:
      return {
        ...state,
        downloadedTncPdf: action.payload,
        downloadingTncPdf: false,
        downloadFailedTncPdf: null,
      };
    case actions.DOWNLOAD_TNC_PDF_LOADING:
      return {
        ...state,
        downloadingTncPdf: true,
      };
    case actions.DOWNLOAD_TNC_PDF_FAILED:
      return {
        ...state,
        downloadedTncPdf: null,
        downloadingTncPdf: false,
        downloadFailedTncPdf: action.payload,
      };
    case actions.DOWNLOAD_TNC_PDF_RESET:
      return {
        ...state,
        downloadedTncPdf: null,
        downloadingTncPdf: false,
        downloadFailedTncPdf: null,
      };
    case actions.SET_URL_SUCCESS:
      return {
        ...state,
        url: action.payload
      };
    case actions.SET_PREV_URL_SUCCESS:
      return {
        ...state,
        prevUrl: action.payload
      };
    case actions.FETCH_MERCH_BIR_DOCS_SUCCESS:
      return {
        ...state,
        fetchMerchBirDocsSuccess: action.payload,
        fetchMerchBirDocsLoading: false,
        fetchMerchBirDocsFailed:  null,
      };
    case actions.FETCH_MERCH_BIR_DOCS_LOADING:
      return {
        ...state,
        fetchMerchBirDocsLoading: true,
      };
    case actions.FETCH_MERCH_BIR_DOCS_FAILED:
      return {
        ...state,
        fetchMerchBirDocsSuccess: null,
        fetchMerchBirDocsLoading: false,
        fetchMerchBirDocsFailed:  action.payload,
      };
    case actions.FETCH_MERCH_BIR_DOCS_RESET:
      return {
        ...state,
        fetchMerchBirDocsSuccess: null,
        fetchMerchBirDocsLoading: false,
        fetchMerchBirDocsFailed:  null,
      };
    default:
      return state;
  }
}
