import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { post } from '@iso/lib/helpers/resApiRequestor';

export function* encryptReqId() {
    yield takeEvery('ENCRYPT_REQ_ID', function* (payload) {
        try {
            yield put({
                type: actions.ENCRYPT_REQ_ID_LOADING,
            });
            // Encrypted ID
            const apiResult = yield encryptReqIdApi(payload.payload);
            const result = apiResult.data;
            if (result.success) {
                yield put({
                    type: actions.ENCRYPT_REQ_ID_SUCCESS,
                    payload: {
                        "x-api-key-1": result.data.request_id,
                        "x-api-key-2": payload.payload.request_id
                    }
                })
            } else {
                yield put({
                    type: actions.ENCRYPT_REQ_ID_FAILED,
                    payload: result
                })
            }
        } catch (error) {
            yield put({
                type: actions.ENCRYPT_REQ_ID_FAILED,
                payload: error
            })
        }
    })
}

function encryptReqIdApi(payload) {
    return post(`encryptdecrypts/encrypt`, payload);
}

export default function* rootSaga() {
    yield all([
        fork(encryptReqId),
    ]);
}