const actions = {
  CONNECT: 'CONNECT',
  CONNECTING: 'CONNECTING',
  CONNECT_SUCCESS: 'CONNECT_SUCCESS',
  CONNECT_FAILED: 'CONNECT_FAILED',
  // USE THIS FOR EXAMPLE FOR DOWNLOADING REPORT OF MERCHANT
  CONNECT_MQTT: 'CONNECT_MQTT',
  // FOR LIVE UPDATES
  CONNECT_MQTT_KEEP_LIVE: 'CONNECT_MQTT_KEEP_LIVE',
  connectMqtt: () => ({ type: actions.CONNECT }),
  connectMqttForDl: (payload) => ({
    type: actions.CONNECT_MQTT,
    payload,
  }),
  connectMqttKeepLive: (payload) => ({
    type: actions.CONNECT_MQTT_KEEP_LIVE,
    payload,
  }),
  connectMqttForUpload: (payload) => ({
    type: actions.CONNECT_MQTT,
    payload,
  }),
  connectMqttForRedirect: (payload) => ({
    type: actions.CONNECT_MQTT,
    payload,
  }),
  connectMqttTranxDetails: (payload) => ({
    type: actions.CONNECT_MQTT,
    payload,
  }),
};
export default actions;
