const actions = {
  // DOWNLOAD QR
  DOWNLOAD_STORE_QR: 'DOWNLOAD_STORE_QR',
  DOWNLOADING_STORE_QR: 'DOWNLOADING_STORE_QR',
  DOWNLOADED_STORE_QR: 'DOWNLOADED_STORE_QR',
  DOWNLOAD_STORE_QR_FAILED: 'DOWNLOAD_STORE_QR_FAILED',
  RESET_DOWNLOADED_STORE_QR: 'RESET_DOWNLOADED_STORE_QR',
  // SAVE STORE
  SAVE_STORE: 'SAVE_STORE',
  SAVING_STORE: 'SAVING_STORE',
  SAVED_STORE_MESSAGE: 'SAVED_STORE_MESSAGE',
  SAVE_STORE_FAILED: 'SAVED_STORE_FAILED',
  DIALOG_ADD_ACTION: 'DIALOG_ADD_ACTION',
  // GET PCHANNEL BY STORE ID
  FETCH_STORE_PCHANNEL: 'FETCH_STORE_PCHANNEL',
  FETCHING_STORE_PCHANNEL: 'FETCHING_STORE_PCHANNEL',
  FETCHED_STORE_PCHANNEL: 'FETCHED_STORE_PCHANNEL',
  FETCH_STORE_PCHANNEL_FAILED: 'FETCH_STORE_PCHANNEL_FAILED',
  FETCH_STORE_PCHANNEL_NEW: 'FETCH_STORE_PCHANNEL_NEW',
  // CHANGE PCHANNEL STATUS
  CHANGE_STORE_PCHANNEL_STATUS: 'CHANGE_STORE_PCHANNEL_STATUS',
  CHANGING_STORE_PCHANNEL_STATUS: 'CHANGING_STORE_PCHANNEL_STATUS',
  STORE_PCHANNEL_STATUS_CHANGED: 'STORE_PCHANNEL_STATUS_CHANGED',
  CHANGE_STORE_PCHANNEL_STATUS_FAILED: 'CHANGE_STORE_PCHANNEL_STATUS_FAILED',
  // DELETE STORE
  DELETE_STORE: 'DELETE_STORE',
  DELETING_STORE: 'DELETING_STORE',
  DELETED_STORE: 'DELETED_STORE',
  DELETE_STORE_FAILED: 'DELETE_STORE_FAILED',
  RESET_DELETE_STORE: 'RESET_DELETE_STORE',
  // DOWNLOAD PLAIN QR
  DOWNLOAD_PLAIN_QR: 'DOWNLOAD_PLAIN_QR',
  DOWNLOADING_PLAIN_QR: 'DOWNLOADING_PLAIN_QR',
  DOWNLOADED_PLAIN_QR: 'DOWNLOADED_PLAIN_QR',
  DOWNLOAD_PLAIN_QR_FAILED: 'DOWNLOAD_PLAIN_QR_FAILED',
  RESET_DOWNLOADED_PLAIN_QR: 'RESET_DOWNLOADED_PLAIN_QR',
  // OPEN DOWNLOAD STORE REPORT MODAL
  OPEN_DOWNLOAD_STORE_REPORT_MODAL: 'OPEN_DOWNLOAD_STORE_REPORT_MODAL',

  // FETCH ALL PCHANNELS INFO
  FETCH_ALL_PCHANNELS_INFO: "FETCH_ALL_PCHANNELS_INFO",
  FETCH_ALL_PCHANNELS_INFO_SUCCESS: "FETCH_ALL_PCHANNELS_INFO_SUCCESS",
  FETCH_ALL_PCHANNELS_INFO_LOADING: "FETCH_ALL_PCHANNELS_INFO_LOADING",
  FETCH_ALL_PCHANNELS_INFO_FAILED: "FETCH_ALL_PCHANNELS_INFO_FAILED",
  
  openStoreRepModal: (payload) => ({
    type: actions.OPEN_DOWNLOAD_STORE_REPORT_MODAL,
    payload,
  }),
  downloadStoreQr: (payload) => ({
    type: actions.DOWNLOAD_STORE_QR,
    payload,
  }),
  resetDownloadedQr: () => ({
    type: actions.RESET_DOWNLOADED_STORE_QR,
  }),
  downloadPlainQr: (payload) => ({
    type: actions.DOWNLOAD_PLAIN_QR,
    payload,
  }),
  resetDownloadedPlainQr: () => ({
    type: actions.RESET_DOWNLOADED_PLAIN_QR,
  }),
  saveStoreValues: (payload) => ({
    type: actions.SAVE_STORE,
    payload: payload,
  }),
  resetSavedStoreMessage: () => ({
    type: actions.SAVED_STORE_MESSAGE,
    payload: null,
    isError: null,
  }),
  dialogAction: (payload) => ({
    type: actions.DIALOG_ADD_ACTION,
    payload: payload,
  }),
  fetchStorePChannels: (store_id) => ({
    type: actions.FETCH_STORE_PCHANNEL,
    store_id,
  }),
  fetchStorePChannelsNew: (store_id) => ({
    type: actions.FETCH_STORE_PCHANNEL_NEW,
    store_id,
  }),
  changeStorePchannelStatus: (store_id, payload, currentStore) => ({
    type: actions.CHANGE_STORE_PCHANNEL_STATUS,
    store_id,
    payload,
    currentStore,
  }),
  deleteStore: (payload) => ({
    type: actions.DELETE_STORE,
    payload,
  }),
  resetDeleteStore: () => ({
    type: actions.RESET_DELETE_STORE,
  }),
  fetchAllPchannelsInfo: (columnsArray) => ({
    type: actions.FETCH_ALL_PCHANNELS_INFO,
    payload: columnsArray ? columnsArray : null,
  }),
};
export default actions;
