const actions = {
  LOAD_MERCHANT_INFO: 'LOAD_MERCHANT_INFO',
  LOAD_MERCHANT_INFO_FAILED: 'LOAD_MERCHANT_INFO_FAILED',
  LOADING_MERCHANT_INFO: 'LOADING_MERCHANT_INFO',
  MERCHANT_INFO_LOADED: 'MERCHANT_INFO_LOADED',
  //Merchant Payment Channels
  LOAD_MERCHANT_PCHANNEL: 'LOAD_MERCHANT_PCHANNEL',
  LOAD_MERCHANT_PCHANNEL_FAILED: 'LOAD_MERCHANT_PCHANNEL_FAILED',
  LOADING_MERCHANT_PCHANNEL: 'LOADING_MERCHANT_PCHANNEL',
  MERCHANT_PCHANNEL_LOADED: 'MERCHANT_PCHANNEL_LOADED',
  // Fetch Merchant Users
  FETCH_MERCHANT_USERS: 'FETCH_MERCHANT_USERS',
  FETCHING_MERCHANT_USERS: 'FETCHING_MERCHANT_USERS',
  LOADING_MORE_MERCHANT_USERS: 'LOADING_MORE_MERCHANT_USERS',
  MERCHANT_USERS_FETCHED: 'MERCHANT_USERS_FETCHED',
  FETCH_MERCHANT_USERS_FAILED: 'FETCH_MERCHANT_USERS_FAILED',
  // SET MERCHANT USER VALUE
  SET_MERCHANT_USER_DATA: 'SET_MERCHANT_USER_DATA',
  // UPDATE USER IN USER PORTAL
  UPDATE_MERCHANT_USER: 'UPDATE_MERCHANT_USER',
  UPDATING_MERCHANT_USER: 'UPDATING_MERCHANT_USER',
  RESET_EDIT_USER_FORM: 'RESET_EDIT_USER_FORM',
  MERCHANT_USER_UPDATED: 'MERCHANT_USER_UPDATED',
  UPDATE_MERCHANT_USER_SUCCESS: 'UPDATE_MERCHANT_USER_SUCCESS',
  UPDATE_MERCHANT_USER_FAILED: 'UPDATE_MERCHANT_USER_FAILED',
  // ADD USER IN USER PORTAL
  ADD_MERCHANT_USER: 'ADD_MERCHANT_USER',
  ADDING_MERCHANT_USER: 'ADDING_MERCHANT_USER',
  MERCHANT_USER_ADDED: 'MERCHANT_USER_ADDED',
  RESET_ADD_USER_FORM: 'RESET_ADD_USER_FORM',
  ADD_MERCHANT_USER_FAILED: 'ADD_MERCHANT_USER_FAILED',
  // FETCH FILTERED ROLE IN USER PORTAL
  FETCH_ALL_FILTERED_ROLE: 'FETCH_ALL_FILTERED_ROLE',
  FETCHING_ALL_FILTERED_ROLE: 'FETCHING_ALL_FILTERED_ROLE',
  FILTERED_ROLE_FETCHED: 'FILTERED_ROLE_FETCHED',
  FETCH_ALL_FILTERED_ROLE_FAILED: 'FETCH_ALL_FILTERED_ROLE_FAILED',
  // FETCH AUTH MANAGMENT ROLE
  FETCH_AUTH_MANAGEMENT_ROLE: 'FETCH_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE: 'FETCHING_AUTH_MANAGEMENT_ROLE',
  AUTH_MANAGEMENT_ROLE_FETCHED: 'AUTH_MANAGEMENT_ROLE_FETCHED',
  FETCH_AUTH_MANAGEMENT_ROLE_FAILED: 'FETCH_AUTH_MANAGEMENT_ROLE_FAILED',
  // FETCH ROLE PERMISSION
  FETCH_ROLE_PERMISSION: 'FETCH_ROLE_PERMISSION',
  FETCHING_ROLE_PERMISSION: 'FETCHING_ROLE_PERMISSION',
  ROLE_PERMISSION_FETCHED: 'ROLE_PERMISSION_FETCHED',
  FETCH_ROLE_PERMISSION_FAILED: 'FETCH_ROLE_PERMISSION_FAILED',
  OPEN_ROLE_PERMISSION_DIALOG: 'OPEN_ROLE_PERMISSION_DIALOG',
  RESET_ROLE_PERMISSION_DATA: 'RESET_ROLE_PERMISSION_DATA',

  // SAVE AUTH MANAGEMENT
  SAVE_AUTH_MANAGEMENT: 'SAVE_AUTH_MANAGEMENT',
  SAVING_AUTH_MANAGEMENT: 'SAVING_AUTH_MANAGEMENT',
  AUTH_MANAGEMENT_SAVED: 'AUTH_MANAGEMENT_SAVED',
  SAVE_AUTH_MANAGEMENT_FAILED: 'SAVE_AUTH_MANAGEMENT_FAILED',
  

  // DEACTIVATE USER IN USER PORTAL
  DEACTIVATE_MERCHANT_USER: 'DEACTIVATE_MERCHANT_USER',
  DEACTIVATING_MERCHANT_USER: 'DEACTIVATING_MERCHANT_USER',
  MERCHANT_USER_DEACTIVATED: 'MERCHANT_USER_DEACTIVATED',
  DEACTIVATE_MERCHANT_USER_FAILED: 'DEACTIVATE_MERCHANT_USER_FAILED',
  DEACTIVATE_MERCHANT_USER_RESET: 'DEACTIVATE_MERCHANT_USER_RESET',
  // RESET USER IN USER PORTAL
  RESET_MERCHANT_USER_PASSWORD: 'RESET_MERCHANT_USER_PASSWORD',
  RESETTING_MERCHANT_USER_PASSWORD: 'RESETTING_MERCHANT_USER_PASSWORD',
  MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'MERCHANT_USER_PASSWORD_RESET_SUCCESS',
  MERCHANT_USER_PASSWORD_RESET_FAILED: 'MERCHANT_USER_PASSWORD_RESET_FAILED',
  MERCHANT_USER_PASSWORD_RESET_ERROR: 'MERCHANT_USER_PASSWORD_RESET_ERROR',
  RESET_MERCHANT_USER_PASSWORD_RESET: 'RESET_MERCHANT_USER_PASSWORD_RESET',
  // NEW RESET PASSWORD
  NEW_RESET_MERCHANT_USER_PASSWORD: 'NEW_RESET_MERCHANT_USER_PASSWORD',
  NEW_RESETTING_MERCHANT_USER_PASSWORD: 'NEW_RESETTING_MERCHANT_USER_PASSWORD',
  NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS',
  NEW_MERCHANT_USER_PASSWORD_RESET_FAILED: 'NEW_MERCHANT_USER_PASSWORD_RESET_FAILED',
  NEW_MERCHANT_USER_PASSWORD_RESET_ERROR: 'NEW_MERCHANT_USER_PASSWORD_RESET_ERROR',
  NEW_RESET_MERCHANT_USER_PASSWORD_RESET: 'NEW_RESET_MERCHANT_USER_PASSWORD_RESET',
  // GET ALL PCHANNELS
  GET_ALL_PCHANNELS: 'GET_ALL_PCHANNELS',
  GETTING_ALL_PCHANNELS: 'GETTING_ALL_PCHANNELS',
  GET_ALL_PCHANNELS_SUCCESS: 'GET_ALL_PCHANNELS_SUCCESS',
  GET_ALL_PCHANNELS_FAILED: 'GET_ALL_PCHANNELS_FAILED',
  // EDIT MERCHANT DETAILS
  EDIT_MERCHANT_DETAILS: 'EDIT_MERCHANT_DETAILS',
  EDITING_MERCHANT_DETAILS: 'EDITING_MERCHANT_DETAILS',
  MERCHANT_DETAILS_EDITED: 'MERCHANT_DETAILS_EDITED',
  EDIT_MERCHANT_DETAILS_FAILED: 'EDIT_MERCHANT_DETAILS_FAILED',
  RESET_MESSAGE_NOTIF: 'RESET_MESSAGE_NOTIF',
  // DOWNLOAD HISTORY
  DOWNLOAD_HISTORY: 'DOWNLOAD_HISTORY',
  DOWNLOADING_HISTORY: 'DOWNLOADING_HISTORY',
  DOWNLOAD_HISTORY_SUCCESS: 'DOWNLOAD_HISTORY_SUCCESS',
  DOWNLOAD_HISTORY_FAILED: 'DOWNLOAD_HISTORY_FAILED',
  RESET_DOWNLOAD_HISTORY: 'RESET_DOWNLOAD_HISTORY',
  // DOWNLOAD STORE TRANSACTIONS
  DOWNLOAD_STORE_TXN: 'DOWNLOAD_STORE_TXN',
  DOWNLOADING_STORE_TXN: 'DOWNLOADING_STORE_TXN',
  DOWNLOAD_STORE_TXN_SUCCESS: 'DOWNLOAD_STORE_TXN_SUCCESS',
  DOWNLOAD_STORE_TXN_FAILED: 'DOWNLOAD_STORE_TXN_FAILED',
  RESET_DOWNLOADED_STORE_TXN: 'RESET_DOWNLOADED_STORE_TXN',
  // FETCH RISK PROFILE
  FETCH_RISK_PROFILE: 'FETCH_RISK_PROFILE',
  FETCHING_RISK_PROFILE: 'FETCHING_RISK_PROFILE',
  FETCHING_RISK_PROFILE_SUCCESS: 'FETCHING_RISK_PROFILE_SUCCESS',
  FETCHING_RISK_PROFILE_FAILED: 'FETCHING_RISK_PROFILE_FAILED',
  // GET KYC QUALIFIED
  GET_KYC_QUALIFIED: 'GET_KYC_QUALIFIED',
  GETTING_KYC_QUALIFIED: 'GETTING_KYC_QUALIFIED',
  GOT_KYC_QUALIFIED: 'GOT_KYC_QUALIFIED',
  GETTING_KYC_QUALIFIED_FAILED: 'GETTING_KYC_QUALIFIED_FAILED',
  // POST KYC DOCS
  POST_KYC_DOCS: 'POST_KYC_DOCS',
  POSTING_KYC_DOCS: 'POSTING_KYC_DOCS',
  POSTED_KYC_DOCS: 'POSTED_KYC_DOCS',
  MQTT_POSTING_RESULT: 'MQTT_POSTING_RESULT',
  POSTING_KYC_DOCS_FAILED: 'POSTING_KYC_DOCS_FAILED',
  RESET_KYC_POST: 'RESET_KYC_POST',
  RELOAD_KYC: 'RELOAD_KYC',
  //
  UPDATING_KYC_DOCS: 'UPDATING_KYC_DOCS',
  UPDATED_KYC_DOCS: 'UPDATED_KYC_DOCS',
  // PREVIEW KYC DOCS
  PREVIEW_KYC_DOCS: 'PREVIEW_KYC_DOCS',
  PREVIEWING_KYC_DOCS: 'PREVIEWING_KYC_DOCS',
  PREVIEWED_KYC_DOCS: 'PREVIEWED_KYC_DOCS',
  PREVIEWING_KYC_DOCS_FAILED: 'PREVIEWING_KYC_DOCS_FAILED',
  RESET_KYC_PREVIEW: 'RESET_KYC_PREVIEW',
  // FETCH MERCHANT REFERRAL LINK
  FETCH_MERCHANT_REFERRAL_LINK: 'FETCH_MERCHANT_REFERRAL_LINK',
  FETCHING_MERCHANT_REFERRAL_LINK: 'FETCHING_MERCHANT_REFERRAL_LINK',
  FETCH_MERCHANT_REFERRAL_LINK_SUCCESS: 'FETCH_MERCHANT_REFERRAL_LINK_SUCCESS',
  FETCH_MERCHANT_REFERRAL_LINK_FAILED: 'FETCH_MERCHANT_REFERRAL_LINK_FAILED',
  // UPLOAD LOGO
  UPLOAD_MERCHANT_LOGO: 'UPLOAD_MERCHANT_LOGO',
  UPLOADING_MERCHANT_LOGO: 'UPLOADING_MERCHANT_LOGO',
  UPLOADED_MERCHANT_LOGO: 'UPLOADED_MERCHANT_LOGO',
  UPLOAD_MERCHANT_LOGO_FAILED: 'UPLOAD_MERCHANT_LOGO_FAILED',
  RESET_MERCHANT_LOGO_UPLOAD: 'RESET_MERCHANT_LOGO_UPLOAD',
  // ASK REUPLOAD KYC DOCS
  ASK_FOR_REUPLOAD: 'ASK_FOR_REUPLOAD',
  ASKING_FOR_REUPLOAD: 'ASKING_FOR_REUPLOAD',
  ASKED_FOR_REUPLOAD: 'ASKED_FOR_REUPLOAD',
  ASKING_FOR_REUPLOAD_FAILED: 'ASKING_FOR_REUPLOAD_FAILED',
  RESET_ASK_REUPLOAD: 'RESET_ASK_REUPLOAD',
  // APPROVE KYC DOCS
  APPROVE_KYC_DOCS: 'APPROVE_KYC_DOCS',
  APPROVING_KYC_DOCS: 'APPROVING_KYC_DOCS',
  APPROVED_KYC_DOCS: 'APPROVED_KYC_DOCS',
  APPROVING_KYC_DOCS_FAILED: 'APPROVING_KYC_DOCS_FAILED',
  RESET_APPROVING: 'RESET_APPROVING',
  // UPDATE MERCHANT CHANNELS
  UPDATE_MERCHANT_CHANNELS: 'UPDATE_MERCHANT_CHANNELS',
  UPDATING_MERCHANT_CHANNELS: 'UPDATING_MERCHANT_CHANNELS',
  MERCHANT_CHANNELS_UPDATED: 'MERCHANT_CHANNELS_UPDATED',
  UPDATE_MERCHANT_CHANNELS_FAILED: 'UPDATE_MERCHANT_CHANNELS_FAILED',
  RESET_UPDATE_MERCHANT_CHANNELS: 'RESET_UPDATE_MERCHANT_CHANNELS',
  // FETCH MERCHANT IMAGE
  FETCH_MERCHANT_IMAGE: 'FETCH_MERCHANT_IMAGE',
  FETCHING_MERCHANT_IMAGE: 'FETCHING_MERCHANT_IMAGE',
  FETCHED_MERCHANT_IMAGE: 'FETCHED_MERCHANT_IMAGE',
  FETCH_MERCHANT_IMAGE_FAILED: 'FETCH_MERCHANT_IMAGE_FAILED',
  // FETCH MAINTENANCE PCHANNELS
  FETCH_MAINTENANCE_PCHANNELS: 'FETCH_MAINTENANCE_PCHANNELS',
  FETCH_MAINTENANCE_PCHANNELS_SUCCESS: 'FETCH_MAINTENANCE_PCHANNELS_SUCCESS',
  FETCH_MAINTENANCE_PCHANNELS_LOADING: 'FETCH_MAINTENANCE_PCHANNELS_LOADING',
  FETCH_MAINTENANCE_PCHANNELS_FAILED: 'FETCH_MAINTENANCE_PCHANNELS_FAILED',
  FETCH_MAINTENANCE_PCHANNELS_RESET: 'FETCH_MAINTENANCE_PCHANNELS_RESET',
  // FETCH CUSTOMER DATA
  FETCH_CUSTOMER_DATA: 'FETCH_CUSTOMER_DATA',
  FETCHING_CUSTOMER_DATA: 'FETCHING_CUSTOMER_DATA',
  FETCHED_CUSTOMER_DATA: 'FETCHED_CUSTOMER_DATA',
  FETCH_CUSTOMER_DATA_FAILED: 'FETCH_CUSTOMER_DATA_FAILED',
  // PAYMENT TRANSACTION REQUEST
  PAYMENT_TRANSACTION_REQUEST: 'PAYMENT_TRANSACTION_REQUEST',
  PAYMENT_TRANSACTION_REQUESTING: 'PAYMENT_TRANSACTION_REQUESTING',
  PAYMENT_TRANSACTION_REQUESTED: 'PAYMENT_TRANSACTION_REQUESTED',
  PAYMENT_TRANSACTION_REQUEST_FAILED: 'PAYMENT_TRANSACTION_REQUEST_FAILED',
  RESET_PAYMENT_TRANSACTION_REQUEST: 'RESET_PAYMENT_TRANSACTION_REQUEST',
  // RESET TOKEN DATA
  RESET_TOKEN_DATA: 'RESET_TOKEN_DATA',
  RESETTING_TOKEN_DATA: 'RESETTING_TOKEN_DATA',
  RESET_TOKEN_DATA_SUCCESS: 'RESET_TOKEN_DATA_SUCCESS',
  RESET_TOKEN_DATA_FAILED: 'RESET_TOKEN_DATA_FAILED',
  // MQTT KYC
  KYC_MQTT: 'KYC_MQTT',
  RESET_KYC_MQTT: 'RESET_KYC_MQTT',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
  // FETCH MERCHANT SETTINGS
  FETCH_MERCHANT_SETTINGS: 'FETCH_MERCHANT_SETTINGS',
  FETCHING_MERCHANT_SETTINGS: 'FETCHING_MERCHANT_SETTINGS',
  FETCHED_MERCHANT_SETTINGS: 'FETCHED_MERCHANT_SETTINGS',
  FETCHING_MERCHANT_SETTINGS_FAILED: 'FETCHING_MERCHANT_SETTINGS_FAILED',
  // UPDATE MERCHANT SETTINGS
  UPDATE_MERCHANT_SETTINGS: 'UPDATE_MERCHANT_SETTINGS',
  UPDATING_MERCHANT_SETTINGS: 'UPDATING_MERCHANT_SETTINGS',
  UPDATED_MERCHANT_SETTINGS: 'UPDATED_MERCHANT_SETTINGS',
  UPDATING_MERCHANT_SETTINGS_FAILED: 'UPDATING_MERCHANT_SETTINGS_FAILED',
  UPDATING_MERCHANT_SETTINGS_ERROR: 'UPDATING_MERCHANT_SETTINGS_ERROR',
  RESET_UPDATE_MERCHANT_SETTINGS: 'RESET_UPDATE_MERCHANT_SETTINGS',
  // FETCH WEBSTORE DATA
  FETCH_WEBSTORE_DATA: 'FETCH_WEBSTORE_DATA',
  FETCHING_WEBSTORE_DATA: 'FETCHING_WEBSTORE_DATA',
  FETCH_WEBSTORE_DATA_SUCCESS: 'FETCH_WEBSTORE_DATA_SUCCESS',
  FETCH_WEBSTORE_DATA_FAILED: 'FETCH_WEBSTORE_DATA_FAILED',
  FETCH_WEBSTORE_DATA_ERROR: 'FETCH_WEBSTORE_DATA_ERROR',
  // SAVE WEBSTORE DATA
  SAVE_WEBSTORE_DATA: 'SAVE_WEBSTORE_DATA',
  SAVING_WEBSTORE_DATA: 'SAVING_WEBSTORE_DATA',
  SAVE_WEBSTORE_DATA_SUCCESS: 'SAVE_WEBSTORE_DATA_SUCCESS',
  SAVE_WEBSTORE_DATA_FAILED: 'SAVE_WEBSTORE_DATA_FAILED',
  SAVE_WEBSTORE_DATA_ERROR: 'SAVE_WEBSTORE_DATA_ERROR',
  // UPDATE WEBSTORE DATA
  UPDATE_WEBSTORE_DATA: 'UPDATE_WEBSTORE_DATA',
  UPDATING_WEBSTORE_DATA: 'UPDATING_WEBSTORE_DATA',
  UPDATE_WEBSTORE_DATA_SUCCESS: 'UPDATE_WEBSTORE_DATA_SUCCESS',
  UPDATE_WEBSTORE_DATA_FAILED: 'UPDATE_WEBSTORE_DATA_FAILED',
  UPDATE_WEBSTORE_DATA_ERROR: 'UPDATE_WEBSTORE_DATA_ERROR',
  RESET_WEBSTORE_DATA: 'RESET_WEBSTORE_DATA',
  // UPDATE WEBSTORE DATA
  UPDATE_WEBSTORE_CATALOG: 'UPDATE_WEBSTORE_CATALOG',
  UPDATING_WEBSTORE_CATALOG: 'UPDATING_WEBSTORE_CATALOG',
  UPDATE_WEBSTORE_CATALOG_SUCCESS: 'UPDATE_WEBSTORE_CATALOG_SUCCESS',
  UPDATE_WEBSTORE_CATALOG_FAILED: 'UPDATE_WEBSTORE_CATALOG_FAILED',
  UPDATE_WEBSTORE_CATALOG_ERROR: 'UPDATE_WEBSTORE_CATALOG_ERROR',
  RESET_WEBSTORE_CATALOG: 'RESET_WEBSTORE_CATALOG',
  // UPLOAD WEBSTORE BANNER
  UPLOAD_WEBSTORE_BANNER: 'UPLOAD_WEBSTORE_BANNER',
  UPLOADING_WEBSTORE_BANNER: 'UPLOADING_WEBSTORE_BANNER',
  UPLOAD_WEBSTORE_BANNER_SUCCESS: 'UPLOAD_WEBSTORE_BANNER_SUCCESS',
  UPLOAD_WEBSTORE_BANNER_FAILED: 'UPLOAD_WEBSTORE_BANNER_FAILED',
  UPLOAD_WEBSTORE_BANNER_ERROR: 'UPLOAD_WEBSTORE_BANNER_ERROR',
  // UPDATE SHIPPING_SETUP
  UPDATE_SHIPPING_SETUP: 'UPDATE_SHIPPING_SETUP',
  UPDATING_SHIPPING_SETUP: 'UPDATING_SHIPPING_SETUP',
  UPDATE_SHIPPING_SETUP_SUCCESS: 'UPDATE_SHIPPING_SETUP_SUCCESS',
  UPDATE_SHIPPING_SETUP_FAILED: 'UPDATE_SHIPPING_SETUP_FAILED',
  UPDATE_SHIPPING_SETUP_ERROR: 'UPDATE_SHIPPING_SETUP_ERROR',
  UPDATE_SHIPPING_SETUP_RESET: 'UPDATE_SHIPPING_SETUP_RESET',
  // FETCH PROVINCE DATA
  FETCH_PROVINCE: 'FETCH_PROVINCE',
  FETCHING_PROVINCE: 'FETCHING_PROVINCE',
  FETCH_PROVINCE_SUCCESS: 'FETCH_PROVINCE_SUCCESS',
  FETCH_PROVINCE_FAILED: 'FETCH_PROVINCE_FAILED',
  FETCH_PROVINCE_ERROR: 'FETCH_PROVINCE_ERROR',
  FETCH_PROVINCE_RESET: 'FETCH_PROVINCE_RESET',
  // MERCHANT SETTINGS OTP
  SEND_OTP: 'SEND_OTP',
  SENDING_OTP: 'SENDING_OTP',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILED: 'SEND_OTP_FAILED',
  SEND_OTP_ERROR: 'SEND_OTP_ERROR',
  SEND_OTP_RESET: 'SEND_OTP_RESET',
  // SENDING EMAIL LIMIT WARNING
  SEND_EMAIL_LIMIT_WARNING: 'SEND_EMAIL_LIMIT_WARNING',
  SENDING_EMAIL_LIMIT_WARNING: 'SENDING_EMAIL_LIMIT_WARNING',
  SENT_EMAIL_LIMIT_WARNING: 'SENT_EMAIL_LIMIT_WARNING',
  SEND_EMAIL_LIMIT_WARNING_FAILED: 'SEND_EMAIL_LIMIT_WARNING_FAILED',

  GET_PCHANNELS_VALUES:'GET_PCHANNELS_VALUES',
  GETTING_PCHANNELS_VALUES:'GETTING_PCHANNELS_VALUES',
  GETTING_PCHANNELS_VALUES_FAILED:'GETTING_PCHANNELS_VALUES_FAILED',
  GETTING_PCHANNELS_VALUES_SUCCESS:'GETTING_PCHANNELS_VALUES_SUCCESS',
  RESET_PCHANNELS_VALUE:'RESET_PCHANNELS_VALUE',

  GET_PCHANNELS_VALUES_WITHDRAW:'GET_PCHANNELS_VALUES_WITHDRAW',
  GETTING_PCHANNELS_VALUES_WITHDRAW:'GETTING_PCHANNELS_VALUES_WITHDRAW',
  GETTING_PCHANNELS_VALUES_WITHDRAW_FAILED:'GETTING_PCHANNELS_VALUES_WITHDRAW_FAILED',
  GETTING_PCHANNELS_VALUES_WITHDRAW_SUCCESS:'GETTING_PCHANNELS_VALUES_WITHDRAW_SUCCESS',

  GET_PCHANNELS_FOR_FEE:'GET_PCHANNELS_FOR_FEE',
  GETTING_PCHANNELS_FOR_FEE:'GETTING_PCHANNELS_FOR_FEE',
  GETTING_PCHANNELS_FOR_FEE_FAILED:'GETTING_PCHANNELS_FOR_FEE_FAILED',
  GETTING_PCHANNELS_FOR_FEE_SUCCESS:'GETTING_PCHANNELS_FOR_FEE_SUCCESS',

  SAVE_PCHANNEL_FEE:'SAVE_PCHANNEL_FEE',
  SAVING_PCHANNEL_FEE:'SAVING_PCHANNEL_FEE',
  SAVE_PCHANNEL_FEE_FAILED:'SAVE_PCHANNEL_FEE_FAILED',
  SAVE_PCHANNEL_FEE_SUCCESS:'SAVE_PCHANNEL_FEE_SUCCESS',
  RESET_SAVE_PCHANNEL_FEE:'RESET_SAVE_PCHANNEL_FEE',

  UPDATE_PCHANNELS_FEE:'UPDATE_PCHANNELS_FEE',
  UPDATING_PCHANNELS_FEE:'UPDATING_PCHANNELS_FEE',
  UPDATING_PCHANNELS_FEE_SUCCESS:'UPDATING_PCHANNELS_FEE_SUCCESS',
  UPDATING_PCHANNELS_FEE_FAILED:'UPDATING_PCHANNELS_FEE_FAILED',

  VALIDATE_ACTIVATION_CODE:'VALIDATE_ACTIVATION_CODE',
  VALIDATING_ACTIVATION_CODE:'VALIDATING_ACTIVATION_CODE',
  VALIDATE_ACTIVATION_CODE_SUCCESS:'VALIDATE_ACTIVATION_CODE_SUCCESS',
  VALIDATE_ACTIVATION_CODE_FAILED:'VALIDATE_ACTIVATION_CODE_FAILED',

  FETCH_CC_REGISTRATION_STATUS:'FETCH_CC_REGISTRATION_STATUS',
  FETCHING_CC_REGISTRATION_STATUS:'FETCHING_CC_REGISTRATION_STATUS',
  FETCHING_CC_REGISTRATION_SUCCESS:'FETCHING_CC_REGISTRATION_SUCCESS',
  FETCHING_CC_RESGISTRATION_FAILED:'FETCHING_CC_RESGISTRATION_FAILED',

  FETCH_SUBMERCHANTS:'FETCH_SUBMERCHANTS',
  FETCHING_SUBMERCHANTS:'FETCHING_SUBMERCHANTS',
  FETCHED_SUBMERCHANTS:'FETCHED_SUBMERCHANTS',
  FETCH_SUBMERCHANTS_FAILED:'FETCH_SUBMERCHANTS_FAILED',

  FETCH_MERCHANT_STORE: 'FETCH_MERCHANT_STORE',
  FETCHING_MERCHANT_STORE: 'FETCHING_MERCHANT_STORE',
  FETCHED_MERCHANT_STORE: 'FETCHED_MERCHANT_STORE',
  FETCH_MERCHANT_STORE_FAILED: 'FETCH_MERCHANT_STORE_FAILED',

  SAVE_SEARCHED_MERCHANT: 'SAVE_SEARCHED_MERCHANT',

  FETCH_MERCHANT_STORE_INVENTORY: 'FETCH_MERCHANT_STORE_INVENTORY',
  FETCHING_MERCHANT_STORE_INVENTORY: 'FETCHING_MERCHANT_STORE_INVENTORY',
  FETCHED_MERCHANT_STORE_INVENTORY: 'FETCHED_MERCHANT_STORE_INVENTORY',
  FETCH_MERCHANT_STORE_INVENTORY_FAILED: 'FETCH_MERCHANT_STORE_INVENTORY_FAILED',
  RESET_FETCH_MERCHANT_STORE_INVENTORY: 'RESET_FETCH_MERCHANT_STORE_INVENTORY',

  UPDATE_COURIER_SETTING: 'UPDATE_COURIER_SETTING',
  UPDATING_COURIER_SETTING: 'UPDATING_COURIER_SETTING',
  UPDATED_COURIER_SETTING: 'UPDATED_COURIER_SETTING',
  UPDATE_COURIER_SETTING_FAILED: 'UPDATE_COURIER_SETTING_FAILED',
  RESET_UPDATE_COURIER_SETTING: 'RESET_UPDATE_COURIER_SETTING',
  // GET CC DOCS
  GET_CC_DOCS: 'GET_CC_DOCS',
  GETTING_CC_DOCS: 'GETTING_CC_DOCS',
  GOT_CC_DOCS: 'GOT_CC_DOCS',
  GETTING_CC_DOCS_FAILED: 'GETTING_CC_DOCS_FAILED',

  // POST CC DOCS
  POST_CC_DOCS: 'POST_CC_DOCS',
  POSTING_CC_DOCS: 'POSTING_CC_DOCS',
  POSTED_CC_DOCS: 'POSTED_CC_DOCS',
  MQTT_CC_POSTING_RESULT: 'MQTT_CC_POSTING_RESULT',
  POSTING_CC_DOCS_FAILED: 'POSTING_CC_DOCS_FAILED',
  RESET_CC_POST: 'RESET_CC_POST',
  RELOAD_CC: 'RELOAD_CC',
  // UPDATE CC DOCS
  UPDATING_CC_DOCS: 'UPDATING_CC_DOCS',
  UPDATED_CC_DOCS: 'UPDATED_CC_DOCS',
  // PREVIEW CC DOCS
  PREVIEW_CC_DOCS: 'PREVIEW_CC_DOCS',
  PREVIEWING_CC_DOCS: 'PREVIEWING_CC_DOCS',
  PREVIEWED_CC_DOCS: 'PREVIEWED_CC_DOCS',
  PREVIEWING_CC_DOCS_FAILED: 'PREVIEWING_CC_DOCS_FAILED',
  RESET_CC_PREVIEW: 'RESET_CC_PREVIEW',
  // ASK REUPLOAD CC DOCS
  ASK_FOR_CC_REUPLOAD: 'ASK_FOR_CC_REUPLOAD',
  ASKING_FOR_CC_REUPLOAD: 'ASKING_FOR_CC_REUPLOAD',
  ASKED_FOR_CC_REUPLOAD: 'ASKED_FOR_CC_REUPLOAD',
  ASKING_FOR_CC_REUPLOAD_FAILED: 'ASKING_FOR_CC_REUPLOAD_FAILED',
  RESET_ASK_CC_REUPLOAD: 'RESET_ASK_CC_REUPLOAD',
  // APPROVE CC DOCS
  APPROVE_CC_DOCS: 'APPROVE_CC_DOCS',
  APPROVING_CC_DOCS: 'APPROVING_CC_DOCS',
  APPROVED_CC_DOCS: 'APPROVED_CC_DOCS',
  APPROVING_CC_DOCS_FAILED: 'APPROVING_CC_DOCS_FAILED',
  RESET_CC_APPROVING: 'RESET_CC_APPROVING',
  // MQTT CC
  CC_MQTT: 'CC_MQTT',
  RESET_CC_MQTT: 'RESET_CC_MQTT',
  RESET_CC_ERROR_MESSAGE: 'RESET_CC_ERROR_MESSAGE',

  CHECK_COURIER_ISSUE_PICK_UP: 'CHECK_COURIER_ISSUE_PICK_UP',
  CHECK_COURIER_ISSUE_PRODUCT_SPECS: 'CHECK_COURIER_ISSUE_PRODUCT_SPECS',

  // FETCHING MERCHANT WALLET
  FETCH_MERCHANT_WALLET: 'FETCH_MERCHANT_WALLET',
  FETCHING_MERCHANT_WALLET: 'FETCHING_MERCHANT_WALLET',
  FETCHED_MERCHANT_WALLET: 'FETCHED_MERCHANT_WALLET',
  FETCH_MERCHANT_WALLET_FAILED: 'FETCH_MERCHANT_WALLET_FAILED',
  RESET_FETCH_MERCHANT_WALLET: 'RESET_FETCH_MERCHANT_WALLET',

  // FETCHING MERCHANT WALLET
  FETCH_FORM_BUILDER: 'FETCH_FORM_BUILDER',
  FETCHING_FORM_BUILDER: 'FETCHING_FORM_BUILDER',
  FETCHED_FORM_BUILDER: 'FETCHED_FORM_BUILDER',
  FETCH_FORM_BUILDER_FAILED: 'FETCH_FORM_BUILDER_FAILED',
  RESET_FORM_BUILDER: 'RESET_FORM_BUILDER',

  // FETCH SERVICEABLE QUERY
  FETCH_SERVICEABLE_QUERY: 'FETCH_SERVICEABLE_QUERY',
  FETCHING_SERVICEABLE_QUERY: 'FETCHING_SERVICEABLE_QUERY',
  FETCHED_SERVICEABLE_QUERY: 'FETCHED_SERVICEABLE_QUERY',
  FETCH_SERVICEABLE_QUERY_FAILED: 'FETCH_SERVICEABLE_QUERY_FAILED',

  // FETCH ROLLING RESERVE SETTINGS
  FETCH_ROLLING_RESERVE_SETTINGS: 'FETCH_ROLLING_RESERVE_SETTINGS',
  FETCH_ROLLING_RESERVE_SETTINGS_LOADING: 'FETCH_ROLLING_RESERVE_SETTINGS_LOADING',
  FETCH_ROLLING_RESERVE_SETTINGS_SUCCESS: 'FETCH_ROLLING_RESERVE_SETTINGS_SUCCESS',
  FETCH_ROLLING_RESERVE_SETTINGS_FAILED: 'FETCH_ROLLING_RESERVE_SETTINGS_FAILED',

  fetchAllSubmerchants: (payload) => ({
    type: actions.FETCH_SUBMERCHANTS,
    payload: payload,
  }),
  savePchannelsFee: (payload) => ({
    type: actions.SAVE_PCHANNEL_FEE,
    payload: payload,
  }),
  updatePchannelsFee: (payload) => ({
    type: actions.UPDATE_PCHANNELS_FEE,
    payload: payload,
  }),
  resetSavePchannelsFee: () => ({
    type: actions.RESET_SAVE_PCHANNEL_FEE,
  }),
  loadMerchantInfo: () => ({
    type: actions.LOAD_MERCHANT_INFO,
  }),
  loadPChannels: () => ({
    type: actions.LOAD_MERCHANT_PCHANNEL,
    // payload: payload,
  }),
  fetchMerchantUsers: (payload) => ({
    type: actions.FETCH_MERCHANT_USERS,
    payload: payload,
  }),
  setMerchantUserData: (payload) => ({
    type: actions.SET_MERCHANT_USER_DATA,
    payload: payload,
  }),
  updateMerchantUser: (payload) => ({
    type: actions.UPDATE_MERCHANT_USER,
    payload,
  }),
  fetchAllFilteredRole: () => ({
    type: actions.FETCH_ALL_FILTERED_ROLE,
  }),
  fetchAuthManagementRole: () => ({
    type: actions.FETCH_AUTH_MANAGEMENT_ROLE,
  }),
  resetRolePermissionData: () => ({
    type: actions.RESET_ROLE_PERMISSION_DATA,
  }),
  // fetchRolePermission: (payload) => ({
  //   type: actions.FETCH_ROLE_PERMISSION,
  //   payload,
  // }),
  // toggleRolePermissionDialog: () => ({
  //   type: actions.OPEN_ROLE_PERMISSION_DIALOG,
  // }),
  addMerchantUser: (payload) => ({
    type: actions.ADD_MERCHANT_USER,
    payload,
  }),
  deactivateMerchantUser: (payload) => ({
    type: actions.DEACTIVATE_MERCHANT_USER,
    payload,
  }),
  resetAddUserForm: () => ({
    type: actions.RESET_ADD_USER_FORM,
  }),
  resetEditUserForm: (payoad) => ({
    type: actions.RESET_EDIT_USER_FORM,
  }),
  resetPassword: (payload) => ({
    type: actions.RESET_MERCHANT_USER_PASSWORD,
    payload: payload,
  }),
  // NEW RESET PASSWORD
  newResetPassword: (payload) => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD,
    payload,
  }),
  resetErrorMessageHandler: () => ({
    type: actions.RESET_ERROR_MESSAGE,
    payload: null,
    isError: false,
  }),
  newResetPasswordReset: () => ({
    type: actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET,
  }),
  resetDeleteUser: () => ({
    type: actions.DEACTIVATE_MERCHANT_USER_RESET,
  }),
  resetPasswordReset: () => ({
    type: actions.RESET_MERCHANT_USER_PASSWORD_RESET,
  }),
  getAllPchannels: () => ({
    type: actions.GET_ALL_PCHANNELS,
  }),
  editMerchantDetails: (payload) => ({
    type: actions.EDIT_MERCHANT_DETAILS,
    payload: payload,
  }),
  resetMessageNotif: () => ({
    type: actions.RESET_MESSAGE_NOTIF,
  }),
  downloadHistory: (payload) => ({
    type: actions.DOWNLOAD_HISTORY,
    payload: payload,
  }),
  resetDownload: () => ({
    type: actions.RESET_DOWNLOAD_HISTORY,
  }),
  downloadStoreTxn: (payload) => ({
    type: actions.DOWNLOAD_STORE_TXN,
    payload: payload,
  }),
  resetDownloadedStoreTxn: () => ({
    type: actions.RESET_DOWNLOADED_STORE_TXN,
  }),
  fetchRiskProfile: () => ({
    type: actions.FETCH_RISK_PROFILE,
  }),
  getKycQualified: () => ({
    type: actions.GET_KYC_QUALIFIED,
  }),
  submitKycDocs: (payload) => ({
    type: actions.POST_KYC_DOCS,
    payload: payload,
  }),
  resetKycPost: () => ({
    type: actions.RESET_KYC_POST,
  }),
  previewKycDocs: (payload) => ({
    type: actions.PREVIEW_KYC_DOCS,
    payload: payload,
  }),
  resetPreview: () => ({
    type: actions.RESET_KYC_PREVIEW,
  }),
  reloadKyc: () => ({
    type: actions.RELOAD_KYC,
  }),
  fetchMerchantReferralLink: (payload) => ({
    type: actions.FETCH_MERCHANT_REFERRAL_LINK,
    payload: payload,
  }),
  uploadLogo: (payload) => ({
    type: actions.UPLOAD_MERCHANT_LOGO,
    payload: payload,
  }),
  resetLogoUpload: () => ({
    type: actions.RESET_MERCHANT_LOGO_UPLOAD,
  }),
  askForReupload: (payload) => ({
    type: actions.ASK_FOR_REUPLOAD,
    payload: payload,
  }),
  resetAskReupload: () => ({
    type: actions.RESET_ASK_REUPLOAD,
  }),
  approveKycDocuments: () => ({
    type: actions.APPROVE_KYC_DOCS,
  }),
  resetApproving: () => ({
    type: actions.RESET_APPROVING,
  }),
  updateMerchantChannels: (payload) => ({
    type: actions.UPDATE_MERCHANT_CHANNELS,
    payload: payload,
  }),
  fetchMerchantImage: (payload) => ({
    type: actions.FETCH_MERCHANT_IMAGE,
    payload,
  }),
  fetchMaintenancePChannels: (payload) => ({
    type: actions.FETCH_MAINTENANCE_PCHANNELS,
    payload
  }),
  fetchMaintenancePChannelsReset: () => ({
    type: actions.FETCH_MAINTENANCE_PCHANNELS_RESET
  }),
  fetchCustomerData: (payload) => ({
    type: actions.FETCH_CUSTOMER_DATA,
    payload,
  }),
  paymentTransactionRequest: (payload) => ({
    type: actions.PAYMENT_TRANSACTION_REQUEST,
    payload,
  }),
  sendEmailLimitWarning: (payload) => ({
    type: actions.SEND_EMAIL_LIMIT_WARNING,
    payload,
  }),
  resetPaymentTransactionRequest: () => ({
    type: actions.RESET_PAYMENT_TRANSACTION_REQUEST,
  }),
  resetTokenData: (payload) => ({
    type: actions.RESET_TOKEN_DATA,
    payload,
  }),
  resetKycMqtt: () => ({
    type: actions.RESET_KYC_MQTT,
  }),
  fetchMerchantSettings: (payload) => ({
    type: actions.FETCH_MERCHANT_SETTINGS,
    payload,
  }),
  sendOtpMerchantSettings: (payload) => ({
    type: actions.SEND_OTP,
    payload,
  }),
  resetOtpMerchantSettings: () => ({
    type: actions.SEND_OTP_RESET,
  }),
  updateMerchantSettings: (payload) => ({
    type: actions.UPDATE_MERCHANT_SETTINGS,
    payload,
  }),
  resetUpdateMerchantSettings: () => ({
    type: actions.RESET_UPDATE_MERCHANT_SETTINGS,
  }),
  resetUpdateMerchantChannels: () => ({
    type: actions.RESET_UPDATE_MERCHANT_CHANNELS,
  }),
  fetchWebstoreData: () => ({
    type: actions.FETCH_WEBSTORE_DATA,
  }),
  saveWebstoreData: (payload) => ({
    type: actions.SAVE_WEBSTORE_DATA,
    payload,
  }),
  updateWebstoreData: (payload) => ({
    type: actions.UPDATE_WEBSTORE_DATA,
    payload,
  }),
  resetWebstoreData: (payload) => ({
    type: actions.RESET_WEBSTORE_DATA,
    payload,
  }),
  // WEBSTORE CATALOG
  resetWebstoreCatalog: () => ({
    type: actions.RESET_WEBSTORE_CATALOG,
  }),
  // WEBSTORE BANNER
  uploadWebstoreBanner: (payload) => ({
    type: actions.UPLOAD_WEBSTORE_BANNER,
    payload,
  }),
  // shipping setup
  fetchProvince: (payload) => ({
    type: actions.FETCH_PROVINCE,
    payload,
  }),
  updateShippingSetup: (payload) => ({
    type: actions.UPDATE_SHIPPING_SETUP,
    payload,
  }),
  resetShippingSetup: () => ({
    type: actions.UPDATE_SHIPPING_SETUP_RESET,
  }),
  getPchannelsValues: (payload) => ({
    type: actions.GET_PCHANNELS_VALUES,
    payload,
  }),
  getPchannelsValuesWithdraw: (payload) => ({
    type: actions.GET_PCHANNELS_VALUES_WITHDRAW,
    payload,
  }),
  getPchannelsForFee: (payload) => ({
    type: actions.GET_PCHANNELS_FOR_FEE,
    payload,
  }),
  resetPchannelsValues: () => ({
    type: actions.RESET_PCHANNELS_VALUE,
  }),
  validateActivationCode: (payload) => ({
    type: actions.VALIDATE_ACTIVATION_CODE,
    payload,
  }),
  fetchCcRegistration: (payload) => ({
    type: actions.FETCH_CC_REGISTRATION_STATUS,
    payload,
  }),
  saveSearchedMerchant: (payload) => ({
    type: actions.SAVE_SEARCHED_MERCHANT,
    payload: payload,
  }),
  fetchMerchantStore: (payload) => ({
    type: actions.FETCH_MERCHANT_STORE,
    payload,
  }),
  fetchMerchantStoreInventory: (payload) => ({
    type: actions.FETCH_MERCHANT_STORE_INVENTORY,
    payload,
  }),
  resetMerchantStoreInventory: () => ({
    type: actions.RESET_FETCH_MERCHANT_STORE_INVENTORY,
  }),
  updateCourierSetting: (payload) => ({
    type: actions.UPDATE_COURIER_SETTING,
    payload,
  }),
  resetCourierSettingMessage: () => ({
    type: actions.RESET_UPDATE_COURIER_SETTING,
  }),
  checkCourierIssuePickUp: (payload) => ({
    type: actions.CHECK_COURIER_ISSUE_PICK_UP,
    payload,
  }),
  checkCourierIssueProdSpec: (payload) => ({
    type: actions.CHECK_COURIER_ISSUE_PRODUCT_SPECS,
    payload,
  }),


  getCcDocs: () => ({
    type: actions.GET_CC_DOCS,
  }),
  submitCcDocs: (payload) => ({
    type: actions.POST_CC_DOCS,
    payload: payload,
  }),
  reloadCc: () => ({
    type: actions.RELOAD_CC,
  }),
  resetCcPost: () => ({
    type: actions.RESET_CC_POST,
  }),
  previewCcDocs: (payload) => ({
    type: actions.PREVIEW_CC_DOCS,
    payload: payload,
  }),
  resetCcPreview: () => ({
    type: actions.RESET_CC_PREVIEW,
  }),
  askForCcReupload: (payload) => ({
    type: actions.ASK_FOR_CC_REUPLOAD,
    payload: payload,
  }),
  resetAskCcReupload: () => ({
    type: actions.RESET_ASK_CC_REUPLOAD,
  }),
  approveCcDocuments: () => ({
    type: actions.APPROVE_CC_DOCS,
  }),
  resetCcApproving: () => ({
    type: actions.RESET_CC_APPROVING,
  }),
  resetCcMqtt: () => ({
    type: actions.RESET_CC_MQTT,
  }),
  fetchMerchantWallet: (payload) => ({
    type: actions.FETCH_MERCHANT_WALLET,
    payload,
  }),
  resetFetchMerchantWallet: () => ({
    type: actions.RESET_FETCH_MERCHANT_WALLET,
  }),
  fetchFormBuilder: (payload) => ({
    type: actions.FETCH_FORM_BUILDER,
    payload: payload,
  }),
  fetchServiceableQuery: (payload) => ({
    type: actions.FETCH_SERVICEABLE_QUERY,
    payload
  }),
  fetchRollingReserveSettings: () => ({
    type: actions.FETCH_ROLLING_RESERVE_SETTINGS
  }),
};
export default actions;
