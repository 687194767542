import { all } from 'redux-saga/effects';
// import { all, put, takeEvery, fork, select } from 'redux-saga/effects';
// import actions from './actions';
// import { get, post } from '@iso/lib/helpers/resApiRequestor';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';

export default function* rootSaga() {
  yield all([
  ]);
}
