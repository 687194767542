import actions from "./actions";

const initialState = {
    encryptReqIdSuccess: null,
    encryptReqIdLoading: false,
    encryptReqIdFailed: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.ENCRYPT_REQ_ID_SUCCESS:
            return {
                ...state,
                encryptReqIdSuccess: action.payload,
                encryptReqIdLoading: false,
                encryptReqIdFailed: null,
            };
        case actions.ENCRYPT_REQ_ID_LOADING:
            return {
                ...state,
                encryptReqIdSuccess: null,
                encryptReqIdLoading: true,
                encryptReqIdFailed: null,
            };
        case actions.ENCRYPT_REQ_ID_FAILED:
            return {
                ...state,
                encryptReqIdSuccess: null,
                encryptReqIdLoading: false,
                encryptReqIdFailed: action.payload,
            };
        default:
            return state;
    }
}