import actions from './actions';
import authActions from '../auth/actions';

const initState = {
  merchantLoading: false,
  merchantInfo: null,
  merchantErrorMessage: null,
  merchantLogo: null,
  loadingPchannel: false,
  merchantPchannel: null,
  merchantPchannelTopup: null,
  bankAccount: null,
  // MERCHANT USERS
  merchantUsers: null,
  merchantUsersPagination: null,
  selectedMerchantUser: null,
  merchantUserUpdatedData: null,
  addMechantUserError: null,
  addMechantUserSuccess: false,
  editMechantUserSuccess: false,
  addingMerchant: false,
  loadingFilteredRole: false,
  filteredRole: null,
  payloadCore: null,
  selectedId: null,
  rolePermission: null,
  openRolePermissionDialog: false,
  updatingMerchantData: false,
  updateMerchantError: null,
  merchantUserDeleted: false,
  merchantUserPasswordReset: false,
  fetchingMerchantUsers: false,
  loadingMoreMerchantUsers: false,
  allPchannels: null,
  gettingAllPchannels: false,
  updatingMerchantDetails: false,
  updatedMerchantDetails: false,
  updatedMessage: null,
  merchantStores: null,
  downloadingHistory: false,
  downloadMessage: null,
  downloadData: null,
  downloadingStoreTxn: false,
  downloadStoreTxnMessage: null,
  downloadTransaction: null,
  downloadStoreId: null,
  riskProfileData: null,
  gettingKyc: false,
  kycQualified: null,
  kycApplication: null,
  kycDocs: [],
  kycRequirements: [],
  postingKyc: false,
  postedKyc: false,
  previewingKycDocs: false,
  previewedKycDocs: null,
  mqttResult: false,
  referralLinkdata: null,
  merchLogo: [],
  uploadingLogo: false,
  uploadedLogo: false,
  uploadLogoMessage: null,
  reuploadAsking: false,
  reuploadAsked: false,
  reuploadAskingResult: null,
  approvingKycDocs: false,
  approvedKycDocs: false,
  approvingResult: null,
  updatingMerchantChannels: false,
  updatedMerchantChannels: false,
  updatedMerchChannelMsg: null,
  redirectFetchingImg: false,
  redirectImg: null,
  redirectStoreChannels: null,

  // Maintenance Payment Channels 
  fetchMaintenancePchannelsSuccess: null,
  fetchMaintenancePchannelsLoading: false,
  fetchMaintenancePchannelsFailed: null,

  redirectGrabToken: null,
  redirectErrorMessage: null,
  redirectFetchingData: false,
  redirectCustomerData: null,
  redirectPaymentRequesting: false,
  redirectPaymentRequested: null,
  redirectPaymentMessage: null,
  redirectPaymentSuccess: false,
  resettingMerchantUserPassword: false,
  merchantPasswordResetData: null,
  newMerchantUserPasswordReset: false,
  resettingTokenData: false,
  hasResetTokenData: null,
  redirectStoreInfo: null,
  redirectMonthlyLimit: null,
  redirectReachLimit: null,
  redirectStoreTemplate: null,
  mqttKycQualified: null,
  mqttKycApplication: null,
  mqttKycDocs: [],
  resetErrorMessage: null,
  resetError: false,
  fetchingSelectedMerchantSettings: false,
  selectedMerchantSettings: null,
  fetchingOTPStatus: true,
  otpStatusMerchantSettings: null,
  otpMessageMerchantSettings: null,
  updatingMerchantSettings: false,
  updatedMerchantSettings: false,
  webstoreData: null,
  webstoreDataUpdating: false,
  webstoreDataUpdated: false,
  webstoreDataSuccess: null,
  webstoreCatalogUpdated: false,
  webstoreCatalogUpdating: false,
  webstoreUpdateErrorMsg: null,
  webstoreUploadingBanner: false,
  webstoreUploadingBannerErrorMsg: null,
  provinceData: null,
  fetchingProvinceData: false,
  hasUpdatedShippingSetup: false,
  updatingShippingSetup: false,
  updatingShippingSetupFailed: false,
  getAllPchannelValues: null,
  gettingAllPchannelValues: false,
  getAllPchannelValuesWithdraw: null,
  gettingAllPchannelValuesWithdraw: false,
  getAllPchannelForFee: null,
  gettingAllPchannelForFee: false,
  savePchannelsValueFee: null,
  savePchannelsFeeFailed: null,
  savingPchannelsFee: false,
  updatePchannelValueFee: null,
  updatingPchannelValueFee: false,
  updatePchannelValueFeeFailed: null,
  validateActivationCode: null,
  validatingActivationCode: false,
  fetchingCcRegistration: false,
  ccRegistrationData: null,
  submerchants: null,
  fetchingSubmerchants: false,
  fetchedMerchantStore: [],
  fetchingMerchantStore: false,
  fetchMerchantStoreFail: null,
  ccApplication: null,
  gettingCc: false,
  ccDocs: [],
  ccRequirements: [],
  postingCc: false,
  postedCc: false,
  previewingCcDocs: false,
  previewedCcDocs: null,
  mqttCcResult: false,
  reuploadAskingCc: false,
  reuploadAskedCc: false,
  reuploadAskingResultCc: null,
  approvingCcDocs: false,
  approvedCcDocs: false,
  failedMessage: null,
  mqttCcApplication: null,
  mqttCcDocs: [],
  resetCcErrorMessage: null,
  resetCcError: false,

  savedSearchMerchant: '',

  fetchedMerchantStoreInventory: [],
  fetchingMerchantStoreInventory: false,
  fetchMerchantStoreInventoryFail: null,

  updatingCourierSetting: false,
  updatedCourierSetting: false,
  updateCourierSettingMessage: null,

  courierHaveIssuePickUp: false,
  courierHaveIssueProdSpec: false,

  fetchedMerchantWallet: null,
  fetchingMerchantWallet: false,
  fecthMerchantWalletFail: null,
  formTemplate: null,
  fetchingFormTemplate: false,

  fetchingServiceableQuery: false,
  fetchedServiceableQuery: null,
  fetchServiceableQueryFailed: null,

  rollingReserveSettings: null,
  rollingReserveSettingsLoading: false,
  rollingReserveSettingsFailed: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.LOAD_MERCHANT:
      return initState;
    case actions.LOADING_MERCHANT_INFO:
      return {
        ...state,
        merchantLoading: true,
        merchantInfo: null,
      };
    case actions.MERCHANT_INFO_LOADED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: action.merchantInfo,
        merchantLogo: action.merchantLogo,
        bankAccount: action.bankAccount,
      };
    case actions.LOAD_MERCHANT_INFO_FAILED:
      return {
        ...state,
        merchantLoading: false,
        merchantInfo: null,
        merchantErrorMessage: '', // payload err here
      };
    //PCHANNEL
    // case actions.LOAD_MERCHANT_PCHANNEL:
    //   return initState;
    case actions.LOADING_MERCHANT_PCHANNEL:
      return {
        ...state,
        loadingPchannel: true,
      };
    case actions.MERCHANT_PCHANNEL_LOADED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: action.merchantPchannel,
        merchantPchannelTopup: action.merchantPchannelTopup,
      };
    case actions.LOAD_MERCHANT_PCHANNEL_FAILED:
      return {
        ...state,
        loadingPchannel: false,
        merchantPchannel: null,
      };
    case actions.MERCHANT_USERS_FETCHED:
      return {
        ...state,
        merchantUsers: action.merchantUsersData,
        merchantUsersPagination: action.pagination,
        fetchingMerchantUsers: false,
        loadingMoreMerchantUsers: false,
      };
    case actions.FETCHING_MERCHANT_USERS:
      return {
        ...state,
        fetchingMerchantUsers: true,
        selectedMerchantUser: null,
        merchantUsers: null,
      };
    case actions.LOADING_MORE_MERCHANT_USERS:
      return {
        ...state,
        loadingMoreMerchantUsers: true,
      };
    case actions.SET_MERCHANT_USER_DATA:
      return {
        ...state,
        selectedMerchantUser: action.payload,
      };
    case actions.ADD_MERCHANT_USER_FAILED:
      return {
        ...state,
        addMechantUserError: action.payload,
        addingMerchant: false,
        addMechantUserSuccess: false,
      };
    case actions.MERCHANT_USER_ADDED:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: true,
      };
    case actions.FILTERED_ROLE_FETCHED:
      return {
        ...state,
        filteredRole: action.payload,
        loadingFilteredRole: false,
      };
    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };
    // case actions.OPEN_ROLE_PERMISSION_DIALOG:
    //   return {
    //     ...state,
    //     openRolePermissionDialog: !state.openRolePermissionDialog,
    //   };
    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };
    case actions.FETCHING_ALL_FILTERED_ROLE:
      return {
        ...state,
        loadingFilteredRole: true,
      };
    case actions.RESET_ADD_USER_FORM:
      return {
        ...state,
        addingMerchant: false,
        addMechantUserSuccess: false,
        addMechantUserError: null,
      };
    case actions.UPDATING_MERCHANT_USER:
      return {
        ...state,
        updatingMerchantData: true,
      };
    case actions.RESET_EDIT_USER_FORM:
      return {
        ...state,
        editUserForm: false,
        editMechantUserSuccess: false,
        updateMerchantError: null,
      };
    case actions.ADDING_MERCHANT_USER:
      return {
        ...state,
        addingMerchant: true,
      };
    case actions.UPDATE_MERCHANT_USER_FAILED:
      return {
        ...state,
        updateMerchantError: action.updateError,
        editMechantUserSuccess: false,
      };
    case actions.UPDATE_MERCHANT_USER_SUCCESS:
      return {
        ...state,
        editMechantUserSuccess: true,
        updatingMerchantData: false,
      };
    case actions.MERCHANT_USER_DEACTIVATED:
      return {
        ...state,
        merchantUserDeleted: true,
      };
    case actions.DEACTIVATE_MERCHANT_USER_RESET:
      return {
        ...state,
        merchantUserDeleted: false,
      };
    case actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantUserPasswordReset: true,
        resettingMerchantUserPassword: false
      };
    case actions.RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        merchantUserPasswordReset: false,
      };
    case actions.MERCHANT_USER_UPDATED:
      const updatedUser = action.merchantUserUpdatedData;
      const roleLabel = updatedUser.role.role.label;
      const merchantUsersList = state.merchantUsers;
      let updatedSelectedUser;
      for (let i = 0; i < merchantUsersList.length; i++) {
        const element = merchantUsersList[i];
        if (updatedUser.Id === element.Id) {
          merchantUsersList[i].walletLink = action.walletLink
          merchantUsersList[i].firstName = updatedUser.firstName;
          merchantUsersList[i].lastName = updatedUser.lastName;
          merchantUsersList[i].email = updatedUser.email;
          merchantUsersList[i].roleId = updatedUser.roleId;
          merchantUsersList[i].role = updatedUser.role;
          merchantUsersList[i].storeId = updatedUser.storeId;
          merchantUsersList[i].store = roleLabel === 'Merchant Admin' ? action.stores : updatedUser.store;
          updatedSelectedUser = merchantUsersList[i];
        }
      }
      Object.assign(state.selectedMerchantUser, updatedSelectedUser);
      return {
        ...state,
        merchantUsers: merchantUsersList,
        selectedMerchantUser: {
          ...state.selectedMerchantUser,
          updatedSelectedUser,
        },
      };
    case actions.GETTING_ALL_PCHANNELS:
      return {
        ...state,
        gettingAllPchannels: true,
        allPchannels: null,
      };
    case actions.GET_ALL_PCHANNELS_SUCCESS:
      return {
        ...state,
        allPchannels: action.payload,
        gettingAllPchannels: false,
      };
    case actions.GET_ALL_PCHANNELS_FAILED:
      return {
        ...state,
        allPchannels: null,
        gettingAllPchannels: false,
      };
    case actions.EDITING_MERCHANT_DETAILS:
      return {
        ...state,
        updatingMerchantDetails: true,
      };
    case actions.MERCHANT_DETAILS_EDITED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Successfully updated merchant details',
        merchantInfo: action.result.merchantDetails,
      };
    case actions.EDIT_MERCHANT_DETAILS_FAILED:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: true,
        updatedMessage: 'Failed to update merchant details',
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        updatingMerchantDetails: false,
        updatedMerchantDetails: false,
        updatedMessage: null,
      };
    case actions.DOWNLOADING_HISTORY:
      return {
        ...state,
        downloadingHistory: true,
      };
    case actions.DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: action.data,
      };
    case actions.RESET_DOWNLOAD_HISTORY:
      return {
        ...state,
        downloadingHistory: false,
        downloadData: null,
        downloadMessage: null,
      };
    case actions.DOWNLOAD_HISTORY_FAILED:
      return {
        ...state,
        downloadingHistory: false,
        downloadMessage: action.message,
      };
    // DOWNLOAD STORE TXN
    case actions.DOWNLOADING_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: true,
        downloadStoreId: action.result,
      };
    case actions.DOWNLOAD_STORE_TXN_SUCCESS:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: action.data,
      };
    case actions.RESET_DOWNLOADED_STORE_TXN:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadTransaction: null,
        downloadStoreTxnMessage: null,
        downloadStoreId: null,
      };
    case actions.DOWNLOAD_STORE_TXN_FAILED:
      return {
        ...state,
        downloadingStoreTxn: false,
        downloadStoreTxnMessage: action.message,
      };
    case actions.FETCHING_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        riskProfileData: action.riskProfileData,
      };
    case actions.GETTING_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: true,
        kycDocs: [],
        kycRequirements: [],
        kycQualified: null,
        kycApplication: null,
      };
    case actions.GOT_KYC_QUALIFIED:
      return {
        ...state,
        gettingKyc: false,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        kycRequirements: action.requirements,
      };
    case actions.GETTING_KYC_QUALIFIED_FAILED:
      return {
        ...state,
        gettingKyc: false,
      };
    case actions.POSTING_KYC_DOCS:
      return {
        ...state,
        postingKyc: true,
      };
    case actions.UPDATED_KYC_DOCS:
      return {
        ...state,
        postingKyc: false,
      };
    case actions.POSTED_KYC_DOCS:
      return {
        ...state,
        postingKyc: false,
        postedKyc: true,
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
        mqttResult: false,
      };
    case actions.MQTT_POSTING_RESULT:
      return {
        ...state,
        mqttResult: true,
      };
    case actions.RESET_KYC_POST:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.POSTING_KYC_DOCS_FAILED:
      return {
        ...state,
        postingKyc: false,
        postedKyc: false,
        mqttResult: false,
      };
    case actions.PREVIEWING_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: true,
      };
    case actions.PREVIEWED_KYC_DOCS:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: action.data,
      };
    case actions.PREVIEWING_KYC_DOCS_FAILED:
      return {
        ...state,
        previewingKycDocs: false,
      };
    case actions.RESET_KYC_PREVIEW:
      return {
        ...state,
        previewingKycDocs: false,
        previewedKycDocs: null,
      };
    case actions.FETCH_MERCHANT_REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        referralLinkdata: action.referralLinkdata,
      };
    // UPLOAD LOGO
    case actions.UPLOADING_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: true,
      };
    case actions.UPLOADED_MERCHANT_LOGO:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: true,
        merchLogo: action.data ? action.data.merchLogo : [],
        // merchantLogo: action.merchantLogo,
        uploadLogoMessage: action.message,
      };
    case actions.RESET_MERCHANT_LOGO_UPLOAD:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: null,
      };
    case actions.UPLOAD_MERCHANT_LOGO_FAILED:
      return {
        ...state,
        uploadingLogo: false,
        uploadedLogo: false,
        uploadLogoMessage: action.message,
      };
    case actions.ASKING_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: true,
      };
    case actions.ASKED_FOR_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
        reuploadAskingResult: 'Reupload request sent',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.ASKING_FOR_REUPLOAD_FAILED:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: true,
      };
    case actions.RESET_ASK_REUPLOAD:
      return {
        ...state,
        reuploadAsking: false,
        reuploadAsked: false,
        reuploadAskingResult: null,
      };
    case actions.APPROVING_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: true,
      };
    case actions.APPROVED_KYC_DOCS:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
        approvingResult: 'KYC documents approved',
        kycQualified: action.data ? action.data.kycQualified : null,
        kycApplication: action.data ? action.data.kycApplication : null,
        kycDocs: action.data ? action.data.kycDocs : [],
      };
    case actions.APPROVING_KYC_DOCS_FAILED:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: true,
      };
    case actions.RESET_APPROVING:
      return {
        ...state,
        approvingKycDocs: false,
        approvedKycDocs: false,
        approvingResult: null,
      };
    // UPDATE MERCHANT CHANNELS
    case actions.UPDATING_MERCHANT_CHANNELS:
      return {
        ...state,
        updatingMerchantChannels: true,
      };
    case actions.MERCHANT_CHANNELS_UPDATED:
      return {
        ...state,
        merchantPchannel: action.result,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
        // merchantInfo: action.result.merchantDetails,
      };
    case actions.UPDATE_MERCHANT_CHANNELS_FAILED:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: true,
        updatedMerchChannelMsg: action.message,
      };
    case actions.RESET_UPDATE_MERCHANT_CHANNELS:
      return {
        ...state,
        updatingMerchantChannels: false,
        updatedMerchantChannels: false,
        updatedMerchChannelMsg: null,
      };
    case actions.FETCHING_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: true,
      };
    case actions.FETCHED_MERCHANT_IMAGE:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: action.merchantLogo,
        redirectStoreChannels: action.storeChannels,
        redirectGrabToken: action.grabToken,
        redirectStoreInfo: action.storeInfo,
        redirectMonthlyLimit: action.monthlyLimit,
        redirectStoreTemplate: action.storeTemplate
        // redirectReachLimit: action.reachLimit,
      };
    // MAINTENANCE REDUCER
    case actions.FETCH_MAINTENANCE_PCHANNELS_SUCCESS:
      return {
        ...state,
        fetchMaintenancePchannelsSuccess: action.payload,
        fetchMaintenancePchannelsLoading: false,
        fetchMaintenancePchannelsFailed: null,
      }
    case actions.FETCH_MAINTENANCE_PCHANNELS_LOADING:
      return {
        ...state,
        fetchMaintenancePchannelsLoading: true
      }
    case actions.FETCH_MAINTENANCE_PCHANNELS_FAILED:
      return {
        ...state,
        fetchMaintenancePchannelsSuccess: null,
        fetchMaintenancePchannelsLoading: false,
        fetchMaintenancePchannelsFailed: action.payload,
      }
    case actions.FETCH_MAINTENANCE_PCHANNELS_RESET:
      return {
        ...state,
        fetchMaintenancePchannelsSuccess: null,
        fetchMaintenancePchannelsLoading: false,
        fetchMaintenancePchannelsFailed: null,
      }
    case actions.FETCHING_FORM_BUILDER:
      return {
        ...state,
        fetchingFormTemplate: true,
        formTemplate: null,
      };
    case actions.FETCHED_FORM_BUILDER:
      return {
        ...state,
        fetchingFormTemplate: false,
        formTemplate: action.payload,
      };
    case actions.FETCH_FORM_BUILDER_FAILED:
      return {
        ...state,
        fetchingFormTemplate: false,
        formTemplate: null,
      };
    case actions.FETCH_MERCHANT_IMAGE_FAILED:
      return {
        ...state,
        redirectFetchingImg: false,
        redirectImg: null,
        redirectStoreChannels: null,
        redirectGrabToken: null,
        redirectErrorMessage: action.payload,
        redirectStoreInfo: null,
        redirectMonthlyLimit: null,
        // redirectReachLimit: null,
        redirectStoreTemplate: null

      };
    case actions.FETCHING_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: true,
      };
    case actions.FETCHED_CUSTOMER_DATA:
      return {
        ...state,
        redirectFetchingData: false,
        redirectCustomerData: action.payload,
      };
    case actions.FETCH_CUSTOMER_DATA_FAILED:
      return {
        ...state,
        redirectFetchingData: false,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTING:
      return {
        ...state,
        redirectPaymentRequesting: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUESTED:
      return {
        ...state,
        // redirectPaymentRequesting: false,
        redirectPaymentRequested: action.payload,
        redirectPaymentSuccess: true,
      };
    case actions.PAYMENT_TRANSACTION_REQUEST_FAILED:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: action.payload,
        redirectPaymentSuccess: false,
      };
    case actions.RESET_PAYMENT_TRANSACTION_REQUEST:
      return {
        ...state,
        redirectPaymentRequesting: false,
        redirectPaymentMessage: null,
        redirectPaymentRequested: null,
        redirectPaymentSuccess: false,
      };
    case actions.RESETTING_TOKEN_DATA:
      return {
        ...state,
        resettingTokenData: true,
      };
    case actions.RESET_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: action.result,
      };
    case actions.RESET_TOKEN_DATA_FAILED:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: null,
      };
    case actions.NEW_RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        resettingMerchantUserPassword: true,
      };
    case actions.RESETTING_MERCHANT_USER_PASSWORD:
      return {
        ...state,
        resettingMerchantUserPassword: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        merchantPasswordResetData: action.result,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_RESET_MERCHANT_USER_PASSWORD_RESET:
      return {
        ...state,
        newMerchantUserPasswordReset: true,
      };
    case actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED:
      return {
        ...state,
        resetErrorMessage: `Couldn't reset password. Please contact Administrator`,
        resetError: true,
        resettingMerchantUserPassword: false,
        newMerchantUserPasswordReset: false,
      };
    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        resetErrorMessage: action.payload,
        resetError: action.isError,
      };
    case actions.KYC_MQTT:
      return {
        ...state,
        mqttKycQualified: action.kycQualified ? action.kycQualified : null,
        mqttKycApplication: action.kycApplication ? action.kycApplication : null,
        mqttKycDocs: action.kycDocs ? action.kycDocs : [],
      };
    case actions.RESET_KYC_MQTT:
      return {
        ...state,
        mqttKycQualified: null,
        mqttKycApplication: null,
        mqttKycDocs: [],
      };
    case actions.FETCHING_MERCHANT_SETTINGS:
      return {
        ...state,
        fetchingSelectedMerchantSettings: true,
      };
    case actions.FETCHED_MERCHANT_SETTINGS:
      return {
        ...state,
        fetchingSelectedMerchantSettings: false,
        selectedMerchantSettings: action.data,
      };
    case actions.SENDING_OTP:
      // const newData = action.data;
      return {
        ...state,
        fetchingOTPStatus: true,
      };
    case actions.SEND_OTP_SUCCESS:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: 'Success',
        fetchingOTPStatus: false,
      };
    case actions.SEND_OTP_FAILED:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: 'Failed',
        fetchingOTPStatus: false,
      };
    case actions.SEND_OTP_RESET:
      // const newData = action.data;
      return {
        ...state,
        otpStatusMerchantSettings: null,
        fetchingOTPStatus: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS:
      // const newData = action.data;
      return {
        ...state,
        otpMessageMerchantSettings: null,
        updatingMerchantSettings: true,
      };
    case actions.UPDATED_MERCHANT_SETTINGS:
      // const newData = action.data;
      return {
        ...state,
        updatedMerchantSettings: true,
        merchantInfo: action.data,
        updatingMerchantSettings: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS_FAILED:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: action.message,
        updatingMerchantSettings: false,
      };
    case actions.UPDATING_MERCHANT_SETTINGS_ERROR:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: action.message,
        updatingMerchantSettings: false,
      };
    case actions.RESET_UPDATE_MERCHANT_SETTINGS:
      return {
        ...state,
        updatedMerchantSettings: false,
        otpMessageMerchantSettings: null,
      };
    case actions.FETCHING_MERCHANT_SETTINGS_FAILED:
      return {
        ...state,
        fetchingSelectedMerchantSettings: false,
        selectedMerchantSettings: null,
      };
    // FETCH STORE DATA
    case actions.FETCH_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
      };
    case actions.FETCH_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreData: null,
      };
    // SAVE STORE DATA
    case actions.SAVE_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
      };
    case actions.SAVE_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreData: null,
      };
    // UPLOAD STORE BANNER
    case actions.UPLOADING_WEBSTORE_BANNER:
      return {
        ...state,
        webstoreUploadingBanner: true,
      };
    case actions.UPLOAD_WEBSTORE_BANNER_SUCCESS:
      return {
        ...state,
        webstoreData: {
          ...state.webstoreData,
          bannerPhoto: action.data.bannerPhoto,
          ogBanner: action.data.ogBanner,
        },
        webstoreUploadingBanner: false,
      };
    case actions.UPLOAD_WEBSTORE_BANNER_FAILED:
      return {
        ...state,
        webstoreUploadingBanner: false,
        webstoreUploadingBannerErrorMsg: action.message,
      };
    // UPDATE STORE DATA
    case actions.UPDATING_WEBSTORE_DATA:
      return {
        ...state,
        webstoreDataUpdating: true,
        webstoreDataSuccess: null,
      };
    case actions.UPDATE_WEBSTORE_DATA_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
        webstoreDataSuccess: action.data,
        webstoreDataUpdated: true,
        webstoreDataUpdating: false,
      };
    case actions.RESET_WEBSTORE_DATA:
      return {
        ...state,
        webstoreDataUpdated: false,
        webstoreDataSuccess: null,
        webstoreUpdateErrorMsg: null,
      };
    case actions.UPDATE_WEBSTORE_DATA_FAILED:
      return {
        ...state,
        webstoreUpdateErrorMsg: action.message,
        webstoreDataUpdating: false,
      };

    // WEBSTORE CATALOG

    case actions.UPDATING_WEBSTORE_CATALOG:
      return {
        ...state,
        webstoreCatalogUpdating: true,
      };
    case actions.UPDATE_WEBSTORE_CATALOG_SUCCESS:
      return {
        ...state,
        webstoreData: action.data,
        webstoreCatalogUpdated: true,
        webstoreCatalogUpdating: false,
      };

    case actions.UPDATE_WEBSTORE_CATALOG_FAILED:
      return {
        ...state,
        webstoreCatalogUpdated: false,
        webstoreCatalogUpdating: false,
      };
    case actions.RESET_WEBSTORE_CATALOG:
      return {
        ...state,
        webstoreCatalogUpdated: false,
        webstoreCatalogUpdating: false,
        // FETCH PROVINCE DATA
      }
    case actions.FETCHING_PROVINCE:
      return {
        ...state,
        fetchingProvinceData: true,
      };
    case actions.FETCH_PROVINCE_SUCCESS:
      return {
        ...state,
        provinceData: action.provinces,
        fetchingProvinceData: false,
      };
    case actions.FETCH_PROVINCE_RESET:
      return {
        ...state,
        fetchingProvinceData: false,
      };
    case actions.FETCH_PROVINCE_FAILED:
      return {
        ...state,
        // provinceData: action.message,
        fetchingProvinceData: false,
      };
    // UPDATE SHIPPING SETUP
    case actions.UPDATING_SHIPPING_SETUP:
      return {
        ...state,
        updatingShippingSetup: true,
      };
    case actions.UPDATE_SHIPPING_SETUP_SUCCESS:
      return {
        ...state,
        provinceData: action.provinces,
        updatingShippingSetup: false,
        hasUpdatedShippingSetup: true,
      };
    case actions.UPDATE_SHIPPING_SETUP_RESET:
      return {
        ...state,
        updatingShippingSetup: false,
        hasUpdatedShippingSetup: false,
        updatingShippingSetupFailed: false,
      };
    case actions.UPDATE_SHIPPING_SETUP_FAILED:
      return {
        ...state,
        // provinceData: action.message,
        updatingShippingSetup: false,
        updatingShippingSetupFailed: true,
      };
    case actions.SENDING_EMAIL_LIMIT_WARNING:
      return {
        ...state,
        sendingEmail: true,
      };
    case actions.SENT_EMAIL_LIMIT_WARNING:
      return {
        ...state,
        // provinceData: action.message,
        sendEmailStatus: action.payload,
        sendingEmail: false,
      };
    case actions.SEND_EMAIL_LIMIT_WARNING_FAILED:
      return {
        ...state,
        sendingEmail: false,
      };
    case actions.GETTING_PCHANNELS_VALUES:
      return {
        ...state,
        gettingAllPchannelValues: true,
        getAllPchannelValues: null,
      };
    case actions.GETTING_PCHANNELS_VALUES_SUCCESS:
      return {
        ...state,
        getAllPchannelValues: action.payload,
        gettingAllPchannelValues: false,
      };
    case actions.GETTING_PCHANNELS_VALUES_FAILED:
      return {
        ...state,
        getAllPchannelValues: null,
        gettingAllPchannelValuesf: false,
      };
    case actions.SAVING_PCHANNEL_FEE:
      return {
        ...state,
        savingPchannelsFee: true,
        savePchannelsValueFee: null,
      };
    case actions.SAVE_PCHANNEL_FEE_SUCCESS:
      return {
        ...state,
        savePchannelsValueFee: action.data,
        savingPchannelsFee: false,
      };
    case actions.SAVE_PCHANNEL_FEE_FAILED:
      return {
        ...state,
        savePchannelsFeeFailed: 'Failed to Save Fee',
        savePchannelsValueFee: null,
        savingPchannelsFee: false,
      };
    case actions.RESET_PCHANNELS_VALUE:
      return {
        ...state,
        getAllPchannelValues: null,
      };
    case actions.UPDATING_PCHANNELS_FEE:
      return {
        ...state,
        updatingPchannelValueFee: true,
        updatePchannelValueFee: null,
      };
    case actions.UPDATING_PCHANNELS_FEE_SUCCESS:
      return {
        ...state,
        updatePchannelValueFee: action.data,
        updatingPchannelValueFee: false,
      };
    case actions.UPDATING_PCHANNELS_FEE_FAILED:
      return {
        ...state,
        updatePchannelValueFeeFailed: 'Failed to Save Fee',
        updatePchannelValueFee: null,
        updatingPchannelValueFee: false,
      };
    case actions.GETTING_PCHANNELS_FOR_FEE:
      return {
        ...state,
        gettingAllPchannelForFee: true,
        getAllPchannelForFee: null,
      };
    case actions.GETTING_PCHANNELS_FOR_FEE_SUCCESS:
      return {
        ...state,
        getAllPchannelForFee: action.payload,
        gettingAllPchannelForFee: false,
      };
    case actions.GETTING_PCHANNELS_FOR_FEE_FAILED:
      return {
        ...state,
        getAllPchannelForFee: null,
        gettingAllPchannelForFee: false,
      };
    case actions.RESET_SAVE_PCHANNEL_FEE:
      return {
        ...state,
        savePchannelsFeeFailed: null,
        savePchannelsValueFee: null,
        updatePchannelValueFee: null,
        updatePchannelValueFeeFailed: null,
      };
    case actions.GETTING_PCHANNELS_VALUES_WITHDRAW:
      return {
        ...state,
        gettingAllPchannelValuesWithdraw: true,
        getAllPchannelValuesWithdraw: null,
      };
    case actions.GETTING_PCHANNELS_VALUES_WITHDRAW_SUCCESS:
      return {
        ...state,
        getAllPchannelValuesWithdraw: action.payload,
        gettingAllPchannelValuesWithdraw: false,
      };
    case actions.GETTING_PCHANNELS_VALUES_WITHDRAW_FAILED:
      return {
        ...state,
        getAllPchannelValuesWithdraw: null,
        gettingAllPchannelValuesfWithdraw: false,
      };
    case actions.VALIDATING_ACTIVATION_CODE:
      return {
        ...state,
        validateActivationCode: null,
        validatingActivationCode: true,
      };
    case actions.VALIDATE_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        validateActivationCode: action.payload,
        validatingActivationCode: false,
      };
    case actions.VALIDATE_ACTIVATION_CODE_FAILED:
      return {
        ...state,
        validatingActivationCode: false,
      };
    case actions.FETCHING_CC_REGISTRATION_STATUS:
      return {
        ...state,
        ccRegistrationData: null,
        fetchingCcRegistration: true,
      };
    case actions.FETCHING_CC_REGISTRATION_SUCCESS:
      return {
        ...state,
        ccRegistrationData: action.payload,
        fetchingCcRegistration: false,
      };
    case actions.FETCHING_CC_REGISTRATION_FAILED:
      return {
        ...state,
        fetchingCcRegistration: false,
      };
    case actions.SAVE_SEARCHED_MERCHANT:
      return {
        ...state,
        savedSearchMerchant: action.payload
      };
    case actions.FETCHED_SUBMERCHANTS:
      return {
        ...state,
        submerchants: action.payload,
        fetchingSubmerchants: false,
      };
    case actions.FETCHING_SUBMERCHANTS:
      return {
        ...state,
        fetchingSubmerchants: true,
      };
    case actions.FETCH_SUBMERCHANTS_FAILED:
      return {
        ...state,
        submerchants: null,
        fetchingSubmerchants: false,
      };
    case actions.FETCHED_MERCHANT_STORE:
      return {
        ...state,
        fetchedMerchantStore: action.payload,
        fetchingMerchantStore: false
      };
    case actions.FETCHING_MERCHANT_STORE:
      return {
        ...state,
        fetchingMerchantStore: true
      };
    case actions.FETCH_MERCHANT_STORE_FAILED:
      return {
        ...state,
        fetchMerchantStoreFail: action.payload,
        fetchingMerchantStore: false
      };
    case actions.FETCHED_MERCHANT_STORE_INVENTORY:
      return {
        ...state,
        fetchedMerchantStoreInventory: action.payload,
        fetchingMerchantStoreInventory: false
      };
    case actions.FETCHING_MERCHANT_STORE_INVENTORY:
      return {
        ...state,
        fetchingMerchantStoreInventory: true
      };
    case actions.FETCH_MERCHANT_STORE_INVENTORY_FAILED:
      return {
        ...state,
        fetchMerchantStoreInventoryFail: action.payload,
        fetchingMerchantStoreInventory: false
      };
    case actions.RESET_FETCH_MERCHANT_STORE_INVENTORY:
        return {
          ...state,
          fetchedMerchantStoreInventory: [],
          fetchingMerchantStoreInventory: false,
          fetchMerchantStoreInventoryFail: null,
        };
    //
    case actions.UPDATING_COURIER_SETTING:
      return {
        ...state,
        updatingCourierSetting: true,
      };
    case actions.UPDATED_COURIER_SETTING:
      return {
        ...state,
        updatingCourierSetting: false,
        updatedCourierSetting: true,
        updateCourierSettingMessage: action.message,
      };
    case actions.RESET_UPDATE_COURIER_SETTING:
      return {
        ...state,
        updatingCourierSetting: false,
        updatedCourierSetting: false,
        updateCourierSettingMessage: null,
      };
    case actions.UPDATE_COURIER_SETTING_FAILED:
      return {
        ...state,
        updatingCourierSetting: false,
        updatedCourierSetting: false,
        updateCourierSettingMessage: action.message,
      }
    case actions.GETTING_CC_DOCS:
      return {
        ...state,
        gettingCc: true,
        ccDocs: [],
        ccRequirements: [],
        ccApplication: null,
      };
    case actions.GOT_CC_DOCS:
      return {
        ...state,
        gettingCc: false,
        ccApplication: action.data ? action.data.ccApplication : null,
        ccDocs: action.data ? action.data.ccDocs : [],
        ccRequirements: action.ccRequirements
      };
    case actions.GETTING_CC_DOCS_FAILED:
      return {
        ...state,
        gettingCc: false,
      };
    // CC DOCS
    case actions.POSTING_CC_DOCS:
      return {
        ...state,
        postingCc: true,
      };
    case actions.UPDATED_CC_DOCS:
      return {
        ...state,
        postingCc: false,
      };
    case actions.POSTED_CC_DOCS:
      return {
        ...state,
        postingCc: false,
        postedCc: true,
        ccApplication: action.data ? action.data.ccApplication : null,
        ccDocs: action.data ? action.data.ccDocs : [],
        mqttCcResult: false,
      };
    case actions.MQTT_CC_POSTING_RESULT:
      return {
        ...state,
        mqttCcResult: true,
      };
    case actions.RESET_CC_POST:
      return {
        ...state,
        postingCc: false,
        postedCc: false,
        mqttCcResult: false,
      };
    case actions.POSTING_CC_DOCS_FAILED:
      return {
        ...state,
        postingCc: false,
        postedCc: false,
        mqttCcResult: false,
      };
    case actions.PREVIEWING_CC_DOCS:
      return {
        ...state,
        previewingCcDocs: true,
      };
    case actions.PREVIEWED_CC_DOCS:
      return {
        ...state,
        previewingCcDocs: false,
        previewedCcDocs: action.data,
      };
    case actions.PREVIEWING_CC_DOCS_FAILED:
      return {
        ...state,
        previewingCcDocs: false,
      };
    case actions.RESET_CC_PREVIEW:
      return {
        ...state,
        previewingCcDocs: false,
        previewedCcDocs: null,
      };
    case actions.ASKING_FOR_CC_REUPLOAD:
      return {
        ...state,
        reuploadAskingCc: true,
      };
    case actions.ASKED_FOR_CC_REUPLOAD:
      return {
        ...state,
        reuploadAskingCc: false,
        reuploadAskedCc: true,
        reuploadAskingResultCc: 'Reupload request sent',
        ccApplication: action.data ? action.data.ccApplication : null,
        ccDocs: action.data ? action.data.ccDocs : [],
      };
    case actions.ASKING_FOR_CC_REUPLOAD_FAILED:
      return {
        ...state,
        reuploadAskingCc: false,
        reuploadAskedCc: true,
      };
    case actions.RESET_ASK_CC_REUPLOAD:
      return {
        ...state,
        reuploadAskingCc: false,
        reuploadAskedCc: false,
        reuploadAskingResultCc: null,
      };
    case actions.APPROVING_CC_DOCS:
      return {
        ...state,
        approvingCcDocs: true,
      };
    case actions.APPROVED_CC_DOCS:
      return {
        ...state,
        approvingCcDocs: false,
        approvedCcDocs: true,
        approvingResult: 'CC documents approved',
        ccApplication: action.data ? action.data.ccApplication : null,
        ccDocs: action.data ? action.data.ccDocs : [],
      };
    case actions.APPROVING_CC_DOCS_FAILED:
      return {
        ...state,
        approvingCcDocs: false,
        approvedCcDocs: false,
        failedMessage: action.message,
      };
    case actions.RESET_CC_APPROVING:
      return {
        ...state,
        approvingCcDocs: false,
        approvedCcDocs: false,
        approvingResult: null,
        failedMessage: null,
      };
    case actions.RESET_CC_ERROR_MESSAGE:
      return {
        ...state,
        resetCcErrorMessage: action.payload,
        resetCcError: action.isError,
      };
    case actions.CC_MQTT:
      return {
        ...state,
        mqttCcApplication: action.ccApplication ? action.ccApplication : null,
        mqttCcDocs: action.ccDocs ? action.ccDocs : [],
      };
    case actions.RESET_CC_MQTT:
      return {
        ...state,
        mqttCcApplication: null,
        mqttCcDocs: [],
      };
    case actions.CHECK_COURIER_ISSUE_PICK_UP:
      return {
        ...state,
        courierHaveIssuePickUp: action.payload,
    };
    case actions.CHECK_COURIER_ISSUE_PRODUCT_SPECS:
      return {
        ...state,
        courierHaveIssueProdSpec: action.payload,
    };
    // FETCHING MERCHANT WALLET
    case actions.FETCHING_MERCHANT_WALLET:
      return {
        ...state,
        fetchingMerchantWallet: true,
      };
    case actions.FETCHED_MERCHANT_WALLET:
      return {
        ...state,
        fetchedMerchantWallet: action.payload,
        fetchingMerchantWallet: false,
      };
    case actions.FETCH_MERCHANT_WALLET_FAILED:
      return {
        ...state,
        fetchingMerchantWallet: false,
      };
    case actions.RESET_FETCH_MERCHANT_WALLET:
      return {
        ...state,
        fetchedMerchantWallet: null,
        fetchingMerchantWallet: false,
        fecthMerchantWalletFail: null,
      };
    case actions.FETCHING_SERVICEABLE_QUERY:
      return {
        ...state,
        fetchingServiceableQuery: true,
      };
    case actions.FETCHED_SERVICEABLE_QUERY:
      return {
        ...state,
        fetchingServiceableQuery: false,
        fetchedServiceableQuery: action.result,
        fetchServiceableQueryFailed: null,
      };
    case actions.FETCH_SERVICEABLE_QUERY_FAILED:
      return {
        ...state,
        fetchingServiceableQuery: false,
        fetchedServiceableQuery: null,
        fetchServiceableQueryFailed: action.payload,
      };
    case actions.FETCH_ROLLING_RESERVE_SETTINGS_SUCCESS:
      return {
        ...state,
        rollingReserveSettings: action.payload,
        rollingReserveSettingsLoading: false,
        rollingReserveSettingsFailed: null,
      };
    case actions.FETCH_ROLLING_RESERVE_SETTINGS_LOADING:
      return {
        ...state,
        rollingReserveSettingsLoading: true,
      };
    case actions.FETCH_ROLLING_RESERVE_SETTINGS_FAILED:
      return {
        ...state,
        rollingReserveSettingsFailed: action.payload,
        rollingReserveSettingsLoading: false,
        rollingReserveSettings: null,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    default:
      return state;
  }
}