import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { post, postBlob, PUT, get } from '@iso/lib/helpers/resApiRequestor';
// import authAction from '../auth/actions';
import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';
import * as _ from 'lodash';


export function* downloadStoreQr() {
  yield takeEvery('DOWNLOAD_STORE_QR', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_STORE_QR,
        payload: true,
      });
      const apiResult = yield generateStoreQr(payload);
      const result = apiResult.data;
      if (result) {
        // const dlApiResult = yield downloadQr({ filename: result });
        // const dlResult = dlApiResult.data;
        yield put({
          type: actions.DOWNLOADED_STORE_QR,
          // payload: dlResult,
          payload: result,
        });
      }
      yield put({
        type: actions.DOWNLOADING_STORE_QR,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_STORE_QR_FAILED,
      });
    }
  });
}
export function* downloadPlainStoreQr() {
  yield takeEvery('DOWNLOAD_PLAIN_QR', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_PLAIN_QR,
        payload: true,
      });
      const apiResult = yield generatePlainQr(payload);
      const result = apiResult.data;
      if (result) {
        // const dlApiResult = yield downloadQr({ filename: result });
        // const dlResult = dlApiResult.data;
        yield put({
          type: actions.DOWNLOADED_PLAIN_QR,
          // payload: dlResult,
          payload: result,
        });
      }
      yield put({
        type: actions.DOWNLOADING_PLAIN_QR,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_PLAIN_QR_FAILED,
      });
    }
  });
}
export function* saveStore() {
  yield takeEvery('SAVE_STORE', function* ({ payload }) {
    const state = yield select();
    const stores = state.Auth.stores;
    try {
      yield put({
        type: actions.SAVING_STORE,
        payload: true,
      });
      let apiResult;
      let result;
      if (payload.Id) {
        apiResult = yield updatingStore(payload);
      } else {
        apiResult = yield savingStore(payload);
      }
      result = apiResult.data;
      if (result.success) {
        if (payload.Id) {

          const index = stores.findIndex((item, i) => {
            return payload.Id === item.Id;
          });
          stores[index].address = payload.address;
          stores[index].storeName = payload.storeName;
          stores[index].isInventory = payload.isInventory;
          stores[index].isPos = payload.isPos;
          stores[index].isStudent = payload.isStudent;
          stores[index].province = payload.province;
          stores[index].city = payload.city;
          stores[index].street = payload.street;
          stores[index].barangay = payload.barangay;
          stores[index].zipCode = payload.zip;
          stores[index].mobile = payload.mobile;
          stores[index].isEnableCourier = payload.isEnableCourier;
          stores[index].walletId = payload.walletId;
        } else {
          stores.push(result.data);
        }
        yield localStorage.setItem('stores', JSON.stringify(stores));
        // pushDataToApp('stores', stores);
        yield put({
          type: actions.SAVING_STORE,
          payload: false,
        });
      }
      yield put({
        type: actions.SAVED_STORE_MESSAGE,
        payload: result.success ? `Store successfully ${payload.Id ? 'updated' : 'created'}!` : result.message,
        isError: result.success ? false : true,
      });
      // yield put({
      //   type: actions.DIALOG_ADD_ACTION,
      // payload: false,
      // payload: result.success ? false: true,
      // });
    } catch (error) {
      yield put({
        type: actions.SAVE_STORE_FAILED,
      });
    }
  });
}

export function* loadStorePchannels() {
  yield takeEvery('FETCH_STORE_PCHANNEL', function* (store_id) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    try {
      const apiResult = yield fetchStorePchannelForStore(store_id);
      // const apiResult = yield fetchStorePchannel(store_id); // OLD VALUE
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_STORE_PCHANNEL,
      });
      yield put({
        type: actions.FETCHED_STORE_PCHANNEL,
        storePchannel: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_STORE_PCHANNEL_FAILED,
      });
    }
  });
}
export function* loadStorePchannelsNew() {
  yield takeEvery('FETCH_STORE_PCHANNEL_NEW', function* (store_id) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    try {
      const apiResult = yield fetchStorePchannelForStore(store_id);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_STORE_PCHANNEL,
      });
      yield put({
        type: actions.FETCHED_STORE_PCHANNEL,
        storePchannel: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_STORE_PCHANNEL_FAILED,
      });
    }
  });
}

export function* changePchannelStatus() {
  yield takeEvery('CHANGE_STORE_PCHANNEL_STATUS', function* (payload) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    const state = yield select();
    const stores = state.Auth.stores;
    try {
      const apiResult = yield changeStorePchannelStatus(payload);
      const result = apiResult.data;
      if (result.success) {
        const index = stores.findIndex((item, i) => {
          return payload.store_id === item.storeId;
        });
        stores[index] = payload.currentStore[0];
        yield localStorage.setItem('stores', JSON.stringify(stores));
        pushDataToApp('stores', stores);
      }
      yield put({
        type: actions.CHANGING_STORE_PCHANNEL_STATUS,
      });
      yield put({
        type: actions.STORE_PCHANNEL_STATUS_CHANGED,
        storePchannelStatus: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.CHANGE_STORE_PCHANNEL_STATUS_FAILED,
      });
    }
  });
}

export function* deleteStore() {
  yield takeEvery('DELETE_STORE', function* (payload) {
    const state = yield select();
    const stores = state.Auth.stores;
    try {
      yield put({
        type: actions.DELETING_STORE,
      });
      const apiResult = yield storeDelete(payload.payload.storeId);
      if (apiResult.data.success) {
        const updatedStores = _.reject(stores, { storeId: payload.payload.storeId });
        yield localStorage.setItem('stores', JSON.stringify(updatedStores));
        pushDataToApp('stores', updatedStores);
        yield put({
          type: actions.DELETED_STORE,
          payload: JSON.stringify(updatedStores),
        });
      } else {
        yield put({
          type: actions.DELETE_STORE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_STORE_FAILED,
      });
    }
  });
}

export function* fetchAllPchannelsInfo() {
  yield takeEvery('FETCH_ALL_PCHANNELS_INFO', function* ({payload}) {
    try {
      yield put({
        type: actions.FETCH_ALL_PCHANNELS_INFO_LOADING,
      });
      const apiResult = yield fetchAllPchannelsInfoRequest(payload);
      if (apiResult.data.success && apiResult.data.data) {
        yield put({
          type: actions.FETCH_ALL_PCHANNELS_INFO_SUCCESS,
          payload: apiResult.data.data,
        });
      } else {
        yield put({
          type: actions.FETCH_ALL_PCHANNELS_INFO_FAILED,
          payload: apiResult
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_PCHANNELS_INFO_FAILED,
        payload: error
      });
    }
  });
}

function savingStore(data) {
  return post(`stores/save_store/`, data);
}
function updatingStore(data) {
  return PUT(`stores/update_store/${data.Id}`, data);
}

function generateStoreQr(data) {
  return postBlob(`stores/generate_pdf`, data);
}

function generatePlainQr(data) {
  return postBlob(`stores/generate_pdf_plain`, data);
}

// function downloadQr(data) {
//   return postBlob(`stores/get_pdf`, data);
// }

// function fetchStorePchannel(store_id) {
//   return get(`storechannels/get_all_pchannel_by_store_id/${store_id.store_id}`);
// }
function fetchStorePchannelForStore(store_id) {
  return get(`storechannels/get_all_pchannel_by_store_id_for_store/${store_id.store_id}`);
}

function changeStorePchannelStatus(data) {
  return post(`storechannels/update_channel_status/`, data);
}
function storeDelete(id) {
  return PUT(`stores/delete_store/${id}`);
}
function fetchAllPchannelsInfoRequest(columnsArr) {
  return get(`pchannels/get_all_pchannels_info/${columnsArr ? columnsArr : []}`);
}

export default function* rootSaga() {
  yield all([
    fork(downloadStoreQr),
    fork(saveStore),
    fork(loadStorePchannels),
    fork(loadStorePchannelsNew),
    fork(changePchannelStatus),
    fork(deleteStore),
    fork(downloadPlainStoreQr),
    fork(fetchAllPchannelsInfo),
  ]);
}
