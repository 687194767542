import { all, takeEvery, call, put, fork, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import trxAction from '../transactions/actions';
import authAction from '../auth/actions';
import financialsAction from '../financials/actions';
import dashBoardAction from '../dashboard/actions';
import merchantAction from '../merchant/actions';
// import * as mqttPaho from 'paho.mqtt.js';
import * as mqtt from 'mqtt';
import { getEnv, getTopic } from '@iso/lib/helpers/utility';

const { messageArrived } = trxAction;
const hostMqtt1 = process.env.REACT_APP_MQTT_HOST1;
const hostMqtt2 = process.env.REACT_APP_MQTT_HOST2;
const protocol = process.env.REACT_APP_MQTT_PROTOCOL;
const port = process.env.REACT_APP_MQTT_PORT;
const username = process.env.REACT_APP_MQTT_USER;
const password = process.env.REACT_APP_MQTT_PASS;
const url = [
  {
    protocol,
    host: hostMqtt1,
    port,
  },
  {
    protocol,
    host: hostMqtt2,
    port,
  },
];
const options = {
  clientId: '',
  clean: true,
  keepalive: 60,
  connectTimeout: 4000,
  rejectUnauthorized: false,
  username,
  password,
  servers: url,
};
let client;
// let newPayload;
export function* connectMQTT() {
  yield takeEvery('CONNECT', function* () {
    options.clientId = 'biz_ui_' + Math.random().toString(16).substr(2, 8);
    const state = yield select();
    const userInfo = state.Auth.identity;
    const role = userInfo.role.role.label;
    const mid = state.Auth.identity.merchantId;
    const stores = state.Auth.stores;
    const topic = getTopic().get('topic');
    const env = getEnv().get('env');
    const topicLogin = `/${env}/sessions/${userInfo.username}`
    const topicWithdraw = `/withdraw/${mid}`;
    const topicWithdrawEmail = `/withdraw/email/${mid}`;
    const topicKycUpload = `/lambda-kyc-upload/${mid}`;
    const topicMobileAndroid = `/lambda-mobiletrx-${mid}`;
    const topicSplitPaymentTransaction = `/lambda-split-payment-transaction-${mid}`;
    const topicCcUpload = `/lambda-cc-upload/${mid}`;
    const topicShippingTimeline = `/lambda-courier-order-tracking/${mid}`;
    if (userInfo && stores) {
      try {
        client = mqtt.connect(options);
        client.on('error', (error) => {
        });

        client.on('connect', () => {
          client.subscribe([topic, topicLogin, topicWithdraw, topicWithdrawEmail, topicKycUpload, topicMobileAndroid, topicSplitPaymentTransaction, topicCcUpload, topicShippingTimeline]);
        });

        const eventResult = yield call(createMQTTEventHandler, client);

        while (true) {
          try {
            // An error from socketChannel will cause the saga jump to the catch block
            const payload = yield take(eventResult)
            if (payload.type) {
              if (payload.payload.token) {
                yield put({
                  type: authAction.LOGIN_VALIDATOR,
                  token: payload.payload.token,
                });
                yield localStorage.setItem('mqttToken', payload.payload.token);
              } else if (payload.payload.withdraw) {
                // WITHDRAW MQTT
                if (payload.payload.status_text) {
                  yield put({
                    type: financialsAction.VERIFIED_OTP,
                    payload: 'Your withdraw is currently processing!',
                    isErrorVerifyOtp: false,
                  });
                } else {
                  yield put({
                    type: financialsAction.VERIFY_OTP_FAILED,
                  });
                  if (payload.payload.code) {
                    yield put({
                      type: financialsAction.VERIFIED_OTP,
                      payload: payload.payload.advise,
                      isErrorVerifyOtp: true,
                    });
                  } else if (payload.payload.response_code) {
                    yield put({
                      type: financialsAction.VERIFIED_OTP,
                      payload: payload.payload.response_message,
                      isErrorVerifyOtp: true,
                    });
                  }
                }
              } else if (payload.payload.withdrawEmail) {
                // WITHDRAW EMAIL
                yield put({
                  type: financialsAction.EMAIL_NOTIF,
                  payload: payload.payload,
                });
              } else if (payload.payload.kycApplication && payload.payload.kycDocs && payload.payload.kycQualified) {
                const merchantId = role === 'Administrator' || role === 'Finance' || role === 'Sales' ? state.Auth.selectedMerchant : state.Auth.identity.merchantId;;
                if (merchantId === payload.payload.kycQualified.merchantId) {
                  yield put({
                    type: merchantAction.KYC_MQTT,
                    kycApplication: payload.payload.kycApplication,
                    kycDocs: payload.payload.kycDocs,
                    kycQualified: payload.payload.kycQualified,
                  });
                }
              } else if (payload.payload.ccApplication && payload.payload.ccDocs) {
                yield put({
                  type: merchantAction.CC_MQTT,
                  ccApplication: payload.payload.ccApplication,
                  ccDocs: payload.payload.ccDocs,
                });
              } else if (payload.payload.timeline && payload.payload.timeline !== null && payload.payload.timeline !== undefined) {
                yield put({
                  type: trxAction.SHIPPING_TIMELINE_MQTT,
                  timeline: payload.payload,
                });
              } else {
                // FOR INCOMMING TRANSACTION
                yield put(payload)
                if (role !== 'Pre Registered') {
                  // GET DASHBOARD COUNT
                  yield put({
                    type: dashBoardAction.LOAD_DASHBOARD_DATA,
                  });
                }
              }
            }
          } catch (err) {
            console.error('socket error:', err)
          }
        }

      } catch (e) {
        yield put({
          type: actions.CONNECT_FAILED
        });
      }
    }
  });
}
export function* connectToMqtt() {
  yield takeEvery('CONNECT_MQTT', function* ({ payload }) {
    options.clientId = `biz_ui_${payload.module}${Math.random().toString(16).substr(2, 8)}`;

    try {
      client = mqtt.connect(options);

      client.on('connect', () => {
        client.subscribe([payload.topic]);
      });

      client.on('error', (error) => {
        console.warn("MQTT client is error...");
      });

      client.end(true, {}, () => {
        console.warn("MQTT client is reconnecting...");
        client.reconnect();
      });
      
      const eventResult = yield call(createMQTTEventHandlerNew, client);
      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const result = yield take(eventResult)
          if (result.type) {
            if (payload.module === 'merchant') {
              yield put({
                type: merchantAction.DOWNLOAD_HISTORY_SUCCESS,
                data: result.payload,
              });
            } else if (payload.module === 'store') {
              yield put({
                type: merchantAction.DOWNLOAD_STORE_TXN_SUCCESS,
                data: result.payload,
              });
            } else if (payload.module === 'kycUpload') {
              if (result.payload === 'SUCCESS_UPLOADING') {
                yield put({
                  type: merchantAction.MQTT_POSTING_RESULT,
                });
              }
            } else if (payload.module === 'financial') {
              yield put({
                type: financialsAction.DOWNLOAD_WITHDRAWAL_LOADED,
                payload: result.payload,
              });
            } else if (payload.module === 'financial/split') {
              yield put({
                type: financialsAction.DOWNLOAD_SPLIT_LOADED,
                payload: result.payload,
              });
            } else if (payload.module === 'financial/topup') {
              yield put({
                type: financialsAction.DOWNLOAD_TOPUP_LOADED,
                payload: result.payload,
              });
            } else if (payload.module === 'financial/bizwalletreport') {
              yield put({
                type: financialsAction.DOWNLOADED_BIZWALLET_REPORT,
                payload: result.payload,
              });
            } else if (payload.module === 'financial/autosettlement') {
              yield put({
                type: financialsAction.DOWNLOAD_AUTO_SETTLEMENT_LOADED,
                payload: result.payload,
              });
            } else if (payload.module === 'registrationSuccess') {
              yield put({
                type: authAction.REDIRECT_REGISTRATION_SUCCESS,
                payload: result.payload,
              });
            } else if (payload.module === 'transactionDownload') {
              yield put({
                type: trxAction.DOWNLOADED_TRANSACTION_PDF,
                payload: result.payload,
              });
            } else if (payload.module === 'ccUpload') {
              if (result.payload === 'SUCCESS_UPLOADING') {
                yield put({
                  type: merchantAction.MQTT_CC_POSTING_RESULT,
                });
              }
            } else if (payload.module === 'transaction') {
              if (result.payload === 'SUCCESS_TRACKING') {
                yield put({
                  type: trxAction.FETCH_SHIPPING_MQTT_RESULT,
                });
              }
            } else if (payload.module === 'payment/tranasction_details') {
              const finalBase64 = `data:${result.payload.fileType};base64,${result.payload.merchantLogo.base64}`;
              yield put({
                type: trxAction.FETCHED_DETAILED_TRANSACTION_DATA,
                payload: result.payload,
                merchantLogo: finalBase64,
                hasMerchantLogo: result.payload.merchantLogo.success,
              });
            }
          }
        } catch (err) {
          console.error('socket error:', err)
        }
      }
    } catch (e) {
      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}

export function* connectToMqttKeepLive() {
  yield takeEvery('CONNECT_MQTT_KEEP_LIVE', function* ({ payload }) {
    options.clientId = `biz_ui_${payload.module}${Math.random().toString(16).substr(2, 8)}`;
    try {
      client = mqtt.connect(options);
      client.on('connect', () => {
        client.subscribe([payload.topic]);
      });

      client.on('error', (error) => {
        console.warn("MQTT client is error...");
      });

      client.end(true, {}, () => {
        console.warn("MQTT client is reconnecting...");
        client.reconnect();
      });
      
      const eventResult = yield call(createMQTTEventHandler, client);
      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const result = yield take(eventResult)
          if (result.type) {
            switch (payload.module) {
              case 'store/pchannel/maintenance':
                const pchannels = []
                const nonbankOTC = []
                result.payload.forEach((e) => {
                  if (e.pmethod !== "nonbank_otc") {
                    pchannels.push(e);
                  } else {
                    nonbankOTC.push(e);
                  }
                })
                yield put({
                  type: merchantAction.FETCH_MAINTENANCE_PCHANNELS_SUCCESS,
                  payload: {
                    pchannels: pchannels,
                    nonbankOTC: nonbankOTC
                  },
                });
                break;
              case 'disbursement_supplierInfo':
                const state = yield select();
                const suppliersArray = state.Transactions.filteredSuppliersArray;
                const fetchedSupplierInfo = state.Transactions.supplierInformationSuccess;

                const { supplierinformation } = result.payload.data;
                const filteredSuppliersArray = suppliersArray?.filter(item => item.Id !== supplierinformation.Id);
                const filteredFetchedSupplierInfo = fetchedSupplierInfo?.filter(item => item.Id !== supplierinformation.Id);
                const isSupplierExists = suppliersArray?.find(item => item.Id === supplierinformation.Id);
                // Add new or updated supplier in filtered supplier information
                const newFilteredSuppliersArray = [supplierinformation, ...filteredSuppliersArray];
                // Add new or updated supplier in fetched supplier information
                const newFilteredSupplierInfo = [supplierinformation, ...filteredFetchedSupplierInfo];
                yield put({
                  type: trxAction.SET_FILTERED_SUPPLIERS_ARRAY,
                  payload: newFilteredSuppliersArray,
                })
                yield put({
                  type: trxAction.FETCH_SUPPLIER_INFO_SUCCESS,
                  payload: newFilteredSupplierInfo,
                })
                // Check if the supplier exist to determine if the process is create or update
                if(isSupplierExists) {
                  yield put({
                    type: trxAction.UPDATE_SUPPLIER_INFO_SUCCESS,
                    payload: result.payload,
                  })
                } else {
                  yield put({
                    type: trxAction.SAVE_SUPPLIER_INFO_SUCCESS,
                    payload: result.payload,
                  });
                }
                break;
              case 'supplier_disbursement':
                const supplierDisbursement = {...result.payload.data};
                delete supplierDisbursement.username
                const supplierDisbursements = yield select(state => state.Transactions.supplierDisbursements);
                if (supplierDisbursement.update) {
                  delete supplierDisbursement.update
                  const newSupplierDisbursements = supplierDisbursements.data.map(e => {
                    if (e.disbursement.disbursementId === supplierDisbursement.disbursementId) {
                      e.disbursement = supplierDisbursement
                    }
                    return e
                  })
                  yield put({
                    type: trxAction.SET_SUPPLIER_DISBURSEMENTS,
                    payload: {...supplierDisbursements, data: newSupplierDisbursements},
                  });
                  yield put({
                    type: trxAction.UPDATE_SUPPLIER_DISBURSEMENT_SUCCESS,
                    payload: result.payload.data
                  });
                } else {
                  const disbursement = supplierDisbursement.disbursement
                  if (
                    !supplierDisbursements.searchWord ||
                    disbursement.companyName.includes(supplierDisbursements.searchWord) ||
                    disbursement.disbursementId.includes(supplierDisbursements.searchWord)
                  ) {
                    const newSupplierDisbursements = supplierDisbursements?.data ?
                      [supplierDisbursement, ...supplierDisbursements.data].reduce((newArr, it) => {
                        const isExist = newArr.find(item => item.disbursement.disbursementId === it.disbursement.disbursementId)
                        if(!isExist) {
                          newArr.push(it);
                        }
                        return newArr;
                      },[])
                      : [supplierDisbursement]
                    yield put({
                      type: trxAction.SET_SUPPLIER_DISBURSEMENTS,
                      payload: {
                        data: newSupplierDisbursements,
                        currentCount: supplierDisbursements.currentCount + 1,
                        totalCount: supplierDisbursements.totalCount + 1,
                        searchWord: supplierDisbursements.searchWord,
                      },
                    });
                  }
                  yield put({
                    type: trxAction.CREATE_SUPPLIER_DISBURSEMENT_SUCCESS,
                    payload: result.payload.data
                  });
                }
                break
              default:
                break;
            }
          }
        } catch (err) {
          console.error('socket error:', err)
        }
      }
    } catch (e) {
      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}
// This function updates the transaction status etc..
function createMQTTEventHandler(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      // const newMessage = message.toString();
      const newPayload = JSON.parse(message.toString());
      emitter(messageArrived(newPayload))
    });
    return () => false;
  });
}
function createMQTTEventHandlerNew(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      // const newMessage = message.toString();
      const newPayload = JSON.parse(message.toString());
      emitter(messageArrived(newPayload))
      client.unsubscribe([topic]);
    });
    return () => false;
  });
}
// function* messageArrived(payload) {
//   yield put({
//     type: trxAction.MQTT_TRX,
//     mqttTrx: payload,
//   });
// }
export default function* rootSaga() {
  yield all([
    fork(connectMQTT),
    fork(connectToMqtt),
    fork(connectToMqttKeepLive),
    // fork(mqttLoginValidator),
  ]);
}
