const actions = {
  LOAD_DASHBOARD_DATA: 'LOAD_DASHBOARD_DATA',
  LOADING_DASHBOARD_DATA: 'LOADING_DASHBOARD_DATA',
  LOADED_DASHBOARD_DATA: 'LOADED_DASHBOARD_DATA',
  LOAD_DASHBOARD_ERROR: 'LOAD_DASHBOARD_ERROR',
  RESET_DASHBOARD_DATA: 'RESET_DASHBOARD_DATA',
  // USER SETTINGS
  GET_USER_SETTINGS: 'GET_USER_SETTINGS',
  GETTING_USER_SETTINGS: 'GETTING_USER_SETTINGS',
  GOT_USER_SETTINGS: 'GOT_USER_SETTINGS',
  ERROR_USER_SETTINGS: 'ERROR_USER_SETTINGS',
  // VAT AGGREE
  UPDATE_VAT_AGGREE: 'UPDATE_VAT_AGGREE',
  UPDATED_VAT_AGGREE: 'UPDATED_VAT_AGGREE',
  UPDATING_VAT_AGGREE: 'UPDATING_VAT_AGGREE',
  ERROR_VAT_AGGREE: 'ERROR_VAT_AGGREE',
  KYC_URL: 'KYC_URL',
  // RAFFLE ENTRY
  // SEND_RAFFLE_ENTRY: 'SEND_RAFFLE_ENTRY',
  // SENDING_RAFFLE_ENTRY: 'SENDING_RAFFLE_ENTRY',
  // RAFFLE_ENTRY: 'RAFFLE_ENTRY',
  // SENDING_FAILED_RAFFLE_ENTRY: 'SENDING_FAILED_RAFFLE_ENTRY',
  // GET_WINNERS: 'GET_WINNERS',
  // GETTING_WINNERS: 'GETTING_WINNERS',
  // GOT_WINNERS: 'GOT_WINNERS',
  // START_RAFFLE: 'START_RAFFLE',
  // STARTING_RAFFLE: 'STARTING_RAFFLE',
  // DONE_RAFFLE: 'DONE_RAFFLE',

  // APPLY CREDIT CARD
  APPLY_CREDIT_CARD: 'APPLY_CREDIT_CARD',
  APPLYING_CREDIT_CARD: 'APPLYING_CREDIT_CARD',
  APPLIED_CREDIT_CARD: 'APPLIED_CREDIT_CARD',
  APPLY_CREDIT_CARD_FAILED: 'APPLY_CREDIT_CARD_FAILED',
  RESET_APPLY_CREDIT_CARD: 'RESET_APPLY_CREDIT_CARD',
  // GET APPLICATION
  GET_CC_APPLICATION: 'GET_CC_APPLICATION',
  GETTING_CC_APPLICATION: 'GETTING_CC_APPLICATION',
  GOT_CC_APPLICATION: 'GOT_CC_APPLICATION',
  ERROR_CC_APPLICATION: 'ERROR_CC_APPLICATION',
  RESET_GET_CC_APPLICATION: 'RESET_GET_CC_APPLICATION',
  // DOWNLOAD TNC PDF
  DOWNLOAD_TNC_PDF: 'DOWNLOAD_TNC_PDF',
  DOWNLOAD_TNC_PDF_SUCCESS: 'DOWNLOAD_TNC_PDF_SUCCESS',
  DOWNLOAD_TNC_PDF_LOADING: 'DOWNLOAD_TNC_PDF_LOADING',
  DOWNLOAD_TNC_PDF_FAILED: 'DOWNLOAD_TNC_PDF_FAILED',
  DOWNLOAD_TNC_PDF_RESET: 'DOWNLOAD_TNC_PDF_RESET',
  // SET URL
  SET_URL: 'SET_URL',
  SET_URL_SUCCESS: 'SET_URL_SUCCESS',
  // SET PREVIOUS URL
  SET_PREV_URL: 'SET_PREV_URL',
  SET_PREV_URL_SUCCESS: 'SET_PREV_URL_SUCCESS',

  // FETCH MERCHANT BIR DOCUMENTS
  FETCH_MERCH_BIR_DOCS: 'FETCH_MERCH_BIR_DOCS',
  FETCH_MERCH_BIR_DOCS_SUCCESS: 'FETCH_MERCH_BIR_DOCS_SUCCESS',
  FETCH_MERCH_BIR_DOCS_LOADING: 'FETCH_MERCH_BIR_DOCS_LOADING',
  FETCH_MERCH_BIR_DOCS_FAILED: 'FETCH_MERCH_BIR_DOCS_FAILED',
  FETCH_MERCH_BIR_DOCS_RESET: 'FETCH_MERCH_BIR_DOCS_RESET',

  getPaymentCount: () => ({
    type: actions.LOAD_DASHBOARD_DATA,
  }),
  resetDashboardData: () => ({
    type: actions.RESET_DASHBOARD_DATA,
  }),
  getUserSettings: (payload) => ({
    type: actions.GET_USER_SETTINGS,
    payload,
  }),
  isVatAggree: (payload) => ({
    type: actions.UPDATE_VAT_AGGREE,
    payload,
  }),
  setLevel1KycUrl: (payload) => ({
    type: actions.KYC_URL,
    payload,
  }),
  // getWinners: () => ({
  //   type: actions.GET_WINNERS,
  // }),
  // startRaffle: () => ({
  //   type: actions.START_RAFFLE,
  // }),
  // reqRaffleEntry: (payload) => ({
  //   type: actions.SEND_RAFFLE_ENTRY,
  //   payload,
  // }),
  // resetRaffleEntry: () => ({
  //   type: actions.RAFFLE_ENTRY,
  //   payload: null,
  //   success: null,
  // }),
  // resetWinner: () => ({
  //   type: actions.DONE_RAFFLE,
  //   payload: null,
  // }),
  applyCredit: (payload) => ({
    type: actions.APPLY_CREDIT_CARD,
    payload
  }),
  resetApplyCredit: (payload) => ({
    type: actions.RESET_APPLY_CREDIT_CARD,
    payload
  }),
  getCcApplication: () => ({
    type: actions.GET_CC_APPLICATION
  }),
  resetGetCcApplication: () => ({
    type: actions.RESET_GET_CC_APPLICATION
  }),
  downloadTncPdf: (payload) => ({
    type: actions.DOWNLOAD_TNC_PDF,
    payload,
  }),
  downloadTncPdfReset: () => ({
    type: actions.DOWNLOAD_TNC_PDF_RESET,
  }),
  setUrl: (payload) => ({
    type: actions.SET_URL,
    payload,
  }),
  setPrevUrl: (payload) => ({
    type: actions.SET_PREV_URL,
    payload,
  }),
  fetchMerchBirDocs: (payload) => ({
    type: actions.FETCH_MERCH_BIR_DOCS,
    payload,
  }),
  fetchMerchBirDocsReset: () => ({
    type: actions.FETCH_MERCH_BIR_DOCS_RESET,
  }),
};
export default actions;
