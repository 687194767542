const actions = {
    ENCRYPT_REQ_ID: "ENCRYPT_REQ_ID",
    ENCRYPT_REQ_ID_SUCCESS: "ENCRYPT_REQ_ID_SUCCESS",
    ENCRYPT_REQ_ID_LOADING: "ENCRYPT_REQ_ID_LOADING",
    ENCRYPT_REQ_ID_FAILED: "ENCRYPT_REQ_ID_FAILED",
    encryptReqId: (payload) => ({
        type: actions.ENCRYPT_REQ_ID,
        payload
    }),
};

export default actions;